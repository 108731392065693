<template>
  <form action="" class="needs-validation" novalidate>
    <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Factuuradres</h2>
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-company">Bedrijfsnaam</label>
          <input class="form-control" type="text" id="checkout-company" v-model="cartCustomer.companyName">
        </div>
      </div>

    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-fn">Voornaam</label>
          <input class="form-control" type="text" id="checkout-fn" v-model="cartCustomer.firstName">
          <div :style="{display:formValidated&&cartCustomer.firstName===''?'block':'none'}" class="invalid-feedback">Vul een voornaam in.</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-ln">Achternaam</label>
          <input class="form-control" type="text" id="checkout-ln" v-model="cartCustomer.lastName">
          <div :style="{display:formValidated&&cartCustomer.lastName===''?'block':'none'}" class="invalid-feedback">Vul een achternaam in.</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-email">E-mail adres</label>
          <input class="form-control" type="email" id="checkout-email" v-model="cartCustomer.emailAddress">
          <div :style="{display:formValidated&&cartCustomer.emailAddress===''?'block':'none'}" class="invalid-feedback">Vul een emailadres in.</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-phone">Telefoon</label>
          <input class="form-control" type="text" id="checkout-phone" v-model="cartCustomer.phone">
          <div :style="{display:formValidated&&cartCustomer.phone===''?'block':'none'}" class="invalid-feedback">Vul een telefoonnnummer in.</div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-zip">Postcode</label>
          <input class="form-control" type="text" id="checkout-zip" v-model="cartCustomer.zipcode" @blur="addressLookup()">
          <div :style="{display:formValidated&&cartCustomer.zipcode===''?'block':'none'}" class="invalid-feedback">Vul een postcode in.</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-number">Huisnummer</label>
          <input class="form-control" type="text" id="checkout-number" v-model="cartCustomer.houseNumber">
          <div :style="{display:formValidated&&cartCustomer.houseNumber===''?'block':'none'}" class="invalid-feedback">Vul een huisnummer in.</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-address-1">Straatnaam</label>
          <input class="form-control" type="text" id="checkout-address-1" v-model="cartCustomer.street">
          <div :style="{display:formValidated&&cartCustomer.street===''?'block':'none'}" class="invalid-feedback">Vul een straatnaam in.</div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="mb-3">
          <label class="form-label" for="checkout-address-2">Woonplaats</label>
          <input class="form-control" type="text" id="checkout-address-2" v-model="cartCustomer.city">
          <div :style="{display:formValidated&&cartCustomer.city===''?'block':'none'}" class="invalid-feedback">Vul een plaatsnaam in.</div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "CheckoutFormDetails",
  props: ['formValidated'],
  computed: {
    cartCustomer() {
      return this.$store.getters['cart/customer'];
    }
  },
  methods: {
    addressLookup() {
      if (this.cartCustomer.zipcode !== '') {
        fetch('https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:'+this.cartCustomer.zipcode.replace(/ +/g, ""))
            .then(response => response.json())
            .then(data => {
              let records = data.response.docs.filter(rec => (rec.huisnummer == this.cartCustomer.houseNumber || rec.huis_nlt == this.cartCustomer.houseNumber));
              if (records.length === 0) {
                records = data.response.docs
              }
              this.cartCustomer.street = records[0].straatnaam
              this.cartCustomer.city = records[0].woonplaatsnaam
            })
            .catch(err => console.log(err))
      }
    },
  }
}
</script>

<style scoped>

</style>