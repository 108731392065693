<template>
  <page-title title="Contact"></page-title>
  <section class="container-fluid pt-grid-gutter">
    <div class="row">
      <div class="col-xl-3 col-sm-6 mb-grid-gutter"><a class="card h-100" href="#map" data-scroll>
        <div class="card-body text-center"><i class="ci-location h3 mt-2 mb-4 text-primary"></i>
          <h3 class="h6 mb-2">Adres</h3>
          <p class="fs-sm text-muted">{{ store.address.street}}, {{store.address.zipcode}}, {{store.address.city}}</p>
          <div class="fs-sm text-primary">Klik voor de route<i class="ci-arrow-right align-middle ms-1"></i></div>
        </div></a></div>
      <div class="col-xl-3 col-sm-6 mb-grid-gutter">
        <div class="card h-100">
          <div class="card-body text-center"><i class="ci-time h3 mt-2 mb-4 text-primary"></i>
            <h3 class="h6 mb-3">Openingstijden</h3>
            <ul class="list-unstyled fs-sm text-muted mb-0">
              <li>Ma - Vr: 8:00 - 18:00</li>
              <li class="mb-0">Za & Zo: 9:00 - 18:00</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-grid-gutter">
        <div class="card h-100">
          <div class="card-body text-center"><i class="ci-phone h3 mt-2 mb-4 text-primary"></i>
            <h3 class="h6 mb-3">Telefoon nummer</h3>
            <ul class="list-unstyled fs-sm mb-0">
              <li><span class="text-muted me-1">Voor alle vragen:</span><a class="nav-link-style" :href="'tel:'+store.phone">{{store.phone}}</a></li>

            </ul>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-sm-6 mb-grid-gutter">
        <div class="card h-100">
          <div class="card-body text-center"><i class="ci-mail h3 mt-2 mb-4 text-primary"></i>
            <h3 class="h6 mb-3">Email adres</h3>
            <ul class="list-unstyled fs-sm mb-0">
              <li><span class="text-muted me-1">Email:</span><a class="nav-link-style" :href="'mailto:'+store.orderEmail">{{store.orderEmail}}</a></li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Split section: Map + Contact form-->
  <div class="container-fluid px-0" id="map">
    <div class="row g-0">
      <div class="col-lg-6 iframe-full-height-wrap">
        <iframe :src="'https://www.google.com/maps/embed?pb=' + store.googleMap" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div class="col-lg-6 px-4 px-xl-5 py-5 border-top">
        <h2 class="h4 mb-4">Stuur ons een bericht</h2>
        <form class="needs-validation mb-3" novalidate @submit.prevent="sendContactMessage">
          <div class="row g-3">
            <div class="col-sm-6">
              <label class="form-label" for="cf-name">Je naam:&nbsp;<span class="text-danger">*</span></label>
              <input class="form-control" type="text" id="cf-name" placeholder="Jan de Vries" v-model="form.name" required>
              <div class="invalid-feedback">Vul je naam in!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="cf-email">Email adres:&nbsp;<span class="text-danger">*</span></label>
              <input class="form-control" type="email" id="cf-email" placeholder="jandevries@email.com" v-model="form.email" required>
              <div class="invalid-feedback">Vul een geldig email adres in!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="cf-phone">Je telefoonnummer:&nbsp;<span class="text-danger">*</span></label>
              <input class="form-control" type="text" id="cf-phone" placeholder="06 00 000 000" v-model="form.phone"  required>
              <div class="invalid-feedback">Vul een geldig nummer in</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="cf-subject">Onderwerp:</label>
              <input class="form-control" type="text" id="cf-subject" placeholder="Waar gaat je bericht over?" v-model="form.subject" >
            </div>
            <div class="col-12">
              <label class="form-label" for="cf-message">Bericht:&nbsp;<span class="text-danger">*</span></label>
              <textarea class="form-control" id="cf-message" rows="6" placeholder="Vul hier je vraag of opmerking in" required v-model="form.message" ></textarea>
              <div class="invalid-feedback">Vul hier je bericht!</div>
              <button class="btn btn-primary mt-4" type="submit">Verstuur bericht</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
  name: "Contact",
  components: {
    PageTitle
  },
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
      }
    }
  },
  computed: {
    store() {
      return this.$store.getters.store;
    },
  },
  methods: {
    sendContactMessage() {
      this.$store.dispatch('sendContactMessage', this.form);
    }
  }
};
</script>

<style scoped>

</style>
