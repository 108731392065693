<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <form method="post" id="form-post-product" @submit.prevent="onSubmit">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Instellingen homepage</h1>
        <div>
          <button :class="{clicked: btnClicked}" class="btn btn-primary save-btn rounded font-sm hover-up" type="submit">
            <span class="save-product"><i class="check fs-sm me-1"></i>Opslaan</span>
            <span class="saved">Opgeslagen</span> <i class="ci-arrow-down-circle"></i>
          </button>
        </div>
      </div>

      <div class="card mb-4">
        <div class="card-body">
          <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
            <h4>Uitgelichte categori&euml;n</h4>
            <div class="row">

              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer eerste</label>
                <select class="form-select" id="select-input" v-model="checkedCategories[0]">
                  <option>Kies een categorie...</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{category.description}}</option>

                </select>
              </div>
              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer tweede</label>
                <select class="form-select" id="select-input" v-model="checkedCategories[1]">
                  <option>Kies een categorie...</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{category.description}}</option>

                </select>
              </div>
              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer derde</label>
                <select class="form-select" id="select-input" v-model="checkedCategories[2]">
                  <option>Kies een categorie...</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{category.description}}</option>

                </select>
              </div>
              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer vierde</label>
                <select class="form-select" id="select-input" v-model="checkedCategories[3]">
                  <option>Kies een categorie...</option>
                  <option v-for="category in categories" :key="category.id" :value="category.id">{{category.description}}</option>

                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mb-4">
        <div class="card-body">
          <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
            <h4>Uitgelichte producten</h4>
            <div class="row">

              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer eerste</label>
                <select class="form-select" id="select-input" v-model="checkedProducts[0]">
                  <option>Kies een product...</option>
                  <option v-for="product in products" :key="product.id" :value="product.id">{{product.title}}</option>

                </select>
              </div>
              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer tweede</label>
                <select class="form-select" id="select-input" v-model="checkedProducts[1]">
                  <option>Kies een product...</option>
                  <option v-for="product in products" :key="product.id" :value="product.id">{{product.title}}</option>

                </select>
              </div>
              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer derde</label>
                <select class="form-select" id="select-input" v-model="checkedProducts[2]">
                  <option>Kies een product...</option>
                  <option v-for="product in products" :key="product.id" :value="product.id">{{product.title}}</option>

                </select>
              </div>
              <div class="mb-3">
                <label for="select-input" class="form-label">Selecteer vierde</label>
                <select class="form-select" id="select-input" v-model="checkedProducts[3]">
                  <option>Kies een product...</option>
                  <option v-for="product in products" :key="product.id" :value="product.id">{{product.title}}</option>

                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>

  </main>
</template>

<script>
export default {
  name: "AdminHomeSettings",
  data() {
    return {
      checkedProducts: [],
      checkedCategories: [],
      // checkedCategories1: null,
      // checkedCategories2: null,
      // checkedCategories3: null,
      // checkedCategories4: null,
      btnClicked: false,
    }
  },
  computed: {
    products() {
      let products = this.$store.getters['prods/products'].filter(p => p.published);
      return products.sort((a, b) => a.title.localeCompare(b.title));
    },
    categories() {
      let categories = this.$store.getters['prods/flatCategories'];
      return categories.sort((a, b) => a.description.localeCompare(b.description));
    },
    featuredProducts() {
      return this.$store.getters['prods/featuredProducts'];
    },
    featuredCategories() {
      return this.$store.getters['prods/featuredCategories'];
    },
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('prods/storeFeaturedProducts', {
        products: this.checkedProducts
      });
      this.$store.dispatch('prods/storeFeaturedCategories', {
        categories: this.checkedCategories
      });
      this.btnClicked = true;
      setTimeout(() => {
        this.btnClicked = false;
      }, 2000);

    }
  },
  watch: {
    featuredProducts(val) {
      this.checkedProducts = val.map(p => p.item_id);

    },
    featuredCategories(val) {
      this.checkedCategories = val.map(p => p.item_id);
    }
  },
  created() {
    this.$store.dispatch('prods/loadProducts',{admin: true});
    this.$store.dispatch('prods/loadFlatCategories',{});
    this.$store.dispatch('prods/loadFeaturedProducts',{});
    this.$store.dispatch('prods/loadFeaturedCategories',{});
  },
}
</script>

<style scoped>

.card {
  margin-bottom: 20px;
}

.save-btn {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden
}

.save-btn:focus {
  outline: none !important
}

.save-btn .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.save-btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.save-btn span.saved {
  opacity: 0
}


.save-btn.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.save-btn.clicked {
  animation: color 1s ease-in forwards
}

.save-btn.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.save-btn.clicked span.save-product {
  animation: saveProduct 1s ease-in forwards
}

.save-btn.clicked span.saved {
  animation: saved 1s ease-in forwards
}


@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes saveProduct {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes saved {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}

.image {
  position: relative;
  width: 100%;
  overflow: hidden
}

.image .overlay {
  position: absolute;
  bottom: 0;
  padding-left: 25px;
  padding-top: 15px;
  width: 100%;
  height: 70%;
  color: white;
  font-size: 20px;
  z-index: 5;
  opacity: 0;
}

.image .overlay::before {
  content: "";
  background: #ffff;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: -150px;
  z-index: -2;
  opacity: 0.5;
  transition: all 0.3s ease-out
}

.image:hover .overlay {
  color: black;
  opacity: 10;
}

.container .image:hover .overlay .amount,
.container .image:hover .overlay .h4,
.container .image:hover .overlay .textmuted,
.container .image:hover .overlay .fa-star,
.container .image:hover .overlay .fa-star-half-alt,
.container .image:hover .overlay .review {
  color: black
}

.image:hover .overlay::before {
  bottom: 0px
}

</style>