<template>
  <page-title :title="store.cartName"></page-title>
  <div class="container pb-5 mb-2 mb-md-4">
    <div class="row">
      <!-- List of items-->
      <section class="col-lg-8">
        <div class="d-flex justify-content-between align-items-center pt-3 pb-4 pb-sm-5 mt-1">
          <h2 class="h6 text-light mb-0">Products</h2><router-link class="btn btn-outline-primary btn-sm ps-2" to="/verhuur"><i class="ci-arrow-left me-2"></i>Terug naar verhuur</router-link>
        </div>
        <!-- Item-->
        <div v-for="product in cartProducts" :key="product.id" class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
          <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
            <router-link class="d-inline-block flex-shrink-0 mx-auto me-sm-4" :to="product.link"><img :src="product.imagelink" width="160" alt="Product"></router-link>
            <div class="pt-2">
              <h3 class="product-title fs-base mb-2"><router-link :to="product.link">{{product.title}}</router-link></h3>
              <div class="fs-lg text-accent pt-2">€ {{ Math.floor(product.price)}}.<small>{{product.price.toFixed(2).slice(-2)}}</small></div>
            </div>
          </div>
          <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style="max-width: 9rem;">
            <label class="form-label" for="quantity1">Aantal</label>
            <input @click="changeQuantity($event, product.id)" class="form-control" type="number" id="quantity1" min="1" :value="product.quantity">
            <button @click="deleteFromCart(product.id)" class="btn btn-link px-0 text-danger" type="button"><i class="ci-close-circle me-2"></i><span class="fs-sm">Verwijderen</span></button>
          </div>
        </div>
        <div v-if="cartLength == 0" class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
          <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
            <div class="pt-2">
              <h3 class="product-title fs-base mb-2">{{ store.noProductsInCart }}</h3>
            </div>
          </div>
        </div>

<!--        <button class="btn btn-outline-accent d-block w-100 mt-4" type="button"><i class="ci-loading fs-base me-2"></i>Update cart</button>-->
      </section>
      <!-- Sidebar-->
      <aside v-if="cartLength > 0" class="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
        <div class="bg-white rounded-3 shadow-lg p-4">
          <div class="py-2 px-xl-2">
            <div class="text-center mb-4 pb-3 border-bottom">
              <h2 class="h6 mb-3 pb-1">Subtotaal</h2>
              <h3 class="fw-normal">€ {{Math.floor(carTotalPrice)}}.<small>{{carTotalPrice.toFixed(2).slice(-2)}}</small></h3>
            </div>
            <div class="mb-3 mb-4">
              <label class="form-label mb-3" for="order-comments"><span class="badge bg-info fs-xs me-2">Notitie</span><span class="fw-medium">Opmerkingen</span></label>
              <textarea class="form-control" rows="6" id="order-comments"></textarea>
            </div>
            <div class="accordion" id="order-options">
              <div class="accordion-item">
                <h3 class="accordion-header"><a class="accordion-button" href="#huurdagen" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="huurdagen">Huurdagen</a></h3>
                <div class="accordion-collapse collapse show" id="huurdagen" data-bs-parent="#order-options">
                  <form class="accordion-body needs-validation" method="post" novalidate>
                    <div class="form-floating mb-3">
                      <input class="form-control" type="date" id="startdate" placeholder="Start huur" v-model="startDate" required>
                      <label for="startdate">Start huur</label>
                      <div class="invalid-feedback">Geef een startdatum</div>
                    </div>
                    <div class="form-floating mb-3">
                      <input class="form-control" type="date" id="startdate" placeholder="Einde huur" v-model="endDate" required>
                      <label for="startdate">Einde huur</label>
                      <div class="invalid-feedback">Geef een einddatum</div>
                      <div class="form-text">Aantal dagen: {{rentDays}}</div>
                    </div>

                    <div v-if="store.cartUseShowdays" class="form-floating mb-3">
                      <input class="form-control" type="number" id="showdays" placeholder="Aantal showdagen" v-model="showDays" required>
                      <label for="showdays">Aantal showdagen</label>
                      <div class="invalid-feedback">Geef aantal showdagen</div>
                    </div>
                  </form>
                </div>
              </div>


            </div><router-link class="btn btn-primary btn-shadow d-block w-100 mt-4" to="/checkout-details"><i class="ci-card fs-lg me-2"></i>Naar gegevens invullen</router-link>
          </div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"

export default {
  name: "Cart",
  components: {
    PageTitle
  },
  methods: {
    deleteFromCart(prodId) {
      this.$store.dispatch('cart/removeFromCart', { productId: prodId });
    },
    changeQuantity(event, prodId) {
      this.$store.dispatch('cart/changeProductQuantity', { productId: prodId, newQty:  event.target.value});
    }
  },
  computed: {
    cartLength() {
      return this.$store.getters['cart/quantity'];
    },
    cartProducts() {
      return this.$store.getters['cart/products'];
    },
    carTotalPrice() {
      return this.$store.getters['cart/totalPrice'];
    },

    rentDays() {
      if (this.startDate && this.endDate) {
        const date1 = new Date(this.startDate);
        const date2 = new Date(this.endDate);
        return Math.round((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24) )+ 1;
      } else {
        return 1;
      }
    },
    store() {
      return this.$store.getters.store;
    },
    showDays: {
      get () {
        return this.$store.getters['cart/rental'].showDays;
      },
      set (value) {
        this.$store.dispatch('cart/changeShowDays', value);
      }
    },
    startDate: {
      get () {
        return this.$store.getters['cart/rental'].startDate;
      },
      set (value) {
        this.$store.commit('cart/changeStartDate', value);
      }
    },
    endDate: {
      get () {
        return this.$store.getters['cart/rental'].endDate;
      },
      set (value) {
        console.log('change enddate');
        this.$store.commit('cart/changeEndDate', value);
      }
    }
  },
  watch: {
    rentDays(value) {
      console.log('rentdays aangepast met value: ' + value);
      this.$store.dispatch('cart/changeShowDays', value);
      //if (value > 2) {
      //  this.$store.dispatch('cart/changeShowDays', value -2);
      //} else {
      //  this.$store.dispatch('cart/changeShowDays', 1);
      //}
    }
  }
}
</script>