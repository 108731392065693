<template>
  <LoginDialog v-if="showLoginDialog" @CloseDialog="onCloseLoginDialog">
    <template #actions>
      <button type="button" class="btn" @click="showLoginDialog=false">Ok</button>
    </template>
  </LoginDialog>
  <header class="shadow-sm">
    <!-- Topbar-->
    <div v-if="store" class="topbar topbar-dark bg-dark">
      <div class="container">
        <div class="topbar-text dropdown d-md-none"><a class="topbar-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Useful links</a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" :href="'tel:' + store.phone"><i class="ci-support text-muted me-2"></i>{{ store.phone }}</a></li>
<!--            <li><a class="dropdown-item" href="order-tracking.html"><i class="ci-location text-muted me-2"></i>Order tracking</a></li>-->
          </ul>
        </div>
        <div class="topbar-text text-nowrap d-none d-md-inline-block"><i class="ci-support"></i><span class="text-muted me-1">Ondersteuning</span><a class="topbar-link" :href="'tel:' + store.phone">{{ store.phone }}</a></div>

<!--        <div class="ms-3 text-nowrap">-->
<!--          <a class="topbar-link me-4 d-none d-md-inline-block" href="order-tracking.html"><i class="ci-location"></i>Order tracking</a>-->
<!--        </div>-->
      </div>
    </div>
    <div class="navbar-sticky bg-light">
      <div class="navbar navbar-expand-lg navbar-light">
        <div class="container">
          <router-link class="navbar-brand d-none d-sm-block flex-shrink-0" to="/">
<!--            <img src="/img/NextSense-logo.png" width="142" alt="Logo">-->
            <img v-if="store" :src="store.logo" width="142" alt="Logo">
          </router-link>
          <router-link class="navbar-brand d-sm-none flex-shrink-0 me-2" to="/">
            <img v-if="store"  :src="store.icon" width="74" alt="Logo">
          </router-link>

          <div class="navbar-toolbar d-flex flex-shrink-0 align-items-center">
            <div style="cursor: pointer" @click="clickMyAccount" class="navbar-tool ms-1 ms-lg-0 me-n1 me-lg-2" href="" >
              <div class="navbar-tool-icon-box"><i class="navbar-tool-icon ci-user"></i></div>
              <div class="navbar-tool-text ms-n3"><small><span v-if="!isLogggedIn">Inloggen</span></small>Mijn account</div>
            </div>


            <cart-dropdown></cart-dropdown>
          </div>
        </div>
      </div>
      <div v-if="store" class="navbar navbar-expand-lg navbar-light navbar-stuck-menu mt-n2 pt-0 pb-2">
        <div class="container">
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <!-- Search-->
            <div class="input-group d-lg-none my-3"><i class="ci-search position-absolute top-50 start-0 translate-middle-y text-muted fs-base ms-3"></i>
              <input class="form-control rounded-start" type="text" placeholder="Search for products">
            </div>

            <!-- Primary menu-->
            <ul v-if="store.topMenu" class="navbar-nav">
              <li class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" to="/">Home</router-link></li>
              <li v-for="category in categories.filter(cat => cat.parent === null)" :key="category.id" class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" :to="category.link">{{category.description}}</router-link>
                <ul v-if="category.subcategories.length>0" class="dropdown-menu">
                  <li v-for="sub in category.subcategories.sort((a,b) => a.sortorder > b.sortorder?1:((b.sortorder > a.sortorder) ? -1 : 0))" :key="sub.id"><router-link class="dropdown-item" :to="sub.link">{{sub.description}}</router-link></li>
                </ul></li>
              <li v-if="isAdmin" class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" to="/admin">Admin</router-link></li>

            </ul>
            <ul v-else class="navbar-nav">
              <li class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" to="/">Home</router-link></li>
              <li class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" to="/verhuur">Verhuur</router-link></li>

              <li v-if="isAdmin" class="nav-item dropdown"><router-link class="nav-link dropdown-toggle" to="/admin">Admin</router-link></li>

            </ul>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
  import CartDropdown from "./CartDropdown"
  import LoginDialog from "./LoginDialog";

  export default {
    name: "Header",
    components: {
      CartDropdown,
      LoginDialog
    },
    data() {
      return {
        showLoginDialog: false
      }
    },
    methods: {
      clickMyAccount() {
        if (!this.isLogggedIn) {
          this.showLoginDialog = true
        } else {
          this.$router.push('/account');
        }
      },
      onCloseLoginDialog() {
        this.showLoginDialog = false;

      }
    },
    computed: {
      isLogggedIn() {
        return this.$store.getters.isAuthenticated;
      },
      isAdmin() {
        return this.$store.getters.isAdmin
      },
      store() {
        return this.$store.getters.store;
      },
      categories() {
        return this.$store.getters['prods/categories'];
      }
    }

  }
</script>