<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Orders</h1>
    </div>
    <div class="card mb-4">
      <header class="card-header">
        <div class="row align-items-center">
        </div>
      </header>
      <div class="card-body">
        <table class="table table-hover table-responsive">
          <tbody>

            <tr @click="editOrder(order.id)" class="text-center align-middle" v-for="order in orders" :key="order.id">
              <td class="text-start">{{convertFromStringToDate(order.created_at)}}</td>
              <td class="text-start">{{order.id}}</td>
              <td class="text-start">{{order.first_name}} {{order.last_name}}</td>
              <td class="text-end">{{order.email_address}}</td>
              <td class="text-end">

                  <button @click.stop="deleteOrder(order.id)" type="button" class="btn btn-danger btn-icon">
                    <i class="ci-trash"></i>
                  </button>

              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: "AdminOrders",
  methods: {
    editOrder(id) {
      this.$router.push('/admin/orders/' + id);
    },
    deleteOrder(id) {
      console.log('Delete order' + id);
    },
    convertFromStringToDate(responseDate) {
      const date = dayjs(responseDate);
      return date.format('DD-MM-YYYY');
    }
  },
  computed: {
    orders() {
      return this.$store.getters['ords/orders'];
    },
  },
  created() {
    // this.$store.dispatch('prods/loadProducts',{});
    this.$store.dispatch('ords/loadOrders',{});

  },
}
</script>

<style scoped>

</style>