<template>

  <page-title :title="title"></page-title>

  <div class="container pb-5 mb-2 mb-md-4">

    <div class="row">

      <!-- Sidebar-->
      <aside v-if="store.sideMenu" class="col-lg-4">
        <!-- Sidebar-->
        <div class="offcanvas offcanvas-collapse bg-white w-100 rounded-3 shadow-lg py-1" id="shop-sidebar" style="max-width: 22rem;">
          <div class="offcanvas-header align-items-center shadow-sm">
            <h2 class="h5 mb-0">Filters</h2>
            <button class="btn-close ms-auto" type="button" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body py-grid-gutter px-lg-grid-gutter">
            <!-- Categories-->
            <categories-filters ></categories-filters>
          </div>
        </div>
      </aside>
      <!-- Content  -->
        <section :class="store.sideMenu ? 'col-lg-8' : 'col-lg-12'">
        <!-- Toolbar-->
        <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5">
          <div class="d-flex flex-wrap">
            <div class="invisible d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3">
              <label class="text-light opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block" for="sorting">Sort by:</label>
              <select class="form-select" id="sorting">
                <option>Popularity</option>
                <option>Low - Hight Price</option>
                <option>High - Low Price</option>
                <option>Average Rating</option>
                <option>A - Z Order</option>
                <option>Z - A Order</option>
              </select><span class="fs-sm text-light opacity-75 text-nowrap ms-2 d-none d-md-block">of {{products?products.length:0}} products</span>
            </div>
          </div>
          <div class="invisible d-flex pb-3"><a class="nav-link-style nav-link-light me-3" href="#"><i class="ci-arrow-left"></i></a><span class="fs-md text-light">1 / 5</span><a class="nav-link-style nav-link-light ms-3" href="#"><i class="ci-arrow-right"></i></a></div>
          <div class="invisible d-none d-sm-flex pb-3"><a class="btn btn-icon nav-link-style bg-light text-dark disabled opacity-100 me-2" href="#"><i class="ci-view-grid"></i></a><a class="btn btn-icon nav-link-style nav-link-light" href="shop-list-ls.html"><i class="ci-view-list"></i></a></div>
        </div>
        <!-- Products grid-->
          <component :is="categoryTextBefore" />
          <div v-if="products" :class="store.sideMenu ? 'pt-3' : ''" class="row mx-n2">
            <div v-for="product in products" :key="product.id"  :class="store.sideMenu ? '' : 'col-lg-3'" class="col-md-4 col-sm-6 px-2 mb-4">
              <product-card :product="product"></product-card>
            </div>
          </div>
          <component :is="categoryTextAfter" />
        <hr class="my-3">

      </section>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import CategoriesFilters from "../components/CategoriesFilters"
import ProductCard from "../components/ProductCard";

// import ProductFilters from "../components/ProductFilters"
// import ProductFiltersButton from "../components/ProductFiltersButton"

export default {
  name: "ProductsList",
  data() {
    return {

    }
  },
  components: {
    ProductCard,
    PageTitle,
    CategoriesFilters,
    // ProductFilters,
    // ProductFiltersButton
  },
  methods: {


  },
  computed: {
    products() {
      if (this.$route.params.maincategory) {
        let category = decodeURI(this.$route.params.maincategory);

        if (this.$route.params.category) {
          category = decodeURI(this.$route.params.category);
          return this.$store.getters['prods/products'].filter(product => product.category_link === category);
        } else {
          return this.$store.getters['prods/products'].filter(product => {
            if (product.maincategory_link) {
              return product.maincategory_link === category;
            } else {
              return product.category_link === category;
            }
          });
        }


      } else {
        return this.$store.getters['prods/products'];
      }

    },
    title() {
      let category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory)[0];
      if (this.$route.params.category) {
        category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.category)[0];
      }
      document.title = this.store.name + ' - Verhuur ' + (category ? category.description : '');
      const descEl = document.querySelector('head meta[name="description"]');
      const keyEl = document.querySelector('head meta[name="keywords"]');
      descEl.setAttribute('content', (category ? category.description : '') + ' kun je huren bij ' +  this.store.name);
      keyEl.setAttribute('content', 'Verhuur ' + (category ? category.description.toLowerCase() : ''));
      return category ? category.description : '';

    },
    categoryTextBefore() {
      let category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory)[0];
      if (this.$route.params.category) {
        category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.category)[0];
      }
      console.log(category);
      //return category.text_before_products;
      return { template: category!==undefined?category.text_before_products:'<div></div>'}
    },
    categoryTextAfter() {
      let category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory)[0];
      if (this.$route.params.category) {
        category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.category)[0];
      }

      return { template: category!==undefined?category.text_after_products:'<div></div>'};
    },
    store() {
      return this.$store.getters.store;
    },
    transformed () {
      return { template: this.categoryTextBefore }
    }
  },
  created() {
    this.$store.dispatch('prods/loadProducts',{});
  },
}
</script>

<style scoped>

</style>

