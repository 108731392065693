<template>
<!--  <main v-if="discountId" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">-->
    <discount-form :discountId="discountId"></discount-form>
<!--  </main>-->
</template>

<script>

import DiscountForm from './DiscountForm'

export default {
  name: "AdminDiscount",
  props: ['discountId'],
  components: {
    DiscountForm
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}

.save-btn {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden
}

.save-btn:focus {
  outline: none !important
}

.save-btn .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.save-btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.save-btn span.saved {
  opacity: 0
}


.save-btn.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.save-btn.clicked {
  animation: color 1s ease-in forwards
}

.save-btn.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.save-btn.clicked span.save-product {
  animation: saveProduct 1s ease-in forwards
}

.save-btn.clicked span.saved {
  animation: saved 1s ease-in forwards
}


@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes saveProduct {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes saved {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}

.image {
  position: relative;
  width: 100%;
  overflow: hidden
}

.image .overlay {
  position: absolute;
  bottom: 0;
  padding-left: 25px;
  padding-top: 15px;
  width: 100%;
  height: 70%;
  color: white;
  font-size: 20px;
  z-index: 5;
  opacity: 0;
}

.image .overlay::before {
  content: "";
  background: #ffff;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: -150px;
  z-index: -2;
  opacity: 0.5;
  transition: all 0.3s ease-out
}

.image:hover .overlay {
  color: black;
  opacity: 10;
}

.container .image:hover .overlay .amount,
.container .image:hover .overlay .h4,
.container .image:hover .overlay .textmuted,
.container .image:hover .overlay .fa-star,
.container .image:hover .overlay .fa-star-half-alt,
.container .image:hover .overlay .review {
  color: black
}

.image:hover .overlay::before {
  bottom: 0px
}

/*div.gallery div:hover {*/
/*  border: 1px dotted #C0C0C0;*/
/*}*/
</style>