<template>
  <div class="col-lg-7 pe-lg-0 pt-lg-4">
    <div class="product-gallery">
      <div class="product-gallery-preview order-sm-2">
        <div class="product-gallery-preview-item active">
          <img v-if="selectedImage" class="image-zoom" :src="selectedImage + '?width=570&height=570'" :data-zoom="selectedImage" alt="Product image">
          <img v-else class="image-zoom" :src="store.noProductImage + '?width=570&height=570'" alt="Product image">
          <div class="image-zoom-pane"></div>
        </div>
      </div>
      <div v-if="product.thumbnails && product.thumbnails.length > 0" class="product-gallery-thumblist order-sm-1">
        <a v-for="(image, index) in product.thumbnails" :key="image.id" @click="selectImageIndex(index)" :class="{active: selectedImageIndex === index}" class="product-gallery-thumblist-item"><img :src="image.image" alt="Product thumb"></a>
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "ProductGallery",
  props: ['product'],
  data()   {
    return {
      selectedImageIndex: 0,
    }
  },
  methods: {
    selectImageIndex(idx) {
      this.selectedImageIndex = idx;
    },
  },
  computed: {
    selectedImage() {
      if (this.product.images && this.product.images.length > 0) {
        return this.product.images[this.selectedImageIndex].image;
      }
      return null;
    },
    store() {
      return this.$store.getters.store;
    }
  }
}
</script>

<style scoped>

</style>