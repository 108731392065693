<template>
  <div class="steps steps-light pt-2 pb-3 mb-5">
    <router-link class="step-item active" to="/cart">
      <div class="step-progress"><span class="step-count">1</span></div>
      <div class="step-label"><i class="ci-cart"></i>{{ store.cartName }}</div>
    </router-link>
    <router-link class="step-item" :class="{active: step > 1, current: step === 2 }" to="/checkout-details">
      <div class="step-progress"><span class="step-count">2</span></div>
      <div class="step-label"><i class="ci-user-circle"></i>Gegevens</div>
    </router-link>
    <component class="step-item" :is="isAllowedToNavigate[1] ? 'router-link' : 'span'" :class="{active: step > 2, current: step === 3 }" to="/checkout-transport">
      <div class="step-progress"><span class="step-count">3</span></div>
      <div class="step-label"><i class="ci-package"></i>Transport</div>
    </component>
    <component class="step-item" :is="isAllowedToNavigate[2] ? 'router-link' : 'span'" :class="{active: step > 3, current: step === 4 }" to="/checkout-payment">
      <div class="step-progress"><span class="step-count">4</span></div>
      <div class="step-label"><i class="ci-card"></i>Betaling</div>
    </component>
    <component class="step-item" :is="isAllowedToNavigate[2] ? 'router-link' : 'span'" :class="{active: step > 4, current: step === 5 }" to="/checkout-review">
      <div class="step-progress"><span class="step-count">5</span></div>
      <div class="step-label"><i class="ci-check-circle"></i>Definitief akkoord</div>
    </component>
  </div>
</template>

<script>
export default {
  name: "CheckoutNavigation",
  props: ['step'],
  computed: {
    isAllowedToNavigate() {
      return [this.$store.getters['cart/isDetailsValid'], this.$store.getters['cart/isTransportValid'], this.$store.getters['cart/isPaymentValid']]
    },
    store() {
      return this.$store.getters.store;
    },
  }
}
</script>

<style scoped>


</style>