<template>
  <aside class="col-lg-4 pt-4 pt-lg-0 ps-xl-5">
    <div class="bg-white rounded-3 shadow-lg p-4 ms-lg-auto">
      <div class="py-2 px-xl-2">
        <div class="widget mb-3">
          <h2 class="widget-title text-center">Order overzicht</h2>
          <div v-if="step !== 5" >
            <div v-for="product in products" :key="product.id" class="d-flex align-items-center pb-2 border-bottom"><router-link class="d-block flex-shrink-0" :to="product.link"><img :src="product.imagelink" width="64" alt="Product"></router-link>
              <div class="ps-2">
                <h6 class="widget-product-title"><router-link :to="product.link">{{product.title}}</router-link></h6>
                <div class="widget-product-meta"><span class="text-accent me-2">€ {{ Math.floor(product.price)}}.<small>{{product.price.toFixed(2).slice(-2)}}</small></span><span class="text-muted">x {{product.quantity}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <ul class="list-unstyled fs-sm pb-2 border-bottom">
          <li class="d-flex justify-content-between align-items-center"><span class="me-2">Subtotaal:</span><span class="text-end">€ {{Math.floor(productTotalPrice)}}.<small>{{(productTotalPrice).toFixed(2).slice(-2)}}</small></span></li>
          <li class="d-flex justify-content-between align-items-center"><span class="me-2">Bezorgen:</span><span class="text-end">€ {{Math.floor(transportCosts)}}.<small>{{transportCosts.toFixed(2).slice(-2)}}</small></span></li>
          <li class="d-flex justify-content-between align-items-center"><span class="me-2">BTW:</span><span class="text-end">€ {{Math.floor(totalVat)}}.<small>{{totalVat.toFixed(2).slice(-2)}}</small></span></li>
          <li class="d-flex justify-content-between align-items-center"><span class="me-2">Korting:</span><span class="text-end">€ {{Math.floor(discountAmount)}}.<small>{{discountAmount.toFixed(2).slice(-2)}}</small></span></li>

        </ul>
        <h3 class="fw-normal text-center my-4">€ {{Math.floor(totalPrice-discountAmount)}}.<small>{{(totalPrice-discountAmount).toFixed(2).slice(-2)}}</small></h3>
        <div class="mb-3">
          <input @focus="emptyErrorMessage" v-if="discountAmount===0" class="form-control" type="text" v-model="discountCode" placeholder="Kortingscode" required="">
          <div v-else>
          <div  class="">Kortingscode: {{discountCode}}</div>
          <span class="me-2"><small>{{discount.value}}{{discount.method==='PERC'?'%': ' euro '}} korting</small></span>
          </div>
        </div>
        <div v-if="discountErrorMessage" class="mb-3">
          <div  class="alert alert-danger" role="alert">{{ discountErrorMessage }}</div>


        </div>
        <button v-if="discountAmount===0" @click="applyDiscount" class="btn btn-outline-primary d-block w-100" type="submit">Toepassen</button>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "CheckoutSideCart",
  props: ['step','products', 'totalPrice', 'totalVat','productTotalPrice'],
  data() {
    return {
      discountCode: ''
    }
  },
  computed: {
    transportCosts() {
      return this.$store.getters['cart/transport'].deliveryCosts + this.$store.getters['cart/transport'].pickupCosts;
    },
    discountAmount() {
      return this.$store.getters['cart/discountAmount'];
    },
    discount() {
      return this.$store.getters['cart/discount'];
    },
    discountErrorMessage() {
      return this.$store.getters['cart/discountErrorMessage'];
    }
  },
  methods: {
    applyDiscount() {
      if (this.discountCode !== '') {
        this.$store.dispatch('cart/applyDiscount', this.discountCode);
      }
    },
    emptyErrorMessage() {
      if (this.discountErrorMessage !== '') {
        this.$store.commit('cart/setDiscountErrorMessage', '');
      }
    }
  },
  mounted() {
    if (this.discountErrorMessage !== '') {
      this.$store.commit('cart/setDiscountErrorMessage', '');
    }
  }
}
</script>

<style scoped>

</style>