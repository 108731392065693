<template>
  <page-form></page-form>

</template>

<script>
import PageForm from "@/components/admin/PageForm";

export default {
  name: "AdminNewPage",
  components: {
    PageForm
  }
}
</script>

<style scoped>

</style>