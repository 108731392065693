<template>
  <section v-if="store" class="container py-3">
    <h2 class="h3 text-center">Hulp nodig?</h2>
    <div class="row pt-4">
      <div class="col-lg-12 col-sm-6 mb-grid-gutter">
        <div class="card border-0 shadow">
          <div class="card-body">

            <p class="fs-sm text-muted pb-2" v-html="store.faqIntrotext"></p>
          </div>
        </div>
      </div>


    </div>
  </section>
  <section class="container pt-4 pb-5">

    <h2 class="h3 text-center">F.A.Q.</h2>

    <div class="row pt-4">
      <div v-if="faq" class="col-sm-6">
        <ul class="list-unstyled">
          <li v-for="q in faq1" :key="q.id"  class="mb-0"><router-link class="nav-link-style d-flex align-items-center border-bottom pb-3 mb-3" :to="'/faq/'+q.code"><i class="ci-book opacity-60 me-2"></i><span>{{q.question}}</span></router-link></li>
        </ul>
      </div>
      <div v-if="faq" class="col-sm-6">
        <ul class="list-unstyled">
          <li v-for="q in faq2" :key="q.id"  class="mb-0"><router-link class="nav-link-style d-flex align-items-center border-bottom pb-3 mb-3" :to="'/faq/'+q.code"><i class="ci-book opacity-60 me-2"></i><span>{{q.question}}</span></router-link></li>
        </ul>
      </div>
    </div>
  </section>
  <!-- Submit request-->
  <section class="container text-center pt-1 pb-5 mb-2">
    <h2 class="h4 pb-3">Staat je vraag er niet bij?</h2><i class="ci-help h3 text-primary d-block mb-4"></i><router-link class="btn btn-primary" to="/faq/need-help">Stel je vraag</router-link>
    <p class="fs-sm pt-4">Neem contact op en we komen snel bij je terug.</p>
  </section>
</template>

<script>

export default {
  name: "HelpFAQ",
  data() {
    return {
      faq1: [],
      faq2: [],
    };
  },
  computed: {
    faq() {
      return this.$store.getters['faq'];
    },
    store() {
      return this.$store.getters.store;
    },
  },
  watch: {
    faq() {
      //console.log(this.faq);
      this.faq1 = this.faq.filter((item,idx) => {
        if (idx % 2 === 0) {
          return item;
        }

      });
      this.faq2 = this.faq.filter((item,idx) => {
        if (idx % 2 !== 0) {
          return item;
        }

      });
    },
  },
  created() {
    this.$store.dispatch('loadFAQ');
  },
};
</script>

<style scoped>

</style>