<template>
  <div>
    <checkout-form-details v-if="step === 2" :formValidated="formValidated"></checkout-form-details>
    <checkout-form-transport v-if="step === 3" :formValidated="formValidated"></checkout-form-transport>
    <checkout-form-payment v-if="step === 4" :formValidated="formValidated"></checkout-form-payment>
  </div>
</template>

<script>
import CheckoutFormDetails from "./CheckoutFormDetails";
import CheckoutFormTransport from "./CheckoutFormTransport";
import CheckoutFormPayment from "./CheckoutFormPayment";

export default {
  name: "CheckoutForm",
  components: { CheckoutFormDetails, CheckoutFormTransport, CheckoutFormPayment },
  props: ['step','formValidated'],



}
</script>

<style scoped>

</style>