<template>
  <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Order definitief maken</h2>
  <!-- Item-->
  <div v-for="product in products" :key="product.id" class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
    <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
      <router-link class="d-inline-block flex-shrink-0 mx-auto me-sm-4" :to="product.link"><img :src="product.imagelink" width="160" alt="Product"></router-link>
      <div class="pt-2">
        <h3 class="product-title fs-base mb-2"><router-link :to="product.link">{{product.title}}</router-link></h3>
        <div class="fs-lg text-accent pt-2">€ {{ Math.floor(product.price)}}.<small>{{product.price.toFixed(2).slice(-2)}}</small></div>
      </div>
    </div>
    <div class="pt-2 pt-sm-0 ps-sm-3 mx-auto mx-sm-0 text-center text-sm-start" style="max-width: 9rem;">
      <p class="mb-0"><span class="text-muted fs-sm">Aantal:</span><span>&nbsp;{{ product.quantity }}</span></p>
<!--      <label class="form-label" for="quantity1">Aantal</label>-->
<!--      <input @click="changeQuantity($event, product.id)" class="form-control" type="number" id="quantity1" min="1" :value="product.quantity">-->
<!--      <button @click="deleteFromCart(product.id)" class="btn btn-link px-0 text-danger" type="button"><i class="ci-close-circle me-2"></i><span class="fs-sm">Verwijderen</span></button>-->
    </div>
  </div>

  <!-- Client details-->
  <div class="bg-secondary rounded-3 px-4 pt-4 pb-2">
    <div class="row">
      <div class="col-sm-6">
        <h4 class="h6">Bestelling voor:</h4>
        <ul class="list-unstyled fs-sm">
          <li><span class="text-muted">Klant:&nbsp;</span>{{ (cartCustomer.companyName !== ''?cartCustomer.companyName + ' - ':'') + cartCustomer.firstName + ' ' + cartCustomer.lastName}}</li>
          <li><span class="text-muted">Adres:&nbsp;</span>{{cartCustomer.street + ' ' + cartCustomer.houseNumber + ', ' + cartCustomer.zipcode + ' ' + cartCustomer.city}}</li>
          <li><span class="text-muted">Telefoon:&nbsp;</span>{{ cartCustomer.phone }}</li>
        </ul>
      </div>
      <div class="col-sm-6">
        <h4 class="h6">Betaling</h4>
        <ul class="list-unstyled fs-sm">
          <li v-if="cartPayment.bank !== ''">iDEAL via {{ cartPayment.bank }}</li>
          <li v-else>Offerte aangevraagd</li>
        </ul>
      </div>
    </div>
  </div>

  <div class="bg-secondary rounded-3 px-4 pt-4 pb-2">
    <div class="row">
      <div class="col-sm-6">
        <h4 class="h6">Levering</h4>
        <ul class="list-unstyled fs-sm">
          <li v-if="cartTransport.shippingMethod === 'self'">De bestelling wordt opgehaald op {{ formatDate(cartTransport.shipDate) }}</li>
          <li v-else>De bestelling wordt gebracht op {{ formatDate(cartTransport.shipDate) }}</li>
        </ul>
      </div>
      <div class="col-sm-6">
        <h4 class="h6">Terugbrengen</h4>
        <ul class="list-unstyled fs-sm">
          <li v-if="cartTransport.returnMethod === 'self'">De bestelling wordt terug gebracht op {{ formatDate(cartTransport.returnDate) }}</li>
          <li v-else>De bestelling wordt opgehaald op {{ formatDate(cartTransport.returnDate) }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="rounded-3 px-4 pt-4 pb-2">
    <div class="row">
      <div class="col-sm-12">

        <div class="form-check d-block">
          <input class="form-check-input" type="checkbox" id="check_terms" v-model="checkTerms">
          <label class="form-check-label" for="check_terms">Ik ga akkoord met de <a href="/algemene-voorwaarden" target="_blank">algemene voorwaarden</a> en met het verwerken van mijn gegevens voor uitvoering van mijn order</label>
          <div :style="{display:formValidated&&!checkTerms?'block':'none'}" class="invalid-feedback">Wanneer je verder wilt moet je ook akkoord gaan met onze voorwaarden</div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "CheckoutOrderOverview",
  props: ['products', 'formValidated'],
  data() {
    return {
      checkTerms: false
    }
  },
  methods: {
    formatDate(str) {
      return str[8] + str[9] + '-' + str[5] + str[6] + '-' + str[0] + str[1] + str[2] + str[3]
    },
  },
  computed: {
    cartCustomer() {
      return this.$store.getters['cart/customer'];
    },
    cartPayment() {
      return this.$store.getters['cart/payment'];
    },
    cartTransport() {
      return this.$store.getters['cart/transport'];
    },
  },
  watch: {
    checkTerms() {
      this.$store.dispatch('cart/clickCheckTerms', this.checkTerms);
    }
  }
}
</script>

<style scoped>

</style>