<template>
  <div :class="{'d-none': !mobile,'d-lg-flex':!mobile,'d-flex':mobile}" class="pt-4 mt-3">
    <div class="w-50 pe-3"><button @click="$emit('previousClick')" class="btn btn-secondary d-block w-100"><i class="ci-arrow-left mt-sm-0 me-1"></i><span class="d-none d-sm-inline">{{ prevStepDescription }}</span><span class="d-inline d-sm-none">Back</span></button></div>
    <div class="w-50 ps-2"><button @click="$emit('nextClick')" :class="step !== 5 ? 'btn-primary' : 'btn-success'" class="btn btn-primary d-block w-100"><span class="d-none d-sm-inline">{{ nextStepDescription }}</span><span class="d-inline d-sm-none">Next</span><i class="ci-arrow-right mt-sm-0 ms-1"></i></button></div>
  </div>

</template>

<script>
export default {
  name: "CheckoutButtons",
  props: ['prevStepDescription','nextStepDescription','mobile', 'step'],
  emits: ['previousClick','nextClick'],
}
</script>

<style scoped>

</style>