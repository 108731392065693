<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Producten</h1>


      <div>
<!--        <a href="#" class="btn btn-light rounded font-md">Exporteren</a>-->
<!--        <a href="#" class="btn btn-light rounded  font-md">Importeren</a>-->
        <router-link to="/admin/newproduct" class="btn btn-success rounded">Nieuw product</router-link>
      </div>
    </div>
    <div class="card mb-4">
      <header class="card-header">
        <div class="row align-items-center">

          <div class="col-md-3 col-8 me-auto mb-md-0 mb-3">
<!--            <select class="form-select">-->
<!--              <option selected="">Alle categorien</option>-->
<!--              <option v-for="category in categories" :key="category.id">{{category.description}}</option>-->

<!--            </select>-->
          </div>

        </div>
      </header>
      <div class="card-body">
        <table class="table table-hover table-responsive">
          <tbody>

            <tr @click="editProduct(product.id)" class="text-center align-middle" v-for="product in products" :key="product.id">
              <td class="text-start">
                <img v-if="product.image !== null" :src="product.image_link + '?width=50&height=50'" class="img-thumbnail">
              </td>
              <td class="text-start">{{product.title}}</td>
              <td class="text-end">€ {{Math.floor(product.price)}}.{{product.price.toFixed(2).slice(-2)}}</td>
              <td class="text-end">

                  <button @click.stop="deleteProduct(product.id)" type="button" class="btn btn-danger btn-icon">
                    <i class="ci-trash"></i>
                  </button>

              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "AdminProducts",
  methods: {
    editProduct(id) {
      this.$router.push('/admin/products/' + id);
    },
    deleteProduct(id) {
      this.$store.dispatch('prods/deleteProduct',id);
    }
  },
  computed: {
    products() {
      return this.$store.getters['prods/products'];
    },
    categories() {
      return this.$store.getters['prods/categories'];
    }
  },
  created() {
    this.$store.dispatch('prods/loadProducts',{admin: true});
    this.$store.dispatch('prods/loadCategories',{});
  },
}
</script>

<style scoped>

</style>