import dayjs from "dayjs";

export default {
    namespaced: true,
    state() {
        return {
            products: [],
            categories: [],
            flatCategories: [],
            product: null,
            featuredProducts: [],
            featuredCategories: [],
        };
    },
    mutations: {
        setProduct(state, payload) {
            state.product = payload;
        },
        setProducts(state, payload) {
            state.products = payload;
        },
        setCategories(state, payload) {
            state.categories = payload;
        },
        setFlatCategories(state, payload) {
            state.flatCategories = payload;
        },
        setFeaturedProducts(state, payload) {
            state.featuredProducts = payload;
        },
        setFeaturedCategories(state, payload) {
            state.featuredCategories = payload;
        },

    },
    actions: {
        loadProduct(context,prodId) {
            fetch(process.env.VUE_APP_API_ENDPOINT +"/products/" + prodId,
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    //console.log(data);
                    let product = {};
                    product.id = data.id;
                    product.title = data.title;
                    product.price= data.price;
                    //product.category= data.category.description;
                    product.category= data.category;
                    product.category_id = data.category.id;
                    product.rating= data.rating;
                    product.opposite_rating= 5 - data.rating;
                    product.sale = (data.sale === 1);
                    product.sales_price = data.sales_price;
                    product.quantity = data.quantity;
                    let images = [];
                    data.images.filter(image => image.type==='details').forEach(image => {
                        let newImage = {};
                        newImage.id = image.id;
                        //newImage.image = '/img/products/' + image.image;
                        newImage.image = process.env.VUE_APP_API_ENDPOINT + '/products/' + data.id + '/images/' + image.image;
                        images.push(newImage);
                    })
                    product.images = images;
                    images = [];
                    data.images.filter(image => image.type==='details').forEach(image => {
                        let newImage = {};
                        newImage.id = image.id;
                        //newImage.image = '/img/products/' + image.image;
                        newImage.image = process.env.VUE_APP_API_ENDPOINT + '/products/' + data.id + '/images/' + image.image + '?width=75&height=75';
                        images.push(newImage);
                    })
                    product.thumbnails = images;
                    product.reviews = data.reviews;
                    product.reviews.map (review => {
                        review.opposite_rating = 5 - review.rating;
                        const date = dayjs(review.datetime_created);
                        review.formattedDate = date.format('dddd D MMMM YYYY');


                    })
                    product.info = data.info;
                    product.description = data.description;
                    product.published = data.published;
                    product.saleItem = data.sale_item;
                    data.accessoires.forEach(acc => {
                        acc.image = process.env.VUE_APP_API_ENDPOINT + '/products/' + acc.id + '/images/' + acc.image + '?width=64&height=64';
                        acc.link = acc.parent_category_description !== null?encodeURI('/verhuur/' + acc.parent_category_description.replace(/\s+/g, '-').toLowerCase() + '/' + acc.category_description.replace(/\s+/g, '-').toLowerCase() + '/' + acc.id):encodeURI('/verhuur/' + acc.category_description.replace(/\s+/g, '-').toLowerCase() + '/' + acc.id);
                    })
                    product.accessoires = data.accessoires;
                    context.commit('setProduct', product);
                })
        },
        loadProducts(context, payload = {admin: false}) {
            let url = '/products'
            let token = '';
            if (payload.admin) {
                url = '/adminProducts'
                token = context.rootGetters.token;
            }
            fetch(process.env.VUE_APP_API_ENDPOINT + url,
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then((response) => {
                    context.dispatch('setApiOffline', false, {root: true});
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    const results = [];
                    for (const id in data) {
                        data[id].category.link = data[id].category.parent.description !== null?encodeURI('/verhuur/' + data[id].category.parent.description.replace(/\s+/g, '-').toLowerCase() + '/' + data[id].category.description.replace(/\s+/g, '-').toLowerCase()):encodeURI('/verhuur/' + data[id].category.description.replace(/\s+/g, '-').toLowerCase()),
                        results.push({
                            id: data[id].id,
                            title: data[id].title,
                            price: data[id].price,
                            //category: data[id].category.description,
                            category: data[id].category,
                            test: data[id].category.parent,
                            category_link: encodeURI(data[id].category.description.replace(/\s+/g, '-').toLowerCase()),
                            maincategory: data[id].category.parent.description !== null?data[id].category.parent.description:null,
                            maincategory_link: data[id].category.parent.description !== null?encodeURI(data[id].category.parent.description.replace(/\s+/g, '-').toLowerCase()):null,
                            rating: data[id].rating,
                            sale: data[id].sale,
                            sales_price: data[id].sales_price,
                            image: data[id].image,
                            image_link: data[id].image!==null?process.env.VUE_APP_API_ENDPOINT + '/products/' + data[id].id + '/images/' + data[id].image:null,
                            link: data[id].category.parent.description !== null?encodeURI('/verhuur/' + data[id].category.parent.description.replace(/\s+/g, '-').toLowerCase() + '/' + data[id].category.description.replace(/\s+/g, '-').toLowerCase() + '/' + data[id].id):encodeURI('/verhuur/' + data[id].category.description.replace(/\s+/g, '-').toLowerCase() + '/' + data[id].id),
                            published: data[id].published,
                            saleItem: data[id].sale_item
                        })
                    }
                    context.commit('setProducts', results);
                })
                .catch((err) => {
                    console.log(err);
                    context.commit('setProducts', []);
                    context.dispatch('setApiOffline', true, {root: true});
                    setTimeout(() => {
                        context.dispatch('loadProducts', context, {admin: payload.admin});
                    }, 10000)
                })
        },
        loadCategories(context) {
            fetch(process.env.VUE_APP_API_ENDPOINT + "/categories",{
                    headers: {
                        'store': process.env.VUE_APP_STORE
                    }
                })
                .then((response) => {
                    context.dispatch('setApiOffline', false, {root: true});
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    let parent_categories = data.filter(item => item.parent === null);
                    parent_categories.forEach(cat => {
                        let subcategories = data.filter(item => item.parent !== null).filter(item => item.parent.id === cat.id);
                        subcategories.forEach(sub => {
                            sub.link = '/verhuur/' + encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase() + '/' + sub.description.replace(/\s+/g, '-').toLowerCase());
                        })
                        cat.subcategories = subcategories;
                    })
                    parent_categories.forEach(cat => {
                        cat.link = '/verhuur/' + encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase());
                        cat.image_link = cat.image!==null?process.env.VUE_APP_API_ENDPOINT + '/images/' + cat.image:null;
                    })
                    //this.categories = parent_categories;
                    context.commit('setCategories', parent_categories)
                })
                .catch((err) => {
                    console.log(err);
                    context.commit('setCategories', []);
                    context.dispatch('setApiOffline', true, {root: true});
                    setTimeout(() => {
                        context.dispatch('loadCategories', context);
                    }, 10000)
                })
        },
        loadFlatCategories(context) {
            fetch(process.env.VUE_APP_API_ENDPOINT + "/categories",{
                headers: {
                    'store': process.env.VUE_APP_STORE
                }
            })
                .then((response) => {
                    context.dispatch('setApiOffline', false, {root: true});
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    //let categories =
                    data.forEach(cat => {
                        if (cat.parent !== null) {
                            cat.link = '/verhuur/' + encodeURI(cat.parent.description.replace(/\s+/g, '-').toLowerCase()) + '/' + encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase());
                            cat.longDescription = cat.parent.description + ' - ' + cat.description
                            cat.image_link = cat.image!==null?process.env.VUE_APP_API_ENDPOINT + '/images/' + cat.image:null;
                        } else {
                            cat.link = '/verhuur/' + encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase());
                            cat.longDescription = cat.description;
                            cat.image_link = cat.image!==null?process.env.VUE_APP_API_ENDPOINT + '/images/' + cat.image:null;
                        }

                        cat.longDescription = cat.parent !== null ? cat.parent.description + ' - ' + cat.description:cat.description
                    })
                    context.commit('setFlatCategories', data)
                })
                .catch((err) => {
                    console.log(err);
                    context.commit('setFlatCategories', []);
                    context.dispatch('setApiOffline', true, {root: true});
                    setTimeout(() => {
                        context.dispatch('loadFlatCategories', context);
                    }, 10000)
                })
        },
        updateProduct(context,payload) {

            let product = payload.product;
            let images = payload.images;
            let body = {
                title: product.title,
                price: product.price,
                category: product.category_id,
                rating: product.rating,
                sale: product.sale,
                sales_price: product.sales_price,
                quantity: product.quantity,
                info: product.info,
                description: product.description,
                published: product.published,
                sale_item: product.saleItem
            };
            let token = context.rootGetters.token;

            if (JSON.stringify(product.images) !== JSON.stringify(images)) {
                //Which images are not needed anymore?
                product.images.forEach((image) => {
                    console.log(image);
                    if (images.filter(img => img.id === image.id).length === 0) {
                        console.log('Deze is verwijderd');

                        fetch(process.env.VUE_APP_API_ENDPOINT + "/products/" + product.id + '/images/' + image.id,
                            {
                                method: 'DELETE',
                                headers: {
                                    'store': process.env.VUE_APP_STORE,
                                    'Authorization': 'Bearer ' + token
                                },
                            }
                        )
                    }
                })
                //Which images are added?
                images.forEach((image) => {
                    if (product.images.filter(img => img.id = image.id).length === 0) {
                        console.log('Image is toegevoegd (' + image.id + ')');
                    }
                })
            }
            fetch(process.env.VUE_APP_API_ENDPOINT + "/products/" + product.id,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'store': process.env.VUE_APP_STORE,
                            'Authorization' : 'Bearer ' + token
                        },
                        body: JSON.stringify(body)
                    },
            )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                console.log('Finished')
                console.log(data);
            })

        },
        addProduct(context,payload) {
            let product = payload.product;
            //let images = payload.images;
            let body = {
                title: product.title,
                price: product.price,
                category: product.category,
                rating: product.rating,
                sale: product.sale,
                sales_price: product.sales_price,
                quantity: product.quantity,
                info: product.info,
                description: product.description,
                published: product.published,
                sale_item: product.saleItem
            };
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/products/",
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                },
            )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(() => {
                context.dispatch('loadProducts')
            })
        },
        deleteProduct(context, id) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/products/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    }
                },
            )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(() => {
                context.dispatch('loadProducts', {admin: true})
            })
        },
        addImage(context, payload) {
            let formData = new FormData();
            let filename = payload.filename
            let prodId = payload.prodId;
            formData.append( 'file', filename );
            let token = context.rootGetters.token;

            fetch( process.env.VUE_APP_API_ENDPOINT + "/products/" + prodId + '/images', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer '+ token
                },
                body: formData
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(() => {
                context.dispatch('loadProduct', prodId)
            })

        },
        storeFeaturedProducts(context, payload) {
            let products = [];
            payload.products.forEach(product => {
                products.push({id: product})
            })
            let token = context.rootGetters.token;
            fetch( process.env.VUE_APP_API_ENDPOINT + '/products/featured', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE,
                    'Authorization': 'Bearer '+ token
                },
                body: JSON.stringify(products)
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })

        },
        storeFeaturedCategories(context, payload) {
            let categories = [];
            payload.categories.forEach(category => {
                if (category !== null) {
                    categories.push({id: category})
                }
            })


            let token = context.rootGetters.token;
            fetch( process.env.VUE_APP_API_ENDPOINT + '/categories/featured', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE,
                    'Authorization': 'Bearer '+ token
                },
                body: JSON.stringify(categories)
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })

        },
        loadFeaturedProducts(context) {
            fetch( process.env.VUE_APP_API_ENDPOINT + '/products/featured', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE
                }
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                context.commit('setFeaturedProducts', data)
            })
        },
        loadFeaturedCategories(context) {
            fetch( process.env.VUE_APP_API_ENDPOINT + '/categories/featured', {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE
                }
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                context.commit('setFeaturedCategories', data)
            })
        },

    },
    getters: {
        products(state) {
            //console.log(state.products);
            return state.products;
        },
        categories(state) {
            //console.log(state.categories)
            return state.categories
        },
        flatCategories(state) {
            //console.log(state.flatCategories)
            return state.flatCategories
        },
        product(state) {
            //console.log(state.product);
            return state.product;
        },
        featuredProducts(state) {
            //console.log(state.featuredProducts);
            return state.featuredProducts;
        },
        featuredCategories(state) {
            //console.log(state.featuredCategories);
            return state.featuredCategories;
        }
    }
};
