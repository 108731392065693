<template>
  <div :class="type" class="alert alert-dismissible fade show center" role="alert" id="notification-bar">
    <div class="text-center" v-html="text"></div>
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>
</template>

<script>
export default {
  name: "NotificationBar",
  props: ['text', 'type']

}
</script>

<style scoped>
#notification-bar {
  /*margin: -2px;*/
}

.alert-danger {
  color: #db4065;
  background-color: #7e0b27;
  border-color: #fbbfcd;
}

.btn-close {
  /*color: #db4065;*/
  color: white !important;
}

</style>