import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat);

export default {
    namespaced: true,
    state() {
        return {
            orders: [],
            order: null,
            discounts: [],
            discount: null
        };
    },
    mutations: {
        setOrder(state, payload) {
            state.order = payload;
        },
        setOrders(state, payload) {
            state.orders = payload;
        },
        setDiscounts(state, payload) {
            state.discounts = payload;
        },
        setDiscount(state, payload) {
            state.discount = payload;
        },
    },
    actions: {
        loadOrder(context,ordId) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT +"/orders/" + ordId,
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer '+ token
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {

                    let order = {};
                    order.id = data.id;
                    order.products = data.items;
                    order.totalPrice = data.items.reduce((a, item) => {
                        return {price: a.price + (item.quantity * item.price)}
                    }).price;
                    order.shippingCosts = 0;
                    if (data.transport.shipping_method === 'us') {
                        order.shippingCosts += 75;
                    }
                    if (data.transport.return_method === 'us') {
                        order.shippingCosts += 75;
                    }
                    order.customer = data.customer;
                    order.transport = data.transport;
                    order.quoteRequested = data.request_quote;
                    order.quoteNumber = data.quote_number;
                    order.discount = data.discount;
                    if (order.discount.code !== undefined) {
                        switch (order.discount.method) {
                            case 'PERC':
                                order.discount.amount = order.totalPrice * order.discount.value / 100;
                                break;
                            case 'AMOUNT':
                                order.discount.amount = order.discount.value;
                                break;
                        }
                    }
                    context.commit('setOrder', order);
                })
        },

        loadOrders(context) {
            let token = context.rootGetters.token;

            fetch(process.env.VUE_APP_API_ENDPOINT + "/orders",
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer '+ token
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    const results = [];
                    for (const id in data) {
                        results.push({
                            id: data[id].id,
                            created_at: data[id].created_at,
                            first_name: data[id].customer.first_name,
                            last_name: data[id].customer.last_name,
                            email_address: data[id].customer.email_address
                        })
                    }
                    context.commit('setOrders', results);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        updateOrder(context,payload) {

            let order = payload.order;
            let images = payload.images;
            let body = {
                title: order.title,
                price: order.price,
                category: order.category,
                rating: order.rating,
                sale: order.sale,
                sales_price: order.sales_price,
                quantity: order.quantity,
                info: order.info,
                description: order.description,

            };
            let token = context.rootGetters.token;

            if (JSON.stringify(order.images) !== JSON.stringify(images)) {
                //Which images are not needed anymore?
                order.images.forEach((image) => {
                    if (images.filter(img => img.id = image.id).length === 0) {

                        fetch(process.env.VUE_APP_API_ENDPOINT + "/orders/" + order.id + '/images/' + image.id,
                            {
                                method: 'DELETE',
                                headers: {
                                    'store': process.env.VUE_APP_STORE,
                                    'Authorization': 'Bearer ' + token
                                },
                            }
                        )
                    }
                })
                //Which images are added?
                images.forEach((image) => {
                    if (order.images.filter(img => img.id = image.id).length === 0) {
                        console.log('Image is toegevoegd (' + image.id + ')');
                    }
                })
            }
            fetch(process.env.VUE_APP_API_ENDPOINT + "/orders/" + order.id,
                    {
                        method: 'PUT',
                        headers: {
                            'Content-type': 'application/json; charset=UTF-8',
                            'store': process.env.VUE_APP_STORE,
                            'Authorization' : 'Bearer ' + token
                        },
                        body: JSON.stringify(body)
                    },
            )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((data) => {
                console.log('Finished')
                console.log(data);
            })

        },
        patchOrder(context, payload) {
            let token = context.rootGetters.token;
            Object.keys(payload).forEach((k) => payload[k] == null && delete payload[k]);
            console.log(payload);
            fetch(process.env.VUE_APP_API_ENDPOINT + "/orders/" + payload.id,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(payload)
                },
            )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(() => {
                context.dispatch('loadOrder', payload.id);
            })
        },
        loadDiscounts(context) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/discounts",
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer '+ token
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    context.commit('setDiscounts', data);
                })
                .catch(err => {
                    console.log(err);
                })
        },
        loadDiscount(context,id) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT +"/discounts/" + id,
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer '+ token
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    data.rental = data.rental === 1;
                    data.saleItems = data.sale_items === 1;
                    data.shippingCosts = data.shippingcosts === 1;
                    data.startDate = data.startdate;
                    delete data.startdate;
                    data.endDate = data.enddate;
                    delete data.enddate;
                    data.minimumOrderAmount = data.minimum_order;
                    context.commit('setDiscount', data);
                })
        },
        updateDiscount(context,payload) {
            console.log(payload);
            let discount = payload;

            let body = {
                code: discount.code,
                description: discount.description,
                rental: discount.rental,
                sale_items: discount.saleItems,
                shippingcosts: discount.shippingCosts,
                method: discount.method,
                value: discount.value,
                minimum_order: discount.minimumOrderAmount,
                startdate: dayjs(payload.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                enddate: dayjs(payload.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD')
            };
            let token = context.rootGetters.token;
            console.log(body);

            fetch(process.env.VUE_APP_API_ENDPOINT + "/discounts/" + discount.id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                },
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    console.log('Finished')
                    console.log(data);
                })

        },
        addDiscount(context,payload) {
            let token = context.rootGetters.token;

            payload.startDate = dayjs(payload.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            payload.endDate = dayjs(payload.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
            console.log(payload);
            fetch(process.env.VUE_APP_API_ENDPOINT + "/discounts/",
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(payload)
                },
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(() => {
                    context.dispatch('loadDiscounts')
                })
        },
        deleteDiscount(context, id) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/discounts/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    }
                },
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(() => {
                    context.dispatch('loadDiscounts')
                })
        }
    },
    getters: {
        orders(state) {
            return state.orders;
        },
        order(state) {
            return state.order;
        },
        discounts(state) {
            return state.discounts;
        },
        discount(state) {
            console.log(state.discount);
            return state.discount;
        }
    }
};
