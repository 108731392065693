<template>
  <div class="container mt-5 mb-5">
    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="card">

          <div class="invoice p-5">
            <h5>Hoe nu verder?</h5>

            <p>Bedankt voor je registratie. We hebben een email verstuurd naar een opgegeven email adres.</p>
            <p>In deze email staat een link om je account te activeren.</p>
            <br>

            <p class="font-weight-bold mb-0">Bedankt dat je voor ons hebt gekozen!</p><br> <span>{{store.name}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountConfirmation",
  computed: {
    store() {
      return this.$store.getters.store;
    }
  }
}
</script>

<style scoped>

</style>