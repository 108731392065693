<template>
  <product-form></product-form>

</template>

<script>
import ProductForm from "@/components/admin/ProductForm";

export default {
  name: "AdminNewProduct",
  components: {
    ProductForm
  }
}
</script>

<style scoped>

</style>