<template>
    <div class="card product-card">
      <button class="btn-wishlist btn-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="Add to wishlist"><i class="ci-heart"></i></button>
      <router-link class="card-img-top d-block overflow-hidden" :to="product.link">
        <img v-if="product.image_link !== null" :src="product.image_link + '?width=450&height=300'" :alt="product.title">
        <img v-else :src="store.noProductImage + '?width=450&height=300'" :alt="product.title">
      </router-link>
      <div class="card-body py-2"><router-link class="product-meta d-block fs-xs pb-1" :to="product.category.link">{{product.category.description}}</router-link>
        <h3 class="product-title fs-sm"><router-link :to="product.link">{{product.title}}</router-link></h3>
        <div class="d-flex justify-content-between">
          <div class="product-price"><span class="text-accent">€ {{Math.floor(product.price)}}.<small>{{product.price.toFixed(2).slice(-2)}}</small></span></div>
          <product-rating :rating="product.rating"></product-rating>
        </div>
      </div>
      <div class="card-body card-body-hidden">
        <button @click="addToCart" :class="{clicked: btnClicked}" class="btn btn-primary cart-button btn-sm d-block w-100 mb-2" type="button">
          <span class="add-to-cart"><i class="ci-cart fs-sm me-1"></i>{{product.saleItem?'Kopen':'Huren'}}</span>
          <span class="added">geselecteerd</span> <i class="ci-arrow-down-circle"></i>
        </button>
      </div>
    </div>
    <hr class="d-sm-none">
</template>

<script>
import ProductRating from "./ProductRating";

export default {
  props: ['product'],
  components: {ProductRating},
  data() {
    return {
      btnClicked: false
    }
  },
  methods: {
    addToCart() {
      this.$store.dispatch('cart/addToCart', {
        id: this.product.id
      });
      this.btnClicked = true;
      setTimeout(() => this.btnClicked = false, 2000);
    },
  },
  computed: {
    store() {
      return this.$store.getters.store;
    }
  }
}
</script>

<style scoped>
.cart-button {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0px;
  overflow: hidden
}
.cart-button:focus {
  outline: none !important
}

.cart-button .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.cart-button span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.cart-button span.added {
  opacity: 0
}


.cart-button.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.cart-button.clicked {
  animation: color 1s ease-in forwards
}

.cart-button.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.cart-button.clicked span.add-to-cart {
  animation: addcart 1s ease-in forwards
}

.cart-button.clicked span.added {
  animation: added 1s ease-in forwards
}

@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes addcart {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes added {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}
</style>

