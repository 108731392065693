import {createStore} from "vuex";

import productsModule from './modules/products.js';
import cartModule from './modules/cart.js';
import authModule from './modules/auth.js';
import ordersModule from './modules/orders.js';

// import { useHead } from '@vueuse/head'


const store = createStore({
    modules: {
        prods: productsModule,
        cart: cartModule,
        auth: authModule,
        ords: ordersModule,
    },
    state() {
        return {
            user: 1,
            store: null,
            api_offline: false,
            maintenance: true,
            pages: null,
            page: null,
            customPage: null,
            faq: null,
            faqQuestion: null,
        };
    },
    getters: {
        store(state) {
            return state.store
        },
        isApiOffline(state) {
            return state.api_offline;
        },
        isMaintenanceMode(state) {
            return state.maintenance;
        },
        pages(state) {
            return state.pages;
        },
        page(state) {
            return state.page;
        },
        customPage(state) {
            return state.customPage
        },
        faq(state) {
            return state.faq;
        },
        faqQuestion(state) {
            return state.faqQuestion;
        }

    },
    actions: {
        loadStore(context) {
            fetch(process.env.VUE_APP_API_ENDPOINT + '/stores?' + new URLSearchParams({
                store: process.env.VUE_APP_STORE,
            }))
            .then((response) => {
                context.dispatch('setApiOffline', false, {root: true});
                if (response.ok) {
                    return response.json();
                }
            })
            .then(rows => {
                let store = {};
                let row = rows[0];
                store.store = row.store;
                store.id = row.id;
                store.logo = process.env.VUE_APP_API_ENDPOINT + '/images/' + row.logo;
                store.icon = process.env.VUE_APP_API_ENDPOINT + '/images/' + row.icon;
                store.backgroundColor = row.background_color;
                store.buttonColor = row.button_color;
                store.name = row.name;
                store.phone = row.phone;
                store.fromEmail = row.from_email;
                store.homeImage = process.env.VUE_APP_API_ENDPOINT + '/images/' + row.home_image;
                store.homeBackgroundColor = row.home_backgroundcolor;
                store.homeHeadline1 = row.home_headline1;
                store.homeHeadline2 = row.home_headline2;
                store.homeSubline = row.home_subline;
                store.homeTitle = row.home_title;
                store.instagram = row.instagram;
                store.twitter = row.twitter;
                store.facebook = row.facebook;
                store.youtube = row.youtube;
                store.maintenanceMode = row.maintenance === 1
                store.maintenanceImage = process.env.VUE_APP_API_ENDPOINT + '/images/' + row.maintenance_image;
                store.maintenanceLine1 = row.maintenance_line1;
                store.maintenanceLine2 = row.maintenance_line2;
                store.cartName = row.cart_name;
                store.noProductsInCart = row.no_products_in_cart;
                store.cartQuote = row.cart_quote === 1;
                store.cartPayments = row.cart_payments === 1;
                store.orderEmail = row.order_email;
                store.blogLink = row.blog_link;
                store.metaDescription = row.meta_description;
                store.metaKeywords = row.meta_keywords;
                store.cartUseShowdays = row.cart_use_showdays === 1;
                store.sideMenu = row.side_menu === 1;
                store.topMenu = row.top_menu === 1;
                store.productPageExtraText = row.productpage_extratext;
                store.notificationText = row.notification_text;
                store.noProductImage = process.env.VUE_APP_API_ENDPOINT + '/images/' + row.no_product_image;
                store.orderShipText = row.order_ship_text;
                store.orderReturnText = row.order_return_text;
                store.showOnOverview = row.show_on_overview;
                store.googleAnalyticsTag = row.google_analytics_tag;
                store.address = {
                    street: row.street,
                    zipcode: row.zip_code,
                    city: row.city,
                    country: row.country,
                }
                store.googleMap = row.google_map;
                store.faqIntrotext = row.faq_introtext;
                store.kvk = row.kvk;
                context.commit('setMaintenanceMode', row.maintenance);
                context.commit('setStore', store);
            })
            .catch(() => {
                //console.log(err)
                context.dispatch('setApiOffline', true, {root: true});
                setTimeout(() => {
                    context.dispatch('loadStore', context);
                }, 10000)
            })
        },
        setApiOffline(context, payload) {
            context.commit('setApiOffline', payload);
        },
        updateStore(context, payload) {
            let token = context.rootGetters.token;
            console.log('updateStore');
            console.log(payload);
            //let body = Object.fromEntries(Object.entries(payload).filter(([_, v]) => v != null));
            Object.keys(payload).forEach((k) => payload[k] == null && delete payload[k]);

            fetch(process.env.VUE_APP_API_ENDPOINT + "/stores/" + payload.id,
                {
                    method: 'PATCH',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(payload)
                },
            )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then(() => {
                context.dispatch('loadStore');
            })
        },
        addMaintenanceImage(context, payload) {
            let formData = new FormData();
            let filename = payload.filename

            formData.append( 'file', filename );
            let token = context.rootGetters.token;
            fetch( process.env.VUE_APP_API_ENDPOINT + '/images', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer '+ token
                },
                body: formData
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((result) => {
                    console.log(result.id)
                    let dbFields = {
                        home: 'home_image',
                        logo: 'logo',
                        icon: 'icon',
                        maintenance : 'maintenance_image',
                        noproductimage: 'no_product_image',
                    };
                    let field = dbFields[payload.field];
                    console.log(field);
                    context.dispatch('updateStore', {id: payload.storeId, [dbFields[payload.field]]: result.id});
                })
        },
        signupNewsletter(context, payload) {
            fetch( process.env.VUE_APP_API_ENDPOINT + '/signupnewsletter', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE,
                },
                body: JSON.stringify({email: payload})
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((result) => {
                console.log(result);
            })
            .catch(err => {
                console.log(err)
            })
        },
        loadPages(context) {
            let token = context.rootGetters.token;
            fetch( process.env.VUE_APP_API_ENDPOINT + '/pages', {
                headers: {
                    'Authorization': 'Bearer '+ token,
                    'store': process.env.VUE_APP_STORE
                }
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            })
            .then((results) => {
                context.commit('setPages', results);
            })
            .catch(err => {
                console.log(err)
            })
        },
        loadPage(context,id) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT +"/pages/" + id,
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer '+ token
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    data.published = (data.published === 1)
                    context.commit('setPage', data);
                })
        },
        loadFAQ(context, admin = false) {
            let headers = {};
            if (admin) {
                let token = context.rootGetters.token;
                headers = {
                    'Authorization': 'Bearer ' + token,
                    'store': process.env.VUE_APP_STORE
                }
            } else {
                headers = {
                    'store': process.env.VUE_APP_STORE
                }

            }

            fetch( process.env.VUE_APP_API_ENDPOINT + '/faq', {
                headers: headers
            })
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then((results) => {
                  context.commit('setFAQ', results);
              })
              .catch(err => {
                  console.log(err)
              })
        },
        loadFAQQuestion(context, code) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT +"/faq/" + code,
              {
                  headers: {
                      'store': process.env.VUE_APP_STORE,
                      'Authorization': 'Bearer '+ token
                  }
              })
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then((data) => {
                  data.published = (data.published === 1)
                  context.commit('setFAQQuestion', data);
              })
        },
        addQuestion(context,payload) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/faq/",
              {
                  method: 'POST',
                  headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      'store': process.env.VUE_APP_STORE,
                      'Authorization' : 'Bearer ' + token
                  },
                  body: JSON.stringify(payload)
              },
            )
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then(() => {
                  context.dispatch('loadFAQ')
              })
        },
        updateQuestion(context,payload) {
            let body = {
                code: payload.code,
                question: payload.question,
                answer: payload.answer,
                published: payload.published
            };
            let token = context.rootGetters.token;

            fetch(process.env.VUE_APP_API_ENDPOINT + "/faq/" + payload.id,
              {
                  method: 'PUT',
                  headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      'store': process.env.VUE_APP_STORE,
                      'Authorization' : 'Bearer ' + token
                  },
                  body: JSON.stringify(body)
              },
            )
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then((data) => {
                  console.log('Finished')
                  console.log(data);
              })

        },
        deleteQuestion(context, id) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/faq/" + id,
              {
                  method: 'DELETE',
                  headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      'store': process.env.VUE_APP_STORE,
                      'Authorization' : 'Bearer ' + token
                  }
              },
            )
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then(() => {
                  context.dispatch('loadFAQ')
              })
        },
        loadCustomPage(context,code) {
            fetch(process.env.VUE_APP_API_ENDPOINT +"/pages/" + code,
                {
                    headers: {
                        'store': process.env.VUE_APP_STORE
                    }
                })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    context.commit('setCustomPage', data);
                })
        },
        addPage(context,payload) {
            let token = context.rootGetters.token;


            fetch(process.env.VUE_APP_API_ENDPOINT + "/pages/",
                {
                    method: 'POST',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(payload)
                },
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(() => {
                    context.dispatch('loadPages')
                })
        },
        deletePage(context, id) {
            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/pages/" + id,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    }
                },
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(() => {
                    context.dispatch('loadPages')
                })
        },
        updatePage(context,payload) {
            let body = {
                code: payload.code,
                title: payload.title,
                content: payload.content,
                published: payload.published
            };
            let token = context.rootGetters.token;

            fetch(process.env.VUE_APP_API_ENDPOINT + "/pages/" + payload.id,
                {
                    method: 'PUT',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization' : 'Bearer ' + token
                    },
                    body: JSON.stringify(body)
                },
            )
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then((data) => {
                    console.log('Finished')
                    console.log(data);
                })

        },
        sendContactMessage(context, payload) {

            let token = context.rootGetters.token;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/contactmessage/",
              {
                  method: 'POST',
                  headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      'store': process.env.VUE_APP_STORE,
                      'Authorization' : 'Bearer ' + token
                  },
                  body: JSON.stringify(payload)
              },
            )
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then(() => {
                  console.log('Bericht is verzonden');
              })
        },
        sendHelpRequest(context, payload) {

            fetch(process.env.VUE_APP_API_ENDPOINT + "/helprequest/",
              {
                  method: 'POST',
                  headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      'store': process.env.VUE_APP_STORE
                  },
                  body: JSON.stringify(payload)
              },
            )
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then(() => {
                  console.log('Bericht is verzonden');
              })
        }

    },
    mutations: {
        setPages(state, payload) {
          state.pages = payload;
        },
        setPage(state, payload) {
            state.page = payload
        },
        setFAQ(state, payload) {
            state.faq = payload;
        },
        setFAQQuestion(state, payload) {
            state.faqQuestion = payload;
        },
        setStore(state, payload) {
            console.log(payload);
            if (payload.homeTitle !== undefined && payload.homeTitle !== null) {
                document.title = payload.homeTitle;
            } else {
                document.title = payload.name;
            }
            const descEl = document.querySelector('head meta[name="description"]');
            const keyEl = document.querySelector('head meta[name="keywords"]');
            descEl.setAttribute('content', payload.metaDescription);
            keyEl.setAttribute('content', payload.metaKeywords);

            state.store = payload;
        },
        setApiOffline(state, payload) {
            state.api_offline = payload;
        },
        setMaintenanceMode(state, payload) {
            state.maintenance = (payload === 1)
        },
        setMaintenanceImage(state, payload) {
            state.store.maintenanceImage = payload;
        },
        setCustomPage(state, payload) {
            state.customPage = payload;
        }
    }
});

export default store;