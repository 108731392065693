<template>
  <main v-if="store">

    <section class="tns-carousel tns-controls-lg">
      <div class="tns-outer" id="tns2-ow">

        <div class="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide <span class="current">1</span>  of 3</div>
        <div class="tns-inner" id="tns2-iw">
          <div class="tns-carousel-inner tns-slider tns-gallery tns-subpixel tns-calc tns-horizontal" data-carousel-options="{&quot;mode&quot;: &quot;gallery&quot;, &quot;responsive&quot;: {&quot;0&quot;:{&quot;nav&quot;:true, &quot;controls&quot;: false},&quot;992&quot;:{&quot;nav&quot;:false, &quot;controls&quot;: true}}}" id="tns2">
            <!-- Item-->
<!--            <div class="px-lg-5 tns-item tns-slide-active tns-fadeIn" style="background-color: rgb(22, 39, 57); left: 0%;" id="tns2-item0">-->
            <div v-if="store" class="px-lg-5 tns-item tns-slide-active tns-fadeIn" style="left: 0%" :style="{'background-color': store.homeBackgroundColor}" id="tns2-item0">
              <div class="d-lg-flex justify-content-between align-items-center ps-lg-4">
                <img class="d-block order-lg-2 me-lg-n5 flex-shrink-0" :src="store.homeImage" alt="Verhuur">
                <div class="position-relative mx-auto me-lg-n5 py-5 px-4 mb-lg-5 order-lg-1" style="max-width: 42rem; z-index: 10;">
                  <div class="pb-lg-5 mb-lg-5 text-center text-lg-start text-lg-nowrap">
                    <h3 class="h2 text-light fw-light pb-1 from-start">{{ store.homeHeadline1  }}</h3>
                    <h2 class="text-light display-5 from-start delay-1">{{ store.homeHeadline2  }}</h2>
                    <p class="fs-lg text-light pb-3 from-start delay-2">{{ store.homeSubline  }}</p>
                    <div class="d-table scale-up delay-4 mx-auto mx-lg-0">
                      <router-link class="btn btn-primary" to="/verhuur">Bekijk<i class="ci-arrow-right ms-2 me-n1"></i></router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- Popular categories-->
    <section v-if="popCategories" class="container position-relative pt-3 pt-lg-0 pb-5 mt-lg-n10" style="z-index: 10;">
      <div class="row">
        <div class="col-xl-10 col-lg-12">
          <div class="card border-0 shadow-lg">
            <div class="card-body px-3 pt-grid-gutter pb-0">
              <div class="row g-0 ps-1">
                <div v-for="cat in popCategories" :key="cat.id" class="col-sm-3 px-2 mb-grid-gutter">
                  <router-link class="d-block text-center text-decoration-none me-1" :to="cat.link">
                    <img class="img-thumbnail d-block rounded mb-3" :src="cat.image_link + '?height=240&width=240'" :alt="cat.description">
                    <h3 class="fs-base pt-1 mb-0">{{cat.description}}</h3>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Products grid (Trending products)-->
    <section class="container pt-md-3 pb-5 mb-md-3">
      <h2 class="h3 text-center">Trending products</h2>
      <div class="row pt-4 mx-n2">
        <!-- Product-->
        <div v-for="product in trendingProducts" :key="product.id" class="col-lg-3 col-md-4 col-sm-6 px-2 mb-4">
          <product-card  :product="product"></product-card>
        </div>
      </div>
      <div class="text-center pt-3"><router-link class="btn btn-outline-accent" to="/verhuur">Meer producten<i class="ci-arrow-right ms-1"></i></router-link></div>
    </section>



    <!-- Blog + Instagram info cards-->
    <section class="container-fluid px-0">
      <div class="row g-0">
        <div class="col-md-6"><a class="card border-0 rounded-0 text-decoration-none py-md-4 bg-faded-primary" :href="store.blogLink" target="_blank">
          <div class="card-body text-center"><i class="ci-edit h3 mt-2 mb-4 text-primary"></i>
            <h3 class="h5 mb-1">Lees onze verhalen</h3>
            <p class="text-muted fs-sm">Laatste nieuws</p>
          </div></a></div>
        <div class="col-md-6"><a class="card border-0 rounded-0 text-decoration-none py-md-4 bg-faded-accent" href="https://www.instagram.com/partyhuren/">
          <div class="card-body text-center"><i class="ci-instagram h3 mt-2 mb-4 text-accent"></i>
            <h3 class="h5 mb-1">Volg ons Instagram</h3>
            <p class="text-muted fs-sm">{{ store.instagram }}</p>
          </div></a></div>
      </div>
    </section>
  </main>
  <!-- Toolbar for handheld devices (Default)-->

</template>

<script>
import ProductCard from "../components/ProductCard";
export default {

  name: "Home",
  components: {
    ProductCard
  },
  computed: {
    popCategories() {

      if (this.featuredCategories) {
        let result = [];
        this.featuredCategories.map(c => {
          let cat = this.categories.find(cat => cat.id == c.item_id);
          if (cat) {
            result.push(cat);
          }
        });
        return result;
      } else {
        return []
      }
    },
    categories() {
      return this.$store.getters['prods/flatCategories']
    },
    products() {
      return this.$store.getters['prods/products']
    },
    trendingProducts() {
      if (this.featuredProducts) {
        let result = [];
        this.featuredProducts.map(p => {
          let product = this.products.find(prod => prod.id == p.item_id);
          if (product) {
            result.push(product);
          }
        });
        return result;
      } else {
        return []
      }
    },
    isLogggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    store() {
      return this.$store.getters.store;
    },
    featuredProducts() {
      return this.$store.getters['prods/featuredProducts'];
    },
    featuredCategories() {
      return this.$store.getters['prods/featuredCategories'];
    },
  },
  created() {
    this.$store.dispatch('prods/loadFlatCategories', {});
  }
}
</script>

<style scoped>
/*#tns2 > .tns-item {*/
/*  width: calc(100%);*/
/*  transition-duration: 0.5s;*/
/*  animation-duration: 0.5s;*/
/*}*/
#tns-item>img {
  padding-left: -100px;
}
</style>