<template>
  <div class="modal fade" id="quoteNumber" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Offerte versturen</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <label for="quote-number" class="col-form-label">Offerte nummer:</label>
          <input type="text" class="form-control" id="quote-number" v-model="quoteNumber" autofocus="true">
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Sluiten</button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="closeModal">Opslaan</button>
        </div>
      </div>
    </div>
  </div>
  <main v-if="order" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">


      <!-- start page title -->
      <div class="row" style="padding-top: 2rem">
        <div class="col-12">
          <div class="page-title-box">

            <h4 class="page-title">Order Details</h4>
          </div>
        </div>
      </div>
      <!-- end page title -->


      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Order-id: {{ order.id }}</h4>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="table-light">
                  <tr>
                    <th>Product</th>
                    <th style="text-align: center;">Aantal</th>
                    <th style="text-align: right;">Per stuk</th>
                    <th style="text-align: right;">Totaalprijs</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="product in order.products" :key="product.id">
                    <td>{{product.title}}</td>
                    <td style="text-align: center;">{{product.quantity}}</td>
                    <td style="text-align: right;">€ {{product.price.toFixed(2)}}</td>
                    <td style="text-align: right;">€ {{(product.quantity * product.price).toFixed(2)}}</td>
                  </tr>

                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->

            </div>
          </div>
        </div> <!-- end col -->

        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Order samenvatting</h4>

              <div class="table-responsive">
                <table class="table mb-0">
                  <thead class="table-light">
                  <tr>
                    <th></th>
                    <th style="text-align: right;">Prijs</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Totaal bedrag :</td>
                    <td style="text-align: right;">€ {{order.totalPrice.toFixed(2)}}</td>
                  </tr>
                  <tr v-if="order.discount.code !== undefined">
                    <td>Korting :</td>
                    <td style="text-align: right;">€ {{order.discount.amount.toFixed(2)}}</td>
                  </tr>
                  <tr>
                    <td>Transportkosten</td>
                    <td style="text-align: right;">€ {{order.shippingCosts.toFixed(2)}}</td>
                  </tr>
                  <tr>
                    <td>BTW </td>
                    <td style="text-align: right;">€ {{ ((order.totalPrice + order.shippingCosts - discountAmount)/121*21).toFixed(2)}}</td>
                  </tr>
                  <tr>
                    <th>Totaal :</th>
                    <th style="text-align: right;">€ {{(order.totalPrice + order.shippingCosts - discountAmount).toFixed(2)}}</th>
                  </tr>
                  </tbody>
                </table>
              </div>
              <!-- end table-responsive -->

            </div>
          </div>
        </div> <!-- end col -->
      </div>
      <!-- end row -->


      <div class="row" style="padding-top: 2rem">
        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Transport</h4>


              <div v-if="order.transport.shipping_method === 'us'">
                <strong>Afleveradres:</strong>
                <address class="mb-0 font-14 address-lg">
                  {{order.transport.delivery_street + ' ' + order.transport.delivery_house_number}}<br>
                  {{order.transport.delivery_zipcode + ' ' + order.transport.delivery_city}}<br>
                  {{order.customer.phone}} <br>
                  Afleveren op <strong>{{formatDate(order.transport.ship_date)}}</strong>
                </address>
              </div>
              <div v-else>
                Klant haalt zelf op <strong>{{formatDate(order.transport.ship_date)}}</strong>
                <br>
              </div>
              <br>
              <div v-if="order.transport.return_method === 'us'">
                <strong>Ophaaladres:</strong>
                <address class="mb-0 font-14 address-lg">
                  {{order.transport.pickup_street + ' ' + order.transport.delivery_house_number}}<br>
                  {{order.transport.delivery_zipcode + ' ' + order.transport.delivery_city}}<br>
                  Ophalen op <strong>{{formatDate(order.transport.return_date)}}</strong>
                </address>
              </div>
              <div v-else>

                Klant brengt zelf terug op <strong>{{formatDate(order.transport.return_date)}}</strong>
                <br>
              </div>

            </div>
          </div>
        </div> <!-- end col -->

        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Klantgegevens</h4>

              <ul class="list-unstyled mb-0">
                <li>
                  <p v-if="order.customer.company_name" class="mb-2"><span class="fw-bold me-2">Bedrijfsnaam:</span> {{order.customer.company_name}}</p>
                  <p class="mb-2"><span class="fw-bold me-2">Klant:</span>{{ order.customer.first_name }} {{order.customer.last_name}}</p>
                  <p class="mb-2"><span class="fw-bold me-2">Adres:</span>{{ order.customer.address.street }} {{ order.customer.address.house_number }}</p>
                  <p class="mb-2">{{ order.customer.address.zipcode }} {{ order.customer.address.city }}</p>
                  <p class="mb-2"><span class="fw-bold me-2">Email:</span>{{ order.customer.email_address }}</p>
                </li>
              </ul>

            </div>
          </div>
        </div> <!-- end col -->

        <div class="col-lg-4">
          <div class="card">
            <div class="card-body">
              <h4 class="header-title mb-3">Betaling</h4>

              <div v-if="order.quoteRequested">

                <p class="mb-1">Klant heeft offerte aangevraagd</p>
                <button v-if="order.quoteNumber === null || order.quoteNumber === ''" @click="quoteSent" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#quoteNumber">Offerte verstuurd</button>
                <p v-else>Offerte-id: <strong>{{order.quoteNumber}}</strong></p>
              </div>
            </div>

          </div>
        </div> <!-- end col -->
      </div>
      <!-- end row -->
  </main>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "AdminProducts",
  props: ['orderId'],
  data() {
    return {
      quoteNumber : null
    }
  },
  methods: {
    formatDate(dateString) {
      const date = dayjs(dateString);
      return date.format('dddd DD-MM-YYYY');
    },
    closeModal() {
      console.log(this.quoteNumber);
      this.$store.dispatch('ords/patchOrder', {
        id: this.order.id,
        quoteNumber: this.quoteNumber
      });
    }
  },
  computed: {
    order() {
      return this.$store.getters['ords/order'];
    },
    discountAmount() {
      console.log(this.order.discount.code);
      return (this.order.discount !== undefined && this.order.discount.code !== undefined ? this.order.discount.amount : 0);
    }
  },
  created() {
    this.$store.dispatch('ords/loadOrder', this.orderId);
  }
}
</script>

<style scoped>

</style>