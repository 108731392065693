<template>
  <div v-if="page">
    <page-title :title="page.title"></page-title>

    <div v-if="page.code === '404'" class="container py-5 mb-lg-3" >
      <div class="row justify-content-center pt-lg-4 text-center">
        <div class="col-lg-5 col-md-7 col-sm-9">
          <h1 class="display-404 py-lg-3">404</h1>
            <h2 class="h3 mb-4">Dit kunnen we helaas niet vinden.</h2>
        </div>
      </div>
    </div>
    <div v-else class="container pb-5 mb-2 mb-md-4">-->
          <div class="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
            <div class="px-lg-3">
              <div class="row">
                <div v-if="page" v-html="page.content"></div>
              </div>
            </div>
          </div>

        </div>
  </div>

</template>

<script>
import PageTitle from "../components/PageTitle";

export default {
  name: "CustomPage",
  props: ['code'],
  components: {
    PageTitle
  },
  computed: {
    page() {
      let page = this.$store.getters['customPage'];

      if (page === undefined) {
        page = {
          code: '404',
          title: 'Niet gevonden',
          content: ''
        }
      }

      return page;
    }
  },
  watch: {
    code(value) {
      this.$store.dispatch('loadCustomPage', value);
    }
  },
  created() {
    this.$store.dispatch('loadCustomPage', this.code);
  }
}
</script>

<style scoped>

</style>