let timer;

export default {
    state() {
        return {
            userId: null,
            user: null,
            token: null,
            didAutoLogout: false,
            admin: false
        }
    },
    getters: {
        userId(state) {
            return state.userId;
        },
        token(state) {
            return state.token;
        },
        isAuthenticated(state) {
            return !!state.token
        },
        didAutoLogout(state) {
            return state.didAutoLogout
        },
        isAdmin(state) {
            return state.admin;
        },
        user(state) {
            console.log(state.user);
            return state.user;
        }

    },
    mutations: {
        setUser(state, payload) {
            console.log(payload);
            state.token = payload.token;
            state.userId = payload.userId;
            if (payload.user) {
                state.user = {
                    id: payload.user.id,
                    firstName: payload.user.first_name,
                    lastName: payload.user.last_name,
                    emailAddress: payload.user.email,
                }
            } else {
                state.user = payload.user;
            }
            state.didAutoLogout = false;
            state.admin = payload.admin;
            console.log(state);

        },
        setAutoLogout(state) {
            state.didAutoLogout = true;
        }
    },
    actions: {
        async auth(context, payload) {
            console.log('auth called');
            const mode = payload.mode;
            let url = process.env.VUE_APP_API_ENDPOINT +"/login";
            let body = {
                email: payload.email,
                password: payload.password
            }
            if (mode === 'signup') {
                url = process.env.VUE_APP_API_ENDPOINT +"/signup";
                body = {
                    firstname: payload.firstname,
                    lastname: payload.lastname,
                    email: payload.email,
                    password: payload.password
                }
            }
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE
                },
                body: JSON.stringify(body)
            });

            const responseData = await response.json();

            if (!response.ok) {
                if(response.status === 409) {
                    const error = new Error('Dit email adres is al in gebruik');
                    throw error;
                } else {
                    const error = new Error(responseData.message || 'Failed to authenticate.')
                    throw error;
                }
            }

            if (mode === 'login') {
                const expiresIn = +responseData.expiresIn * 1000
                const expirationDate = new Date().getTime() + expiresIn;

                localStorage.setItem('token', responseData.token);
                localStorage.setItem('userId', responseData.userId);
                localStorage.setItem('admin', responseData.admin);
                localStorage.setItem('tokenExpiration', expirationDate);

                url = process.env.VUE_APP_API_ENDPOINT +"/users/" + responseData.userId;
                const userResponse = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        'store': process.env.VUE_APP_STORE,
                        'Authorization': 'Bearer ' + responseData.token
                    }
                });
                const userResponseData = await userResponse.json();

                timer = setTimeout(() => {
                    context.dispatch('autoLogout');
                }, expiresIn)

                context.commit('setUser', {
                    token: responseData.token,
                    userId: responseData.userId,
                    user: userResponseData,
                    admin: responseData.admin
                })
                return 'loggedin'
            } else {
                return 'signedup'
            }
        },
        async login(context, payload) {
            return context.dispatch('auth' , {
                ...payload,
                mode: 'login'
            })
        },
        async signup(context, payload) {
            return context.dispatch('auth' , {
                ...payload,
                mode: 'signup'
            })
        },
        logout(context) {
            localStorage.removeItem('token');
            localStorage.removeItem('userId');
            localStorage.removeItem('admin');
            localStorage.removeItem('tokenExpiration');

            clearTimeout(timer);

            context.commit('setUser', {
                token: null,
                userId: null,
                user: null,
                admin: false
            })
        },
        async tryLogin(context) {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            const admin = localStorage.getItem('admin') === 'true';
            const tokenExpiration = localStorage.getItem('tokenExpiration');

            const expiresIn = +tokenExpiration - new Date().getTime();

            if (expiresIn < 10000) {
                return;
            }

            timer = setTimeout(() => {
                context.dispatch('autoLogout');
            }, expiresIn)

            let url = process.env.VUE_APP_API_ENDPOINT +"/users/" + userId;
            const userResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE,
                    'Authorization': 'Bearer ' + token
                }
            });
            const userResponseData = await userResponse.json();

            if (token && userId) {
                context.commit('setUser', {
                    token: token,
                    userId: userId,
                    user: userResponseData,
                    admin: admin
                })
            }

        },
        activate(context, token) {
            return new Promise((resolve, reject) => {
                // Do something here... lets say, a http call using vue-resource
                fetch(process.env.VUE_APP_API_ENDPOINT + "/activate/" + token,{
                     headers: {
                         'store': process.env.VUE_APP_STORE
                     }
                 })
                    .then(response => {
                        if (response.status === 200) {
                            resolve(response)
                        } else {
                            reject(response)
                        }

                }, error => {
                    reject(error);
                })
            })
        },
        autoLogout(context) {
            context.dispatch('logout');
            context.commit('setAutoLogout');
        },
        saveUser(context, payload) {
            const token = localStorage.getItem('token');
            console.log(payload);

            fetch(process.env.VUE_APP_API_ENDPOINT + "/users/" + payload.id, {
                method: 'PATCH',
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    'store': process.env.VUE_APP_STORE,
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(payload)
            })
              .then((response) => {
                  if (response.ok) {
                      return response.json();
                  }
              })
              .then((data) => {
                  console.log('Finished')
                  console.log(data);
              })
        }
    }
}