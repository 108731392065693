<template>
  <div class="container py-5 mt-md-2 mb-2">
    <div class="row">
      <div class="col-lg-3">
        <!-- Related articles sidebar-->

      </div>
      <div v-if="question" class="col-lg-9">
<!--        <div class="card">-->
<!--          <h2 class="card-title h4 pb-3">{{question.question}}</h2>-->
<!--          <div class="card-text" v-if="question" v-html="question.answer"></div>-->
<!--        </div>-->
        <div class="col-lg-12 col-sm-12 mb-grid-gutter">
          <div class="card border-0 shadow">
            <div class="card-body">
              <h6>{{question.question}}</h6>
              <p v-if="question" v-html="question.answer" class="fs-sm text-muted pb-2"></p>
            </div>
          </div>
        </div>
        <section class="container text-center pt-5 mt-2">
          <h2 class="h4 pb-3">Heb je niet het juiste antwoord gevonden? We kunnen je helpen.</h2><i class="ci-help h3 text-primary d-block mb-4"></i><router-link class="btn btn-primary" to="/faq/need-help">Stel je vraag</router-link>
          <p class="fs-sm pt-4">Neem contact op en we komen snel bij je terug.</p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpFAQQuestion",

  props: ['code'],
  computed: {
    question() {
      return this.$store.getters['faqQuestion'];
    }
  },
  created() {
    this.$store.dispatch('loadFAQQuestion', this.code);
  }
};
</script>

<style scoped>
.card {
  padding:10px;
}

</style>