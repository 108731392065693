<template>
  <h2 class="h6 pb-3 mb-2">Hoe wil je betalen?</h2>
  <div class="accordion mb-2" id="payment-method">
    <div v-if="store.cartQuote" class="accordion-item">
      <h3 class="accordion-header"><a class="accordion-button collapsed" href="#points" data-bs-toggle="collapse"><i class="ci-document me-2"></i>Vraag een offerte aan</a></h3>
      <div  class="accordion-collapse collapse show" id="points" data-bs-parent="#payment-method">
        <div class="accordion-body">
          <div class="form-check d-block">
            <input class="form-check-input" type="checkbox" id="request_quote" v-model="cartPayment.requestQuote">
            <label class="form-check-label" for="request_quote">Mail me een offerte voor mijn bestelling. Hiermee is de bestelling nog niet definitief.</label>
          </div>
          <p>Binnen 24 uur ontvangen je van ons een offerte op basis van je bestelling. Deze offerte bevat een betaallink waarmee je de offerte kunt omzetten in een order.</p>

        </div>
      </div>
    </div>
    <div v-if="store.cartPayments" class="accordion-item">
      <h3 class="accordion-header"><a class="accordion-button collapsed" href="#ideal" data-bs-toggle="collapse"><i class="fab fa-ideal me-2 align-middle"></i>Betalen met iDEAL</a></h3>
      <div class="accordion-collapse collapse show" id="ideal" data-bs-parent="#payment-method">
        <div class="accordion-body fs-sm">
          <p><span class='fw-medium'>iDEAL betaling</span> - wordt uitgevoerd door Mollie</p>

          <div class="col-sm-6">
            <div class="mb-3">
              <label class="form-label" for="checkout-bank">Bank</label>
              <select class="form-select" id="checkout-bank" v-model="cartPayment.bank">
                <option value="">Kies je bank</option>
                <option value="ABN AMRO">ABN AMRO</option>
                <option value="ASN Bank">ASN Bank</option>
                <option value="bunq">bunq</option>
                <option value="ING">ING</option>
                <option value="Knab">Knab</option>
                <option value="Rabobank">Rabobank</option>
                <option value="Regiobank">Regiobank</option>
                <option value="Revolut">Revolut</option>
                <option value="SNS">SNS</option>
                <option value="Svenska Handelsbanken">Svenska Handelsbanken</option>
                <option value="Triodos Bank">Triodos Bank</option>
                <option value="Van Lanschot">Van Lanschot</option>
              </select>
            </div>
          </div>
          <div :style="{display:formValidated&&cartPayment.bank===''?'block':'none'}" class="invalid-feedback">Kies je bank als je via iDEAL wilt betalen of vraag een offerte aan.</div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
export default {
  name: "CheckoutFormPayment",
  props: ['formValidated'],
  computed: {
    cartCustomer() {
      return this.$store.getters['cart/customer'];
    },
    cartPayment: {
      get () {
        return this.$store.getters['cart/payment'];
      },
      set (value) {
        this.$store.commit('cart/setPayment', value);
      }
    },
    store() {
      return this.$store.getters.store;
    },
  },
}
</script>

<style scoped>

</style>