<template>
  <faq-question-form :code="code"></faq-question-form>
</template>

<script>

import FaqQuestionForm from "./FaqQuestionForm";

export default {
  name: "AdminFaqQuestion",
  props: ['code'],
  components: {
    FaqQuestionForm
  }
}
</script>

<style scoped>

</style>