<template>
  <page-title v-if="product" :title="product.title"></page-title>
  <div v-if="product" class="container">
    <!-- Gallery + details-->
    <div class="bg-light shadow-lg rounded-3 px-4 py-3 mb-5">
      <div class="px-lg-3">
        <div class="row">
          <!-- Product gallery-->
          <product-gallery :product="product"></product-gallery>

          <!-- Product details-->
          <div class="col-lg-5 pt-4 pt-lg-0">
            <div class="product-details ms-auto pb-3">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <a href="#reviews" data-scroll :title="productRating.toFixed(1)">
                  <product-rating :rating="Math.floor(productRating)"></product-rating>
                  <span class="d-inline-block fs-sm text-body align-middle mt-1 ms-1">{{countReviews}} beoordelingen</span>
                </a>
                <button class="btn-wishlist me-0 me-lg-n3" type="button" data-bs-toggle="tooltip" title="Add to wishlist"><i class="ci-heart"></i></button>
              </div>
              <div class="mb-3"><span class="h3 fw-normal text-accent me-1">€ {{Math.floor(productPrice)}}.<small>{{productPrice.toFixed(2).slice(-2)}}</small></span>incl BTW
                <del v-if="product.sale" class="text-muted fs-lg me-3">€ {{Math.floor(oldProductPrice)}}.<small>{{oldProductPrice.toFixed(2).slice(-2)}}</small></del><span v-if="product.sale" class="badge bg-danger badge-shadow align-middle mt-n2">Sale</span>
              </div>




                <div class="mb-3 d-flex align-items-center">
                  <select v-model="quantityRent" class="form-select me-3" style="width: 5rem;">
                    <option v-for="idx in productQuantity" :key="idx" :value="idx">{{idx}}</option>
                  </select>
                  <button @click="addToCart" :class="{clicked: btnClicked}" class="btn btn-primary cart-button btn-shadow d-block w-100">
                    <span class="add-to-cart"><i class="ci-cart fs-lg me-2"></i>Huren</span>
                    <span class="added">in de winkelmand</span> <i class="ci-arrow-down-circle"></i>
                  </button>

                </div>
              <p class="fs-sm text-black-50" v-html="store.productPageExtraText"></p>

              <!-- Product panels-->
              <div class="accordion mb-4" id="productPanels">
                <div class="accordion-item">
                  <h3 class="accordion-header"><a class="accordion-button" href="#productInfo" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="productInfo"><i class="ci-announcement text-muted fs-lg align-middle mt-n1 me-2"></i>Product info</a></h3>
                  <div class="accordion-collapse collapse show" id="productInfo" data-bs-parent="#productPanels">
                    <div v-html="product.info" class="accordion-body">

                    </div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h3 class="accordion-header"><a class="accordion-button collapsed" href="#shippingOptions" role="button" data-bs-toggle="collapse" aria-expanded="true" aria-controls="shippingOptions"><i class="ci-delivery text-muted lead align-middle mt-n1 me-2"></i>Verzend opties</a></h3>
                  <div class="accordion-collapse collapse" id="shippingOptions" data-bs-parent="#productPanels">
                    <div class="accordion-body fs-sm">

                      <div class="d-flex justify-content-between border-bottom py-2">
                        <div>
                          <div class="fw-semibold text-dark">Laten bezorgen</div>
                          <div class="fs-sm text-muted">1 dag</div>
                        </div>
                        <div>word later vastgesteld</div>
                      </div>
                      <div class="d-flex justify-content-between pt-2">
                        <div>
                          <div class="fw-semibold text-dark">Zelf ophalen</div>
                          <div class="fs-sm text-muted">&mdash;</div>
                        </div>
                        <div>€0.00</div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <!-- Sharing-->
              <label class="form-label d-inline-block align-middle my-2 me-3">Delen:</label><a class="btn-share btn-twitter me-2 my-2" target="_blank" href="https://twitter.com/intent/tweet?text=Wat een geweldig product"><i class="ci-twitter"></i>Twitter</a><a class="btn-share btn-instagram me-2 my-2" target="_blank" href="https://www.instagram.com"><i class="ci-instagram"></i>Instagram</a><a class="btn-share btn-facebook my-2" target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.nextsense.nl%2F&amp;src=sdkpreparse"><i class="ci-facebook"></i>Facebook</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Product description section 1-->
    <div class="row my-lg-3 py-5">
      <div v-html="product.description" class="col-lg-5 col-md-6 offset-lg-1"></div>
      <div v-if="product.accessoires.length >0" class="col-lg-4 col-md-6">
        <!-- Widget: Featured products list -->
        <div class="widget">
          <h3 class="widget-title">Aanvullende producten</h3>

          <div v-for="acc, idx in product.accessoires" :key="acc.id" class="d-flex align-items-center py-2 border-bottom">
            <router-link :to="acc.link" class="flex-shrink-0">
              <img :src="acc.image" width="64" alt="Product"/>
            </router-link>
            <div class="ps-2">
              <h6 class="widget-product-title"><router-link :to="acc.link">{{ acc.title }}</router-link></h6>
              <div class="widget-product-meta">
<!--                <span class="text-accent me-2">€ 150.<small>00</small></span>-->
                <span class="text-accent me-2">€ {{Math.floor(acc.price)}}.<small>{{acc.price.toFixed(2).slice(-2)}}</small></span>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :id="acc.id" @change="toggleAccessoire($event)" v-model="checks[idx]">
                  <label class="form-check-label" :for="acc.id">Mee bestellen</label>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>

  </div>
  <!-- Reviews-->
  <product-reviews v-if="product" :product="product" :productRating="productRating" @reviewAdded="refreshProduct()"></product-reviews>
</template>

<script>
import PageTitle from "../components/PageTitle";
import ProductReviews from "../components/ProductReviews";
import ProductRating from "../components/ProductRating";
import ProductGallery from "../components/ProductGallery";

import dayjs from 'dayjs';
import 'dayjs/locale/nl'

dayjs.locale('nl');

export default {
  data() {
    return {
      countRatings5: 0,
      countRatings4: 0,
      countRatings3: 0,
      countRatings2: 0,
      countRatings1: 0,
      quantityRent: 1,
      btnClicked: false,
      acc_included: [],
      checks: []
    }
  },
  components: {
    PageTitle,
    ProductReviews,
    ProductRating,
    ProductGallery
  },
  methods: {
    addToCart() {
      this.$store.dispatch('cart/addToCart', {
        id: this.product.id,
        qty: this.quantityRent,
        accessoires: this.acc_included
      });
      this.btnClicked = true;
      this.acc_included = [];
      this.checks = [];
      setTimeout(() => this.btnClicked = false, 4000);
    },
    toggleAccessoire(event) {
      if (event.target.checked) {
        this.acc_included.push(event.target.id);
      } else {
        this.acc_included = this.acc_included.filter(i => i !== event.target.id);
      }
    },
    refreshProduct() {
      this.$store.dispatch('prods/loadProduct', this.prodId);
    },
  },
  computed: {
    countReviews() {
      return this.product&&this.product.reviews!=undefined?this.product.reviews.length:0
    },
    productRating() {
      let avg = 0;
      if (this.product && this.product.reviews!=undefined) {
        const reviewRatings = this.product.reviews.map(item => item.rating);
        avg = reviewRatings.reduce((runningTotal, rating) => runningTotal + rating,0) / reviewRatings.length;
      }
      return avg
    },
    prodId() {
      return this.$route.params.prodId;
    },
    productPrice() {
      return this.product&&this.product.price!=undefined?(this.product.sale?this.product.sales_price:this.product.price):0
    },
    oldProductPrice() {
      return this.product&&this.product.price!=undefined?this.product.price:0
    },
    productQuantity() {
      return this.product&&this.product.quantity!=undefined?this.product.quantity:1
    },
    product() {
      return this.$store.getters['prods/product'];
    },
    store() {
      return this.$store.getters.store;
    },


  },
  watch: {
    prodId(value) {
      if (value !== undefined) {
        this.$store.dispatch('prods/loadProduct', value);
      }
    },
    product(value) {
      document.title = this.store.name + ' - Verhuur ' + value.title;
      const descEl = document.querySelector('head meta[name="description"]');
      const keyEl = document.querySelector('head meta[name="keywords"]');
      descEl.setAttribute('content', value.title + ' kun je huren bij ' +  this.store.name);
      keyEl.setAttribute('content', value.title);
    }
  },

  created() {
    this.$store.dispatch('prods/loadProduct', this.prodId);
  }
}
</script>

<style scoped>
.cart-button {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0px;
  overflow: hidden
}
.cart-button:focus {
  outline: none !important
}

.cart-button .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.cart-button span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.cart-button span.added {
  opacity: 0
}


.cart-button.clicked .fa-plus {
  animation: addicon 2s ease-in forwards
}

.cart-button.clicked {
  animation: color 2s ease-in forwards
}

.cart-button.clicked .ci-arrow-down-circle {
  animation: circle 1s ease-in forwards
}

.cart-button.clicked span.add-to-cart {
  animation: addcart 2s ease-in forwards
}

.cart-button.clicked span.added {
  animation: added 2s ease-in forwards
}

@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes addcart {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes added {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}
</style>