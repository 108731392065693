<template>
  <div v-if="store" class="navbar-tool dropdown ms-3">
    <router-link class="navbar-tool-icon-box bg-secondary dropdown-toggle" to="/cart"><span class="navbar-tool-label">{{cartLength}}</span><i class="navbar-tool-icon ci-cart"></i></router-link>
    <router-link class="navbar-tool-text" to="/cart"><small>{{ store.cartName }}</small>€ {{carTotalPrice.toFixed(2)}}</router-link>
    <div class="dropdown-menu dropdown-menu-end">
      <div class="widget widget-cart px-3 pt-2 pb-3" style="width: 20rem;">
        <div style="height: 15rem;" data-simplebar data-simplebar-auto-hide="false">
          <div v-for="product in cartProducts" :key="product.id" class="widget-cart-item pb-2 border-bottom">
            <button @click="deleteFromCart(product.id)" class="btn-close text-danger" type="button" aria-label="Remove"><span aria-hidden="true">&times;</span></button>
            <div class="d-flex align-items-center"><router-link class="flex-shrink-0" :to="product.link"><img :src="product.imagelink" width="64" alt="Product"></router-link>
              <div class="ps-2">
                <h6 class="widget-product-title"><router-link :to="product.link">{{product.title}}</router-link></h6>
                <div class="widget-product-meta"><span class="text-accent me-2">€ {{Math.floor(product.price)}}.<small>{{product.price.toFixed(2).slice(-2)}}</small></span><span class="text-muted">x {{product.quantity}}</span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-between align-items-center py-3">
          <div class="fs-sm me-2 py-2"><span class="text-muted">Subtotaal:</span><span class="text-accent fs-base ms-1">€ {{carTotalPriceFirst}}.<small>{{carTotalPrice.toFixed(2).slice(-2)}}</small></span></div><router-link class="btn btn-outline-secondary btn-sm" to="/cart">{{ store.cartName }}<i class="ci-arrow-right ms-1 me-n1"></i></router-link>
        </div><router-link class="btn btn-primary btn-sm d-block w-100" to="/cart"><i class="ci-card me-2 fs-base align-middle"></i>Afrekenen</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CartDropdown",
  computed: {
    cartLength() {
      return this.$store.getters['cart/quantity'];
    },
    cartProducts() {
      return this.$store.getters['cart/products'];
    },
    carTotalPrice() {
      return this.$store.getters['cart/totalPrice']?this.$store.getters['cart/totalPrice']:0;
    },
    carTotalPriceFirst() {
      return Math.floor(this.carTotalPrice);
    },
    carTotalPriceSecond() {
      return (this.carTotalPrice - Math.floor(this.carTotalPrice)) * 100;
    },
    store() {
      return this.$store.getters.store;
    }
  },
  methods: {
    deleteFromCart(prodId) {
      console.log('Delete from Cart (' + prodId + ')');
      this.$store.dispatch('cart/removeFromCart', { productId: prodId });
      console.log('Deleted');
    }
  }
}
</script>

<style scoped>

</style>