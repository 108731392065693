<template>
    <page-form :pageId="pageId"></page-form>
</template>

<script>

import PageForm from './PageForm'

export default {
  name: "AdminPage",
  props: ['pageId'],
  components: {
    PageForm
  }
}
</script>

<style scoped>

</style>