<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Kortingscodes</h1>


      <div>
        <router-link to="/admin/newdiscount" class="btn btn-success rounded">Nieuw kortingsbon</router-link>
      </div>
    </div>
    <div class="card mb-4">
      <header class="card-header">
        <div class="row align-items-center">
          <div class="col-md-3 col-8 me-auto mb-md-0 mb-3"></div>
        </div>
      </header>
      <div class="card-body">
        <table class="table table-hover table-responsive">
          <tbody>

          <tr @click="editDiscount(discount.id)" class="text-center align-middle" v-for="discount in discounts" :key="discount.id">

            <td class="text-start">{{discount.code}} - {{discount.description}}</td>
            <td class="text-start">{{discount.startdate}}</td>
            <td class="text-start">{{discount.enddate}}</td>
            <td class="text-end">
              <button @click.stop="deleteDiscount(discount.id)" type="button" class="btn btn-danger btn-icon">
                <i class="ci-trash"></i>
              </button>

            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>
import dayjs from 'dayjs';
export default {
  name: "AdminDiscounts",
  methods: {
    editDiscount(id) {
      this.$router.push('/admin/discounts/' + id);
    },
    deleteDiscount(id) {
      console.log('Delete product' + id);
      this.$store.dispatch('ords/deleteDiscount',id);
    },
    convertFromStringToDate(responseDate) {
      const date = dayjs(responseDate);
      return date.format('DD-MM-YYYY');
    }
  },
  computed: {
    discounts() {
      return this.$store.getters['ords/discounts'];
    },

  },
  created() {
    this.$store.dispatch('ords/loadDiscounts');
  },
}
</script>

<style scoped>

</style>