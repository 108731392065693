<template>
  <div class="container py-5 mt-md-2 mb-md-4">
    <div class="row">
      <div class="col-lg-3">
        <!-- Related articles sidebar-->

      </div>
      <div class="col-lg-9">
        <div class="alert alert-info d-flex fs-sm mb-4" role="alert">
          <div class="alert-icon"><i class="ci-announcement"></i></div>
          <div>Ons vriendelijke team is er altijd om je te helpen. Kies een onderwerp en stel je vraag</div>
        </div>
        <form class="needs-validation" novalidate @submit.prevent="sendHelpRequest">
          <div class="row gx-4 gy-3">
            <div class="col-sm-6">
              <label class="form-label" for="help-context">Kies een onderwerp <strong class='text-danger'>*</strong></label>
              <select class="form-select" required id="help-context" v-model="form.helpContext">
                <option value>—</option>
                <option value="Producten">Producten</option>
                <option value="Bestellen">Bestellen</option>
                <option value="Betalen">Betalen</option>
                <option value="Bezorgen/ophalen">Bezorgen/ophalen</option>
                <option value="Iets anders">Iets anders</option>
              </select>
              <div class="invalid-feedback">Kies een onderwerp!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="help-subject">Verzoek onderwerp </label>
              <input class="form-control" type="text" id="help-subject" v-model="form.helpSubject">
            </div>
            <div class="col-12">
              <label class="form-label" for="help-message">Bericht <strong class='text-danger'>*</strong></label>
              <textarea class="form-control" rows="6" required id="help-message" v-model="form.helpMessage"></textarea>
              <div class="invalid-feedback">Vertel ons je probleem!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="help-name">Je naam <strong class='text-danger'>*</strong></label>
              <input class="form-control" type="text" required id="help-name" v-model="form.helpName">
              <div class="invalid-feedback">Vul je naam in!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="help-email">Je emailadres <strong class='text-danger'>*</strong></label>
              <input class="form-control" type="email" required id="help-email" v-model="form.helpEmail">
              <div class="invalid-feedback">Vul een geldig email adres in!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="help-url">Eventueel een URL</label>
              <input class="form-control" type="text" id="help-url" v-model="form.helpUrl">
            </div>

            <div class="col-12 pt-2">
              <button class="btn btn-primary me-4" type="submit">Stel je vraag</button>
              <router-link target="_blank" to="/privacy-verklaring">Privacy policy</router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelpFAQRequest",
  data() {
    return {
      form: {
        helpContext: '',
        helpSubject: '',
        helpMessage: '',
        helpName: '',
        helpEmail: '',
        helpUrl: ''
      }
    }
  },
  methods: {
    sendHelpRequest() {
      this.$store.dispatch('sendHelpRequest', this.form);
    }
  }
};
</script>

<style scoped>

</style>