<template>
  <div class="widget widget-categories mb-4 pb-4 border-bottom">
    <h3 class="widget-title">Categorieën</h3>
    <div class="accordion mt-n1" id="shop-categories">
      <div v-for="category in categories" :key="category.id" class="accordion-item">
        <h3 class="accordion-header"><a :class="{collapsed: category.id !== 6}" class="accordion-button" :href="category.href" role="button" data-bs-toggle="collapse" aria-expanded="true" :aria-controls="category.link">{{category.description}}</a></h3>
        <div class="show accordion-collapse collapse" :id="category.link" data-bs-parent="#shop-categories">
          <div class="accordion-body">
            <div class="widget widget-links widget-filter">

              <ul class="widget-list widget-filter-list pt-1" data-simplebar data-simplebar-auto-hide="false">
                <li class="widget-list-item widget-filter-item">
                  <router-link class="widget-list-link d-flex justify-content-between align-items-center" :to="category.link">
                    <span class="widget-filter-item-text">Bekijk alles</span>
                  </router-link>
                </li>
                <li v-for="sub in category.subcategories" :key="sub" class="widget-list-item widget-filter-item">
                  <router-link class="widget-list-link d-flex justify-content-between align-items-center" :to="formatLink(category.description + '/' + sub.description)">
                    <span class="widget-filter-item-text">{{sub.description}}</span>
                  </router-link>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CategoriesFilters",
  methods: {
    formatLink(text) {
      return encodeURI('/verhuur/' + text.replace(/\s+/g, '-').toLowerCase());
    }
  },
  computed: {
    categories() {
      return this.$store.getters['prods/categories'];
    }
  }
}
</script>

<style scoped>

</style>