<template>
  <form action="" class="needs-validation" novalidate>
    <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">Transport</h2>
    <div class="accordion mb-2" id="transport-data">
      <div class="accordion-item">
        <h3 class="accordion-header"><a class="accordion-button" href="#card" data-bs-toggle="collapse"><i class="ci-arrow-right fs-lg me-2 mt-n1 align-middle"></i>Bezorgen</a></h3>
        <div class="accordion-collapse collapse show" id="card" data-bs-parent="#payment-method">
          <div class="accordion-body">


            <form class="shipping-to-form">
              <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">{{store.orderShipText}}</h2>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="radio4" value="us" v-model="cartTransport.shippingMethod" @change="changeShippingMethod">
                <label class="form-check-label" for="radio4">Laten bezorgen</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="radio5" value="self" v-model="cartTransport.shippingMethod" @change="changeShippingMethod">
                <label class="form-check-label" for="radio5">Zelf halen</label>
              </div>
              <div :style="{display:formValidated&&cartTransport.shippingMethod===''?'block':'none'}" class="invalid-feedback">{{store.orderShipText}}</div>

              <div class="mb-3 col-sm-6">
                <label class="form-label" for="checkout-pickup-date">{{ cartTransport.shippingMethod === 'self'?'Ophaaldatum':'Bezorgdatum'}}</label>
                <input id="checkout-pickup-date" class="form-control" type="date" name="number" placeholder="Ophaal datum" v-model="cartTransport.shipDate" required>
              </div>
              <div v-if="cartTransport.shippingMethod === 'us'">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="cartTransport.deliveryAddressSame">
                  <label class="form-check-label" for="customSwitch1">Bezorgadres zelfde als factuuradres</label>
                </div>
                <div v-if="!cartTransport.deliveryAddressSame" class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-zip">Postcode</label>
                      <input class="form-control" type="text" id="checkout-zip" v-model="cartTransport.deliveryZipcode" @blur="addressLookup('delivery')">
                      <div :style="{display:formValidated&&cartTransport.deliveryZipcode===''?'block':'none'}" class="invalid-feedback">Vul een postcode in.</div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-number">Huisnummer</label>
                      <input class="form-control" type="text" id="checkout-number" v-model="cartTransport.deliveryHouseNumber">
                      <div :style="{display:formValidated&&cartTransport.deliveryHouseNumber===''?'block':'none'}" class="invalid-feedback">Vul een huisnummer in.</div>
                    </div>
                  </div>
                </div>
                <div v-if="!cartTransport.deliveryAddressSame"  class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-address-1">Straatnaam</label>
                      <input class="form-control" type="text" id="checkout-address-1" v-model="cartTransport.deliveryStreet">
                      <div :style="{display:formValidated&&cartTransport.deliveryStreet===''?'block':'none'}" class="invalid-feedback">Vul een straatnaam in.</div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-address-2">Woonplaats</label>
                      <input class="form-control" type="text" id="checkout-address-2" v-model="cartTransport.deliveryCity">
                      <div :style="{display:formValidated&&cartTransport.deliveryCity===''?'block':'none'}" class="invalid-feedback">Vul een plaatsnaam in.</div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="accordion-item">
        <h3 class="accordion-header"><a class="accordion-button collapsed" href="#paypal" data-bs-toggle="collapse"><i class="ci-arrow-left me-2 align-middle"></i>Ophalen</a></h3>
        <div class="accordion-collapse collapse show" id="paypal" data-bs-parent="#payment-method">
          <div class="accordion-body fs-sm">
            <form class="shipping-to-form">
              <h2 class="h6 pt-1 pb-3 mb-3 border-bottom">{{store.orderReturnText}}</h2>

              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="radio6" value="us" v-model="cartTransport.returnMethod" @change="changePickupMethod">
                <label class="form-check-label" for="radio6">Laten ophalen</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" id="radio7" value="self" v-model="cartTransport.returnMethod" @change="changePickupMethod">
                <label class="form-check-label" for="radio7">Zelf terugbrengen</label>
              </div>
              <div :style="{display:formValidated&&cartTransport.returnMethod===''?'block':'none'}" class="invalid-feedback">{{store.orderReturnText}}</div>

              <div class="mb-3 col-sm-6">
                <label class="form-label" for="checkout-pickup-date">{{ cartTransport.returnMethod === 'self'?'Terugbreng datum':'Ophaaldatum'}}</label>
                <input id="checkout-pickup-date" class="form-control" type="date" name="number" placeholder="Ophaal datum" v-model="cartTransport.returnDate" required>
              </div>
              <div v-if="cartTransport.returnMethod === 'us'">
                <div class="form-check form-switch">
                  <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="cartTransport.pickupAddressSame">
                  <label class="form-check-label" for="customSwitch1">Ophaaladres zelfde als factuuradres</label>
                </div>
                <div v-if="!cartTransport.pickupAddressSame" class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-zip">Postcode</label>
                      <input class="form-control" type="text" id="checkout-zip" v-model="cartTransport.pickupZipcode" @blur="addressLookup()">
                      <div :style="{display:formValidated&&cartTransport.pickupZipcode===''?'block':'none'}" class="invalid-feedback">Vul een postcode in.</div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-number">Huisnummer</label>
                      <input class="form-control" type="text" id="checkout-number" v-model="cartTransport.pickupHouseNumber">
                      <div :style="{display:formValidated&&cartTransport.pickupHouseNumber===''?'block':'none'}" class="invalid-feedback">Vul een huisnummer in.</div>
                    </div>
                  </div>
                </div>
                <div v-if="!cartTransport.pickupAddressSame"  class="row">
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-address-1">Straatnaam</label>
                      <input class="form-control" type="text" id="checkout-address-1" v-model="cartTransport.pickupStreet">
                      <div :style="{display:formValidated&&cartTransport.pickupStreet===''?'block':'none'}" class="invalid-feedback">Vul een straatnaam in.</div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="mb-3">
                      <label class="form-label" for="checkout-address-2">Woonplaats</label>
                      <input class="form-control" type="text" id="checkout-address-2" v-model="cartTransport.pickupCity">
                      <div :style="{display:formValidated&&cartTransport.pickupCity===''?'block':'none'}" class="invalid-feedback">Vul een plaatsnaam in.</div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "CheckoutFormTransport",
  props: ['formValidated'],
  computed: {
    cartCustomer() {
      return this.$store.getters['cart/customer'];
    },
    cartTransport: {
      get () {
        return this.$store.getters['cart/transport'];
      },
      set (value) {
        this.$store.commit('cart/setTransport', value);
      }
    },
    store() {
      return this.$store.getters.store;
    },
  },
  methods: {
    addressLookup(phase) {
      let zipcode = this.cartTransport.pickupZipcode;
      let houseNumber = this.cartTransport.pickupHousenumber;
      if (phase === 'delivery') {
        zipcode = this.cartTransport.deliveryZipcode;
        houseNumber = this.cartTransport.deliveryHousenumber;
      }
      if (zipcode !== '') {
        fetch('https://geodata.nationaalgeoregister.nl/locatieserver/free?fq=postcode:'+zipcode.replace(/ +/g, ""))
            .then(response => response.json())
            .then(data => {
              let records = data.response.docs.filter(rec => (rec.huisnummer == houseNumber || rec.huis_nlt == houseNumber));
              if (records.length === 0) {
                records = data.response.docs
              }
              if (phase === 'delivery') {
                this.cartTransport.deliveryStreet = records[0].straatnaam
                this.cartTransport.deliveryCity = records[0].woonplaatsnaam
              } else {
                this.cartTransport.pickupStreet = records[0].straatnaam
                this.cartTransport.pickupCity = records[0].woonplaatsnaam
              }
            })
            .catch(err => console.log(err))
      }
    },
    changeShippingMethod() {
      this.$store.dispatch('cart/calculateShippingCosts', this.cartTransport.shippingMethod );
    },
    changePickupMethod() {
      this.$store.dispatch('cart/calculatePickupCosts', this.cartTransport.returnMethod );
    },

  },
  watch: {
    cartTransport(value) {
      console.log(value);
    }
  }
}
</script>

<style scoped>
.accordion-button {
  background-color: #373F50;
  color: #E7E8EA;
}
</style>