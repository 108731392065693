<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">Pagina's</h1>


      <div>
        <router-link to="/admin/newpage" class="btn btn-success rounded">Nieuw pagina</router-link>
      </div>
    </div>
    <div class="card mb-4">
      <header class="card-header">
        <div class="row align-items-center">
          <div class="col-md-3 col-8 me-auto mb-md-0 mb-3"></div>
        </div>
      </header>
      <div class="card-body">
        <table class="table table-hover table-responsive">
          <tbody>

          <tr @click="editPage(page.id)" class="text-center align-middle" v-for="page in pages" :key="page.id">

            <td class="text-start">{{page.code}} - {{page.title}}</td>

            <td class="text-end">
              <button @click.stop="deletePage(page.id)" type="button" class="btn btn-danger btn-icon">
                <i class="ci-trash"></i>
              </button>

            </td>
          </tr>

          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: "AdminPages",
  methods: {
    editPage(id) {
      this.$router.push('/admin/pages/' + id);
    },
    deletePage(id) {
      this.$store.dispatch('deletePage',id);
    }
  },
  computed: {
    pages() {
      return this.$store.getters['pages'];
    },
  },
  created() {
    this.$store.dispatch('loadPages');
  },
}
</script>

<style scoped>

</style>