<template>
  <page-title v-if="step !== 6" title="Kassa"></page-title>
  <div v-if="step !== 6" class="container pb-5 mb-2 mb-md-4">
    <div class="row">
      <section class="col-lg-8">
        <checkout-navigation :step="step"></checkout-navigation>
        <checkout-form :step="step" :formValidated="formValidated"></checkout-form>
        <checkout-order-overview v-if="step === 5" :products="cartProducts" :formValidated="formValidated"></checkout-order-overview>
        <checkout-buttons :prev-step-description="prevStepDescription" :next-step-description="nextStepDescription" :step="step" @previousClick="previousStep" @nextClick="nextStep"></checkout-buttons>
      </section>
     <checkout-side-cart :products="cartProducts" :totalPrice="cartTotalPrice" :totalVat="cartBTW" :productTotalPrice="cartProductTotalPrice" :step="step"></checkout-side-cart>
    </div>
    <!-- Navigation (mobile)-->
    <div class="row d-lg-none">
      <div class="col-lg-8">
        <checkout-buttons :prev-step-description="prevStepDescription" :next-step-description="nextStepDescription" mobile="true" @previousClick="previousStep" @nextClick="nextStep"></checkout-buttons>
      </div>
    </div>
  </div>
  <div v-else class="container pb-5 mb-sm-4">
    <div class="pt-5">
      <div class="card py-3 mt-sm-3">
        <div class="card-body text-center">
          <h2 class="h4 pb-3">Bedankt voor uw {{ cartPayment.requestQuote?'offerte aanvraag':'bestelling' }}</h2>
          <p v-if="cartPayment.requestQuote" class="fs-sm mb-2">We zullen deze binnen 24 uur naar u toesturen.</p>
          <div v-else>
            <p class="fs-sm mb-2">We gaan hiermee aan de slag en zorgen dat de spullen voor u worden klaargezet.</p>
            <p class="fs-sm mb-2">De bestelling is opgeslagen onder ordernummer <span class='fw-medium'>34VB5540K83.</span></p>
            <p class="fs-sm">Binnen niet al te lange tijd ontvangt u van ons een orderbevestiging. <u>Nu kunt u:</u></p><a class="btn btn-secondary mt-3 me-3" href="/verhuur">Terug naar verhuur</a><a class="btn btn-primary mt-3" href="order-tracking.html"><i class="ci-location"></i>&nbsp;Track & Trace</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>


<script>
import PageTitle from "../components/PageTitle"
import CheckoutNavigation from "../components/checkout/CheckoutNavigation";
import CheckoutSideCart from "../components/checkout/CheckoutSideCart";
import CheckoutButtons from "../components/checkout/CheckoutButtons";
import CheckoutForm from "../components/checkout/CheckoutForm";
import CheckoutOrderOverview from "../components/checkout/CheckoutOrderOverview";

export default {
  name: "Checkout",
  components: {PageTitle, CheckoutNavigation, CheckoutSideCart, CheckoutButtons, CheckoutForm, CheckoutOrderOverview},
  props: ['step'],
  data() {
    return {
      formValidated : false,
    }
  },
  methods: {

    validateField(field) {
      return field !== ''
    },
    validateForm() {
      let formIsValid = true;
      this.formValidated = true;
      switch (this.step) {
        case 2: {
          formIsValid = this.isAllowedToNavigate[0];
          break;
        }
        case 3: {
          formIsValid = this.isAllowedToNavigate[1];
          break;
        }
        case 4: {
          formIsValid = this.isAllowedToNavigate[2];
          break;
        }
        case 5: {
          formIsValid = this.isAllowedToNavigate[3];
          break;
        }
        default: {
          formIsValid = true;
        }
      }
      return formIsValid;
    },
    previousStep() {
      const prevRoute = '/' + this.checkoutPages.find(page => page.step === this.step-1).route;
      this.$router.replace(prevRoute);
    },
    nextStep() {
      if (this.step === 5 && this.validateForm()) {
        let requestQuote = this.cartPayment.requestQuote;
        this.$store.dispatch('cart/processOrder');
        this.$router.replace('/order-confirmation?quote=' + requestQuote);
      } else {
        if (this.validateForm()) {
          const nextRoute = this.step !== 5 ? '/' + this.checkoutPages.find(page => page.step === this.step + 1).route : '/checkout-complete';
          this.formValidated = false;
          this.$router.replace(nextRoute);
        }
      }
    },
    clearValidity(field) {
      this[field].isValid = true;
    }
  },
  computed: {
    cartProducts() {
      return this.$store.getters['cart/products'];
    },
    cartTotalPrice() {
      return this.$store.getters['cart/totalPrice'];
    },
    cartProductTotalPrice() {
      return this.$store.getters['cart/productTotalPrice'];
    },
    cartBTW() {
      return this.$store.getters['cart/vat'];
      //return ((this.$store.getters['cart/totalPrice']) + this.$store.getters['cart/transport'].deliveryCosts + this.$store.getters['cart/transport'].pickupCosts) / 121 * 21
    },
    isAllowedToNavigate() {
      return [this.$store.getters['cart/isDetailsValid'], this.$store.getters['cart/isTransportValid'] , this.$store.getters['cart/isPaymentValid'], this.$store.getters['cart/isTermsValid']]
    },
    prevStepDescription() {
      return 'Terug naar ' + this.checkoutPages.find(page => page.step === this.step-1).title;
    },
    store() {
      return this.$store.getters.store;
    },
    checkoutPages() {
      return [
        {step: 1, title: this.store.cartName, route: 'cart', valid: true},
        {step: 2, title: 'Gegevens', route: 'checkout-details', valid: false},
        {step: 3, title: 'Transport', route: 'checkout-transport', valid: false},
        {step: 4, title: 'Betaling', route: 'checkout-payment', valid: false},
        {step: 5, title: 'Bestelling akkoord', route: 'checkout-review', valid: false},
        {step: 6, title: 'Bedankt', route: 'checkout-complete', valid: false}
      ]
    },
    nextStepDescription() {
      switch (this.step) {
        case 5:
          return 'Akkoord';
        case 6:
          return '';
        default:
          return 'Verder met ' + this.checkoutPages.find(page => page.step === this.step+1).title;
      }

    },
    cartCustomer() {
      return this.$store.getters['cart/customer'];
    },
    cartTransport() {
      return this.$store.getters['cart/transport'];
    },
    cartPayment() {
      return this.$store.getters['cart/payment'];
    },
    checkTerms() {
      return this.$store.getters['cart/checkTerms'];
    },
  },
  created() {
    this.formValidated = false;
  },

}
</script>

<style scoped>

</style>