<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <form method="post" id="form-post-product" @submit.prevent="onSubmit">

      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">Nieuw product</h1>
        <div>

          <button :class="{clicked: btnClicked}" class="btn btn-primary save-btn rounded font-sm hover-up" type="submit">
            <span class="save-product"><i class="check fs-sm me-1"></i>Opslaan</span>
            <span class="saved">Opgeslagen</span> <i class="ci-arrow-down-circle"></i>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Algemeen</h5>

          <div class="mb-3">
            <label class="form-label" for="product-title">Product titel<span class="text-danger">*</span></label>
            <input class="form-control" type="text" required id="product-title" v-model="productTitle">

          </div>
          <div class="mb-3">
            <label class="form-label" for="product-category">Categorie<span class="text-danger">*</span></label>
            <select v-model="productCategory" class="form-select" id="product-category" required>
              <option value="">Kies een categorie...</option>
              <option v-for="category in categories" :key="category.id" :value="category.id">{{category.longDescription}}</option>
            </select>
          </div>

          <div class="mb-3 ">
            <label class="form-label">Beschikbaar</label>
            <div class="form-check form-switch align-middle">
              <input type="checkbox" class="form-check-input" id="publishedSwitch" v-model="productPublished">
              <label class="form-check-label" for="publishedSwitch">Artikel is beschikbaar in de shop</label>
            </div>
          </div>

          <div class="mb-3">
            <label class="form-label">Product info<span class="text-danger">*</span></label>

            <editor
                api-key="flrjy63babnnz8dnnflouas7aac5opq3j0lr85ym69yzpij1"
                v-model="productInfo"
                :init="{
                    height: 300,
                    menubar: false,
                  }"

            />
          </div>
          <div class="row">
            <div class="col-lg-4">
              <div class="mb-3">
                <label class="form-label" for="product-price">Prijs {{productSaleItem?'per stuk':'per dag'}}<span class="text-danger">*</span></label>
                <input class="form-control" type="text" required id="product-price" v-model="productPrice">

              </div>
            </div>
            <div class="col-lg-4 ">
              <div class="mb-3 ">
                <label class="form-label" for="product-sales-price">Sales</label>
                <div class="form-check form-switch align-middle">
                  <input type="checkbox" class="form-check-input" id="customSwitch1" v-model="productSale">
                  <label class="form-check-label" for="customSwitch1">Artikel is in de uitverkoop?</label>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="mb-3">
                <label class="form-label" for="product-sales-price">Saleprijs {{productSaleItem?'per stuk':'per dag'}}</label>
                <input class="form-control" type="text" id="product-sales-price" v-model="productSales_price">

              </div>
            </div>
            <div class="col-lg-4 ">
              <div class="mb-3 ">
                <label class="form-label">Verkoopartikel</label>
                <div class="form-check form-switch align-middle">
                  <input type="checkbox" class="form-check-input" id="saleItemSwitch" v-model="productSaleItem">
                  <label class="form-check-label" for="saleItemSwitch">Dit artikel kan gekocht worden</label>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Product tekst</h5>
          <div class="mb-3">
            <editor
                api-key="flrjy63babnnz8dnnflouas7aac5opq3j0lr85ym69yzpij1"
                v-model="productDescription"
                :init="{
                    height: 500,
                    menubar: false,
                  }"
            />
          </div>

        </div>
      </div>
    </form>
  </main>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {

  name: "ProductForm",
  components: {
    'editor': Editor
  },
  data() {
    return {
      btnClicked: false,
      productTitle: '',
      productCategory: '',
      productInfo: '',
      productPrice: '',
      productSale: '',
      productSales_price: '',
      productDescription: '',
      images: [],
      productPublished: '',
      productSaleItem: ''
    }
  },
  computed: {
    categories() {
      let cats = this.$store.getters['prods/flatCategories'];
      return cats.sort((a,b) => a.longDescription > b.longDescription?1:((b.longDescription > a.longDescription) ? -1 : 0));
    }
  },
  methods: {
    validateForm() {
      if (this.productTitle === '') {
        return false;
      }
      if (this.productCategory === '') {
        return false;
      }
      return true;
    },
    onSubmit() {
      if (this.validateForm()) {
        this.$store.dispatch('prods/addProduct', {
          product: {
            title: this.productTitle,
            price: this.productPrice,
            category: this.productCategory,
            rating: 5,
            sale: this.productSale,
            sales_price: this.productSales_price,
            quantity: 1,
            info: this.productInfo,
            description: this.productDescription,
            published: this.productPublished,
            saleItem: this.productSaleItem
          }, images: this.images
        });
        this.btnClicked = true;
        setTimeout(() => {
          this.btnClicked = false;
          this.$router.replace('/admin/products')
        }, 2000);
      }
    },
  },

  created() {
    this.$store.dispatch('prods/loadFlatCategories',{});
    this.productCategory = "";
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}

.save-btn {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden
}

.save-btn:focus {
  outline: none !important
}

.save-btn .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.save-btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.save-btn span.saved {
  opacity: 0
}


.save-btn.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.save-btn.clicked {
  animation: color 1s ease-in forwards
}

.save-btn.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.save-btn.clicked span.save-product {
  animation: saveProduct 1s ease-in forwards
}

.save-btn.clicked span.saved {
  animation: saved 1s ease-in forwards
}


@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes saveProduct {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes saved {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}

.image {
  position: relative;
  width: 100%;
  overflow: hidden
}

.image .overlay {
  position: absolute;
  bottom: 0;
  padding-left: 25px;
  padding-top: 15px;
  width: 100%;
  height: 70%;
  color: white;
  font-size: 20px;
  z-index: 5;
  opacity: 0;
}

.image .overlay::before {
  content: "";
  background: #ffff;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: -150px;
  z-index: -2;
  opacity: 0.5;
  transition: all 0.3s ease-out
}

.image:hover .overlay {
  color: black;
  opacity: 10;
}

.container .image:hover .overlay .amount,
.container .image:hover .overlay .h4,
.container .image:hover .overlay .textmuted,
.container .image:hover .overlay .fa-star,
.container .image:hover .overlay .fa-star-half-alt,
.container .image:hover .overlay .review {
  color: black
}

.image:hover .overlay::before {
  bottom: 0px
}

</style>