import { createApp } from "vue";
import { createRouter, createWebHistory } from 'vue-router';

import store from "./store/index"

import App from "./App.vue";
import VueGtag from "vue-gtag";


import ProductsList from "./pages/ProductsList";
import Home from "./pages/Home"
import ProductDetails from "./pages/ProductDetails"
import Cart from "./pages/Cart";
// import CheckoutDetails from "./pages/CheckoutDetails";
// import CheckoutTransport from "./pages/CheckoutTransport";
import Checkout from "./pages/Checkout";
import AdminDashboard from "./pages/admin/AdminDashboard";
import AdminOrders from "./components/admin/AdminOrders";
import AdminOrder from "./components/admin/AdminOrder";
import AdminProducts from "./components/admin/AdminProducts";
import AdminProduct from "./components/admin/AdminProduct";
import AdminNewProduct from "./components/admin/AdminNewProduct";
import AdminHome from "./components/admin/AdminHome";
import AdminSettings from './components/admin/AdminSettings'
import MyAccount from "./pages/MyAccount";
import ActivateAccount from "./pages/ActivateAccount";
import Signin from "./pages/Signin";
import OrderConfirmation from "./pages/OrderConfirmation"
import AdminDiscounts from './components/admin/AdminDiscounts'
import AdminDiscount from './components/admin/AdminDiscount'
import AdminNewDiscount from "./components/admin/AdminNewDiscount";
import AdminPages from "./components/admin/AdminPages"
import AdminPage from "./components/admin/AdminPage"
import AdminNewPage from "./components/admin/AdminNewPage"
import AdminHomeSettings from "./components/admin/AdminHomeSettings"
import CustomPage from "./components/CustomPage"
import CategoriesList from "./pages/CategoriesList"
import Contact from "./pages/Contact";
import AccountConfirmation from "./pages/AccountConfirmation"
import MyAccountProfile from "./components/myaccount/MyAccountProfile";
import MyAccountOrders from "./components/myaccount/MyAccountOrders";
import HelpFAQ from "./pages/HelpFAQ";
import HelpFAQQuestion from "./pages/HelpFAQQuestion";
import HelpFAQRequest from "./pages/HelpFAQRequest";
import AdminFAQ from "./components/admin/AdminFAQ";
import AdminNewFAQQuestion from "./components/admin/AdminNewFAQQuestion";
import AdminFaqQuestion from "./components/admin/AdminFaqQuestion";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', component: Home, meta: {description: 'Party'} },
        { path: '/signin', component: Signin },
        { path: '/verhuur', component: CategoriesList },
        { path: '/verhuur/:maincategory', component: CategoriesList},
        //{ path: '/verhuur/:maincategory/:prodId(\\d+)', component: ProductDetails},
        { path: '/verhuur/:maincategory/:prodId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})', component: ProductDetails},
        { path: '/verhuur/:maincategory/:category', component: ProductsList},
        { path: '/verhuur/:maincategory/:category/:prodId', component: ProductDetails},
        { path: '/account', component: MyAccount, meta: {requiresAuth: true}, children: [
            { path: '', component: MyAccountProfile},
            { path: 'orders', component: MyAccountOrders},
          ]},
        { path: '/activate/:token', component: ActivateAccount},
        { path: '/cart', component: Cart},
        { path: '/checkout-details', component: Checkout, props: { step: 2 }},
        { path: '/checkout-transport', component: Checkout, props: { step: 3 }},
        { path: '/checkout-payment', component: Checkout, props: { step: 4 }},
        { path: '/checkout-review', component: Checkout, props: { step: 5 }},
        { path: '/checkout-complete', component: Checkout, props: { step: 6 }},
        { path: '/order-confirmation', component: OrderConfirmation},
        { path: '/account-confirmation', component: AccountConfirmation},

        { path: '/admin', component: AdminDashboard, meta: {requiresAdmin: true}, children: [
                { path: '',  component: AdminHome, meta: {description: 'Party'}},
                { path: 'home-settings',  component: AdminHomeSettings},
                { path: 'orders', component: AdminOrders},
                { path: 'orders/:orderId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})', component: AdminOrder, props: true},
                { path: 'products', component: AdminProducts},
                { path: 'products/:prodId', component: AdminProduct, props: true,},
                { path: 'newproduct', component: AdminNewProduct},
                { path: 'settings', component: AdminSettings},
                { path: 'discounts', component: AdminDiscounts},
                { path: 'discounts/:discountId', component: AdminDiscount, props: true},
                { path: 'newdiscount', component: AdminNewDiscount},
                { path: 'pages', component: AdminPages},
                { path: 'pages/:pageId', component: AdminPage, props: true},
                { path: 'newpage', component: AdminNewPage},
                { path: 'faq', component: AdminFAQ},
                { path: 'faq/:code', component: AdminFaqQuestion, props: true},
                { path: 'newfaq', component: AdminNewFAQQuestion},

            ]},
        { path: '/contact', component: Contact},
        { path: '/faq', component: HelpFAQ},
        { path: '/faq/need-help', component: HelpFAQRequest},
        { path: '/faq/:code', component: HelpFAQQuestion, props: true},
        { path: '/:code', component: CustomPage, props: true},

        { path: '/:notFound(.*)', component: CustomPage, props: {code: 'notfound'}}
    ],
    linkActiveClass: 'nav-item.active',
    linkExactActiveClass: 'active',
    scrollBehavior() {
      window.scrollTo(0,0);
    }
})

router.beforeEach((to, _, next) => {
    if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
        next('/signin');
    } else {
        let isAdmin = store.getters.isAdmin;
        if (to.meta.requiresAdmin && isAdmin === false) {
            next('/signin?redirect=' + to.path);
        } else {
            next();
        }
    }
})

const app = createApp(App);

app.use(router);

app.use(store);

console.log(process.env.NODE_ENV)

if (process.env.NODE_ENV === 'production') {
  app.use(VueGtag, {
    config: {
      id: "G-F034VTFQR6",
    },
  }, router);
}



app.mount('#app');