<template>
  <div class="container mt-5 mb-5">
    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="card">

          <div class="invoice p-5">
            <h5>Hoe nu verder?</h5>
            <p v-if="quote">Bedankt voor je offerte aanvraag. We zullen deze zo snel mogelijk versturen.</p>
            <p v-else>Bedankt voor je bestelling. We gaan aan de slag om deze klaar te maken</p>
            <br>

            <p class="font-weight-bold mb-0">Bedankt dat je voor ons hebt gekozen!</p><br> <span>{{store.name}}</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckoutOrderConfirmation",
  computed: {
    store() {
      return this.$store.getters.store;
    },
    quote() {
      return this.$route.query.quote;
    }
  }
}
</script>

<style scoped>

</style>