<template>
<!--  <button :class="{clicked: btnClicked}"  class="btn btn-primary mt-3 mt-sm-0"  @click="click" type="button">{{buttonText}}</button>-->
  <button :class="{clicked: btnClicked}" class="btn btn-primary save-btn rounded font-sm hover-up" @click="click" type="button">
    <span class="save-product"><i class="check fs-sm me-1"></i>Opslaan</span>
    <span class="saved">Opgeslagen</span> <i class="ci-arrow-down-circle"></i>
  </button>
</template>

<script>
export default {
  name: "SaveButton",
  props: ['buttonText', 'canClick'],
  emits: ['click','beforeClick'],
  data() {
    return {
      btnClicked: false
    }
  },
  methods: {
    click() {
      this.$emit('beforeClick')
    }
  },
  watch: {
    canClick(value) {
      if (value) {
        this.btnClicked = true
        this.$emit('click')
        setTimeout(() => {
          this.btnClicked = false
        }, 1000)
      }
    }
  }
};
</script>

<style scoped>

.save-btn {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden
}

.save-btn:focus {
  outline: none !important
}

.save-btn .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.save-btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.save-btn span.saved {
  opacity: 0
}


.save-btn.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.save-btn.clicked {
  animation: color 1s ease-in forwards
}

.save-btn.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.save-btn.clicked span.save-product {
  animation: saveProduct 1s ease-in forwards
}

.save-btn.clicked span.saved {
  animation: saved 1s ease-in forwards
}


@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes saveProduct {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes saved {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}


</style>