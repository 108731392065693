<template>
  <page-title title="Mijn account"></page-title>

  <div class="container pb-5 mb-2 mb-md-4">
    <div class="row">

      <MyAccountSidebar></MyAccountSidebar>
      <!-- Content  -->
      <router-view></router-view>
    </div>
  </div>


</template>

<script>
import PageTitle from "../components/PageTitle"
import MyAccountSidebar from "../components/myaccount/MyAccountSidebar";


export default {
  name: "MyAccount",
  components: {
    PageTitle,
    MyAccountSidebar
  },
  methods: {
    logout() {
      this.$store.dispatch('logout');
      this.$router.replace('/verhuur');
    }
  }
}
</script>

<style scoped>

</style>