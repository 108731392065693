<template>
  <products-list v-if="showProducts"></products-list>
  <div v-else>
    <page-title :title="title"></page-title>
    <div class="container pb-5 mb-2 mb-md-4">
      <div class="row">
        <!-- Onderstaande div is alleen om wat ruimte te maken -->
        <div class="d-flex justify-content-center justify-content-sm-between align-items-center pt-2 pb-4 pb-sm-5"><div class="d-flex flex-wrap"><div class="invisible d-flex align-items-center flex-nowrap me-3 me-sm-4 pb-3"><label class="text-light opacity-75 text-nowrap fs-sm me-2 d-none d-sm-block" for="sorting">Sort by:</label><select class="form-select" id="sorting"><option>Popularity</option><option>Low - Hight Price</option><option>High - Low Price</option><option>Average Rating</option><option>A - Z Order</option><option>Z - A Order</option></select><span class="fs-sm text-light opacity-75 text-nowrap ms-2 d-none d-md-block">of 1 products</span></div></div><div class="invisible d-flex pb-3"><a class="nav-link-style nav-link-light me-3" href="#"><i class="ci-arrow-left"></i></a><span class="fs-md text-light">1 / 5</span><a class="nav-link-style nav-link-light ms-3" href="#"><i class="ci-arrow-right"></i></a></div><div class="invisible d-none d-sm-flex pb-3"><a class="btn btn-icon nav-link-style bg-light text-dark disabled opacity-100 me-2" href="#"><i class="ci-view-grid"></i></a><a class="btn btn-icon nav-link-style nav-link-light" href="shop-list-ls.html"><i class="ci-view-list"></i></a></div></div>
        <component :is="categoryTextBefore" />
        <div v-if="categories && categories.length > 0" class="row g-0 ps-1">
          <div v-for="cat in categories" :key="cat.id" class="col-sm-3 px-2 mb-grid-gutter">
            <router-link class="d-block text-center text-decoration-none me-1" :to="cat.link">
              <img v-if="cat.image !== ''" class="img-thumbnail d-block rounded mb-3" :src="cat.image_link + '?height=240&width=240'" :alt="cat.description">
              <img v-else class="img-thumbnail d-block rounded mb-3" :src="store.noProductImage + '?height=240&width=240'" :alt="cat.description">
              <h3 class="fs-base pt-1 mb-0">{{cat.description}}</h3>
            </router-link>
          </div>
        </div>
        <component :is="categoryTextAfter" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle"
import ProductsList from "../pages/ProductsList"

export default {
  name: "CategoriesList",
  data() {
    return {
      categoriesLoaded : false,
      showProducts: false,
      title: 'Verhuur'
    }
  },
  components: {
    // CategoryCard,
    PageTitle,
    ProductsList
  },
  methods: {


  },
  computed: {
    categories() {
      let categories = [];
      if (this.$route.params.maincategory) {
        categories =  this.$store.getters['prods/flatCategories'].filter(cat => {
          if (cat.parent !== null) {
            return encodeURI(cat.parent.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory;
          } else {
            return false;
          }
        });
        return categories
      } else {
        categories = this.$store.getters['prods/categories']
      }
      return categories;

    },
    categoryTextBefore() {
      let category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory)[0];
      if (this.$route.params.category) {
        category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.category)[0];
      }
      console.log(category);
      //return category.text_before_products;
      return { template: category!==undefined?category.text_before_products:'<div></div>'}
    },
    categoryTextAfter() {
      let category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory)[0];
      if (this.$route.params.category) {
        category = this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.category)[0];
      }

      return { template: category!==undefined?category.text_after_products:'<div></div>'};
    },
    store() {
      return this.$store.getters.store;
    },
  },
  watch: {
    categories(val) {
      if (this.store.showOnOverview === 'products') {
        this.showProducts = true;
      } else {
        if (val.length === 0 && this.categoriesLoaded) {
          this.showProducts = true;
        } else {
          this.showProducts = false;
          let category  =  this.$store.getters['prods/flatCategories'].filter(cat => encodeURI(cat.description.replace(/\s+/g, '-').toLowerCase()) === this.$route.params.maincategory)[0];
          if (category) {
            this.title = category.description;
          } else {
            this.title = 'Verhuur';
          }

        }
      }
    },
    title(val) {
      document.title = this.store.name + ' - Verhuur ' + val;
      const descEl = document.querySelector('head meta[name="description"]');
      const keyEl = document.querySelector('head meta[name="keywords"]');
      descEl.setAttribute('content', val + ' kun je huren bij ' +  this.store.name);
      keyEl.setAttribute('content', 'Verhuur ' + val);
    }
  },
  created() {

    this.$store.dispatch('prods/loadFlatCategories', {});
    this.$store.dispatch('prods/loadCategories', {});

    this.categoriesLoaded = true;

  },

}
</script>

<style scoped>

</style>