<template>
  <div @click="$emit('closeDialog')"></div>

  <dialog open>
    <header>
            <ul class="nav nav-tabs card-header-tabs" role="tablist">
              <li class="nav-item"><a @click="showLogin = true" class="nav-link fw-medium active" data-bs-toggle="tab" role="tab" aria-selected="true"><i class="ci-unlocked me-2 mt-n1"></i>Inloggen</a></li>
              <li class="nav-item"><a @click="showLogin = false" class="nav-link fw-medium" data-bs-toggle="tab" role="tab" aria-selected="false"><i class="ci-user me-2 mt-n1"></i>Registreren</a></li>
              <button class="btn-close" @click="close" type="button" aria-label="Close"></button>
            </ul>
    </header>
    <section v-if="showLogin" class="modal-content">
          <label style="margin-top: 15px;" class="form-label" for="si-email">Emailadres</label>
          <input @focus="error = null" class="form-control" type="email" id="si-email" placeholder="johan@voorbeeld.nl" required="" autocomplete="off" v-model.trim="email">
          <div class="invalid-feedback">Please provide a valid email address.</div>

          <label style="margin-top: 25px;" class="form-label" for="si-password">Wachtwoord</label>
          <input @focus="error = null" class="form-control" type="password" id="si-password" required="" autocomplete="off" v-model.trim="password" @keyup.enter="submitLoginForm">

          <a  style="margin-top: 25px;" class="fs-sm" href="#">Wachtwoord vergeten?</a>

          <p v-if="!formIsValid">Voer een emailadres en wachtwoord in!</p>
          <p style="margin-left: 50px; margin-right:50px; margin-top: 25px; font-style: italic; background-color:#8B0000; color:white; text-align: center" v-if="error">{{error}}</p>

          <button style="margin-top: 25px;"  class="btn btn-primary btn-shadow d-block w-100" @click="submitLoginForm" type="submit">Inloggen</button>

    </section>
    <section v-else>
      <label style="margin-top: 15px;" class="form-label" for="firstname">Voornaam</label>
      <input @focus="error = null" class="form-control" type="text" required="" id="firstname" autocomplete="off" v-model.trim="firstName">
      <div class="invalid-feedback">Vul een voornaam in.</div>

      <label style="margin-top: 15px;" class="form-label" for="lastname">Achternaam</label>
      <input @focus="error = null" class="form-control" type="text" required="" id="lastname" autocomplete="off" v-model.trim="lastName">
      <div class="invalid-feedback">Vul een voornaam in.</div>

      <label style="margin-top: 15px;" class="form-label" for="signupEmail">Emailadres</label>
      <input @focus="error = null" class="form-control" type="email" id="signupEmail" placeholder="johan@voorbeeld.nl" required="" autocomplete="off" v-model.trim="signupEmail">
      <div class="invalid-feedback">Please provide a valid email address.</div>

      <label style="margin-top: 25px;" class="form-label" for="signupPassword">Password</label>
      <input @focus="error = null" class="form-control" type="password" id="signupPassword" required="" autocomplete="off" v-model.trim="signupPassword">

      <input @focus="error = null" class="form-control" type="password" id="signupPasswordConfirm" required="" v-model.trim="signupPasswordConfirm" @keyup.enter="submitSignupForm">
      <p v-if="!formIsValid">Voer een emailadres en 2x hetzelfde wachtwoord in!</p>
      <p style="margin-left: 50px; margin-right:50px; margin-top: 25px; font-style: italic; background-color:#8B0000; color:white; text-align: center" v-if="error">{{error}}</p>
      <button style="margin-top: 25px;"  class="btn btn-primary btn-shadow d-block w-100" @click="submitSignupForm" type="submit">Registreren</button>
    </section>
<!--    <menu>-->
<!--      <slot name="actions"></slot>-->
<!--    </menu>-->
<!--  </div>-->
  </dialog>
</template>

<script>
export default {
  name: "LoginDialog",
  emits: ['closeDialog'],
  data() {
    return {
      showLogin: true,
      email: '',
      password: '',
      formIsValid: true,
      isLoading: false,
      error: null,
      firstName: '',
      lastName: '',
      signupEmail: '',
      signupPassword: '',
      signupPasswordConfirm: ''
    }
  },
  methods: {
    close() {
      this.$emit('closeDialog')
    },
    async submitLoginForm() {
      console.log('submit');
      this.formIsValid = true;
      if (this.email === '' || !this.email.includes('@') || this.password === '') {
        this.formIsValid = false;
        return;
      }

      this.isLoading = true;
      try {
        // Nu naar store met await
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        })
      } catch (err) {
        console.log('error');

        this.error = err.message || 'Failed to authenticate, try later.';
        return
      }

      this.isLoading = false;
      console.log('We zijn ingelogd');
      this.$router.push('/account');
      this.close();
    },
    async submitSignupForm() {
      this.formIsValid = true;
      if (this.signupEmail === '' || !this.signupEmail.includes('@') || this.signupPassword === '' || this.signupPasswordConfirm === '' || this.signupPasswordConfirm !== this.signupPassword) {
        this.formIsValid = false;
        return;
      }
      try {
        // Nu naar store met await
        await this.$store.dispatch('signup', {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.signupEmail,
          password: this.signupPassword
        })
        .then(result => {
          console.log('result', result);
          if (result === 'signedup') {
            this.close();
            this.$router.replace('account-confirmation')
          }
        })
      } catch (err) {
        console.log(err);

        this.error = err.message || 'Failed to register, try later.';
        return
      }
      this.close();
    }
  }
}
</script>

<style scoped>
div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
}

header {
  /*background-color: #3a0061;*/
  background-color: #F6F9FC;
  color: white;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.btn-close {
  padding: 0.5rem 0.625rem;
  margin: 0rem 1rem -0.5rem auto;
}


</style>