<template>
  <div class="container py-4 py-lg-5 my-4">
    <div class="row">
      <div class="col-md-6">
        <div class="card border-0 shadow">
          <div class="card-body">
            <h2 class="h4 mb-1">Inloggen</h2>

            <hr>
            <h3 class="fs-base pt-4 pb-2">Vul de gegevens in</h3>
            <form class="needs-validation" novalidate @submit.prevent="submitLoginForm">
              <div class="input-group mb-3"><i class="ci-mail position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                <input class="form-control rounded-start" type="email" placeholder="Emailadres" required v-model.trim="email">
              </div>
              <div class="input-group mb-3"><i class="ci-locked position-absolute top-50 translate-middle-y text-muted fs-base ms-3"></i>
                <div class="password-toggle w-100">
                  <input class="form-control" type="password" placeholder="Wachtwoord" required v-model.trim="password" @keyup.enter="submitLoginForm">

                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" checked id="remember_me">
                  <label class="form-check-label" for="remember_me">Onthoud me op deze computer</label>
                </div><a class="nav-link-inline fs-sm" href="account-password-recovery.html">Wachtwoord vergeten?</a>
              </div>
              <hr class="mt-4">
              <div class="text-end pt-4">
                <button class="btn btn-primary" type="submit"><i class="ci-sign-in me-2 ms-n21"></i>Inloggen</button>
              </div>
              <p v-if="!formIsValid">Voer een emailadres en het juiste wachtwoord in!</p>
              <p style="margin-left: 50px; margin-right:50px; margin-top: 25px; font-style: italic; background-color:#8B0000; color:white; text-align: center" v-if="error">{{error}}</p>
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6 pt-4 mt-3 mt-md-0">
        <h2 class="h4 mb-3">Nog geen account? Registreer je dan!</h2>
        <p class="fs-sm text-muted mb-4">Registratie duurt niet lang en geeft je volledig inzicht in je bestellingen.</p>
        <form class="needs-validation" novalidate @submit.prevent="submitSignupForm">
          <div class="row gx-4 gy-3">
            <div class="col-sm-6">
              <label class="form-label" for="reg-fn">Voornaam</label>
              <input class="form-control" type="text" required id="reg-fn" v-model.trim="firstName">
              <div class="invalid-feedback">Please enter your first name!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="reg-ln">Achternaam</label>
              <input class="form-control" type="text" required id="reg-ln" v-model.trim="lastName">
              <div class="invalid-feedback">Please enter your last name!</div>
            </div>
            <div class="col-sm-12">
              <label class="form-label" for="reg-email">Emailadres</label>
              <input class="form-control" type="email" required id="reg-email" v-model.trim="signupEmail">
              <div class="invalid-feedback">Please enter valid email address!</div>
            </div>

            <div class="col-sm-6">
              <label class="form-label" for="reg-password">Wachtwoord</label>
              <input class="form-control" type="password" required id="reg-password" v-model.trim="signupPassword">
              <div class="invalid-feedback">Please enter password!</div>
            </div>
            <div class="col-sm-6">
              <label class="form-label" for="reg-password-confirm">Bevestig wachtwoord</label>
              <input class="form-control" type="password" required id="reg-password-confirm" v-model.trim="signupPasswordConfirm" @keyup.enter="submitSignupForm">
              <div class="invalid-feedback">Passwords do not match!</div>
            </div>
            <div class="col-12 text-end">
              <p v-if="!formIsValidSignup">Voer een emailadres en 2x hetzelfde wachtwoord in!</p>
              <p style="margin-left: 50px; margin-right:50px; margin-top: 25px; font-style: italic; background-color:#8B0000; color:white; text-align: center" v-if="errorSignup">{{errorSignup}}</p>
              <button class="btn btn-primary" type="submit"><i class="ci-user me-2 ms-n1"></i>Sign Up</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Signin",
  data() {
    return {
      email: '',
      password: '',
      formIsValid: true,
      formIsValidSignup: true,
      error: null,
      errorSignup: null,
      firstName: '',
      lastName: '',
      signupEmail: '',
      signupPassword: '',
      signupPasswordConfirm: ''
    }
  },
  methods: {
    async submitLoginForm() {
      console.log('submit');
      this.formIsValid = true;
      if (this.email === '' || !this.email.includes('@') || this.password === '') {
        this.formIsValid = false;
        return;
      }
      try {
        // Nu naar store met await
        await this.$store.dispatch('login', {
          email: this.email,
          password: this.password
        })
      } catch (err) {
        console.log('error');
        this.error = err.message || 'Failed to authenticate, try later.';
        return
      }
      console.log('We zijn ingelogd');
      this.$router.replace(this.$route.query.redirect || '/');
    },
    async submitSignupForm() {
      console.log('submit Signup');
      this.formIsValidSignup = true;
      if (this.signupEmail === '' || !this.signupEmail.includes('@') || this.signupPassword === '' || this.signupPasswordConfirm === '' || this.signupPasswordConfirm !== this.signupPassword) {
        this.formIsValidSignup = false;
        return;
      }
      this.isLoading = true;
      try {
        // Nu naar store met await
        await this.$store.dispatch('signup', {
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.signupEmail,
          password: this.signupPassword
        })
      } catch (err) {
        console.log('error');

        this.errorSignup = err.message || 'Failed to register, try later.';
        return
      }
      this.close();
    }
  }
}
</script>

<style scoped>

</style>