<template>
  <main v-if="store" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <form method="post" id="form-settings" @submit.prevent="onSubmit">
      <section class="col-lg-12 pt-lg-4 pb-4 mb-3">
        <div class="pt-2 px-4 ps-lg-0 pe-xl-5">
          <h2 class="h3 py-2 text-center text-sm-start">Settings</h2>
          <!-- Tabs-->
          <ul class="nav nav-tabs nav-justified" role="tablist">
            <li class="nav-item"><a class="nav-link px-0 active" href="#profile" data-bs-toggle="tab" role="tab">
              <div class="d-none d-lg-block"><i class="ci-user opacity-60 me-2"></i>Gegevens</div>
              <div class="d-lg-none text-center"><i class="ci-user opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Gegevens</span></div></a>
            </li>
            <li class="nav-item"><a class="nav-link px-0" href="#notifications" data-bs-toggle="tab" role="tab">
              <div class="d-none d-lg-block"><i class="ci-bell opacity-60 me-2"></i>Onderhoudmodus</div>
              <div class="d-lg-none text-center"><i class="ci-bell opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Onderhoudmodus</span></div></a>
            </li>
            <li class="nav-item"><a class="nav-link px-0" href="#images" data-bs-toggle="tab" role="tab">
              <div class="d-none d-lg-block"><i class="ci-card opacity-60 me-2"></i>Afbeeldingen</div>
              <div class="d-lg-none text-center"><i class="ci-card opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Afbeeldingen</span></div></a>
            </li>
            <li class="nav-item"><a class="nav-link px-0" href="#texts" data-bs-toggle="tab" role="tab">
              <div class="d-none d-lg-block"><i class="ci-card opacity-60 me-2"></i>Teksten</div>
              <div class="d-lg-none text-center"><i class="ci-card opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Teksten</span></div></a>
            </li>
            <li class="nav-item"><a class="nav-link px-0" href="#ordersettings" data-bs-toggle="tab" role="tab">
              <div class="d-none d-lg-block"><i class="ci-card opacity-60 me-2"></i>Order instellingen</div>
              <div class="d-lg-none text-center"><i class="ci-card opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Order instellingen</span></div></a>
            </li>
            <li class="nav-item"><a class="nav-link px-0" href="#settings" data-bs-toggle="tab" role="tab">
              <div class="d-none d-lg-block"><i class="ci-card opacity-60 me-2"></i>Overige instellingen</div>
              <div class="d-lg-none text-center"><i class="ci-card opacity-60 d-block fs-xl mb-2"></i><span class="fs-ms">Overige instellingen</span></div></a>
            </li>
          </ul>
          <!-- Tab content-->
          <div class="tab-content">
            <!-- Gegevens-->
            <div class="tab-pane fade show active" id="profile" role="tabpanel">
              <div class="row gx-4 gy-3">
                <div class="col-sm-6">
                  <label class="form-label" for="product-title">Naam</label>
                  <input class="form-control" type="text" required id="product-title" v-model="store.name">
                </div>

                <div class="col-sm-6">
                  <label class="form-label" for="dashboard-email">Email adres</label>
                  <input class="form-control" type="text" id="dashboard-email" v-model="this.emailaddress">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="dashboard-phone">Telefoonnummer</label>
                  <input class="form-control" type="text" id="dashboard-phone" v-model="this.phone">
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="dashboard-profile-name">Instagram</label>
                  <input class="form-control" type="text" id="dashboard-profile-name" v-model="this.instagram">
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="dashboard-profile-name">Twitter</label>
                  <input class="form-control" type="text" id="dashboard-profile-name" v-model="this.twitter">
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="dashboard-profile-name">Facebook</label>
                  <input class="form-control" type="text" id="dashboard-profile-name" v-model="this.facebook">
                </div>
                <div class="col-sm-6">
                  <label class="form-label" for="dashboard-profile-name">Youtube</label>
                  <input class="form-control" type="text" id="dashboard-profile-name" v-model="this.youtube">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="dashboard-profile-name">Blog link</label>
                  <input class="form-control" type="text" id="dashboard-profile-name" v-model="this.blogLink">
                </div>
              </div>
            </div>
            <!-- Maintenance-->
            <div class="tab-pane fade" id="notifications" role="tabpanel">

              <div id="notifocation-settings">
                <div class="border-bottom p-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="nf-product-sold" v-model="maintenanceMode">
                    <label class="form-check-label" for="nf-product-sold">Onderhoudsmodus</label>
                  </div>
                  <div class="form-text">In deze modus is de shop niet bereikbaar</div>
                </div>

              </div>
              <br>
              <div class="col-sm-12">
                <label class="form-label" for="dashboard-maintenanceLine1">Titel</label>
                <input class="form-control" type="text" id="dashboard-maintenanceLine1" v-model="this.maintenanceLine1">
              </div>
              <div class="col-sm-12">
                <label class="form-label" for="dashboard-maintenanceLine2">Ondertitel</label>
                <input class="form-control" type="text" id="dashboard-maintenanceLine2" v-model="this.maintenanceLine2">
              </div>
              <br>

            </div>
            <!-- Images-->
            <div class="tab-pane fade" id="images" role="tabpanel">
              <label class="form-label">Home</label>
              <div class="bg-secondary rounded-3 p-4 mb-4">
                <div class="d-flex align-items-center"><img class="rounded" :src="store.homeImage" width="300" alt="Logo">
                  <div class="ps-3">
                    <button @click="$refs.file_home.click()" class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Wijzig <span class='d-none d-sm-inline'>foto</span></button>
                    <input type="file" @change="addImage( $event, 'home' )" accept="image/*" ref="file_home" style="display: none">
                  </div>
                </div>
              </div>
              <label class="form-label">Logo</label>
              <div class="bg-secondary rounded-3 p-4 mb-4">
                <div class="d-flex align-items-center"><img class="rounded" :src="store.logo" width="300" alt="Logo">
                  <div class="ps-3">
                    <button @click="$refs.file_logo.click()" class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Wijzig <span class='d-none d-sm-inline'>logo</span></button>
                    <input type="file" @change="addImage( $event, 'logo' )" accept="image/*" ref="file_logo" style="display: none">
                  </div>
                </div>
              </div>
              <label class="form-label">Klein logo (mobile)</label>
              <div class="bg-secondary rounded-3 p-4 mb-4">
                <div class="d-flex align-items-center"><img class="rounded" :src="store.icon" width="300" alt="Logo">
                  <div class="ps-3">
                    <button @click="$refs.file_icon.click()" class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Wijzig <span class='d-none d-sm-inline'>logo</span></button>
                    <input type="file" @change="addImage( $event, 'icon' )" accept="image/*" ref="file_icon" style="display: none">
                  </div>
                </div>
              </div>
              <label class="form-label">Onderhoudsscherm</label>
              <div class="bg-secondary rounded-3 p-4 mb-4">
                <div class="d-flex align-items-center"><img class="rounded" :src="store.maintenanceImage" width="300" alt="Onderhoud">
                  <div class="ps-3">
                    <button @click="$refs.file_maintenance.click()" class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Wijzig <span class='d-none d-sm-inline'>achtergrond</span></button>
                    <input type="file" @change="addImage( $event, 'maintenance' )" accept="image/*" ref="file_maintenance" style="display: none">
                  </div>
                </div>
              </div>
              <label class="form-label">Nog geen productfoto</label>
              <div class="bg-secondary rounded-3 p-4 mb-4">
                <div class="d-flex align-items-center">
                  <img v-if="store.noProductImage !== null" class="rounded" :src="store.noProductImage" width="300" alt="Onderhoud">
                  <div class="ps-3">
                    <button @click="$refs.file_maintenance.click()" class="btn btn-light btn-shadow btn-sm mb-2" type="button"><i class="ci-loading me-2"></i>Wijzig <span class='d-none d-sm-inline'>foto</span></button>
                    <input type="file" @change="addImage( $event, 'noproductimage' )" accept="image/*" ref="file_maintenance" style="display: none">
                  </div>
                </div>
              </div>

            </div>
            <div class="tab-pane fade" id="texts" role="tabpanel">
              <div class="row gx-4 gy-3">
                <div class="col-sm-12">
                  <label class="form-label" for="home-headline1">Homepage headline 1</label>
                  <input class="form-control" type="text" required id="home-headline1" v-model="homeHeadline1">
                </div>

                <div class="col-sm-12">
                  <label class="form-label" for="home-headline2">Homepage headline 2</label>
                  <input class="form-control" type="text" required id="home-headline2" v-model="homeHeadline2">
                </div>

                <div class="col-sm-12">
                  <label class="form-label" for="home-subline">Homepage subline</label>
                  <input class="form-control" type="text" id="home-subline" v-model="homeSubline">
                </div>
                <br>
                <div class="col-sm-12">
                  <label class="form-label" for="home-subline">Meta tag description</label>
                  <input class="form-control" type="text" id="home-subline" v-model="metaDescription">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="home-subline">Meta tag keywords</label>
                  <input class="form-control" type="text" id="home-subline" v-model="metaKeywords">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="productpage-extratext">Product pagina - tekst onder de prijs</label>
                  <input class="form-control" type="text" id="productpage-extratext" v-model="productPageExtraText">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="notification-text">Tekst voor in de notificatiebalk</label>
                  <input class="form-control" type="text" id="notification-text" v-model="notificationText">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="order-ship-text">Vraag hoe artikelen worden afgeleverd</label>
                  <input class="form-control" type="text" id="order-ship-text" v-model="orderShipText">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="order-return-text">Vraag hoe artikelen worden teruggebracht</label>
                  <input class="form-control" type="text" id="order-return-text" v-model="orderReturnText">
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="ordersettings" role="tabpanel">
              <div id="order-settings">
                <div class="border-bottom p-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="nf-product-sold" v-model="cartQuote">
                    <label class="form-check-label" for="nf-product-sold">Offerte</label>
                  </div>
                  <div class="form-text">Gebruiker kan offerte aanvragen</div>
                </div>
                <div class="border-bottom p-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="nf-product-sold" v-model="cartPayments">
                    <label class="form-check-label" for="nf-product-sold">Betaling</label>
                  </div>
                  <div class="form-text">Gebruiker kan bestelling betalen</div>
                </div>
                <div class="border-bottom p-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="use-show-days" v-model="cartUseShowdays">
                    <label class="form-check-label" for="use-show-days">Gebruik showdagen</label>
                  </div>
                  <div class="form-text">Gebruiker kan aantal showdagen opgeven (wordt gebruikt om prijs te bepalen)</div>
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="order-cartname">Emailadres orders</label>
                  <input class="form-control" type="text" id="order-cartname" v-model="orderEmail">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="order-cartname">Naam winkelwagen</label>
                  <input class="form-control" type="text" id="order-cartname" v-model="cartName">
                </div>
                <div class="col-sm-12">
                  <label class="form-label" for="order-cartname">Tekst bij geen producten in winkelwagen</label>
                  <input class="form-control" type="text" id="order-cartname" v-model="noProductsInCart">
                </div>
              </div>
              <div class="row gx-4 gy-3">

              </div>
            </div>
            <div class="tab-pane fade" id="settings" role="tabpanel">
              <div id="settings">
                <div class="border-bottom p-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="nf-product-sold" v-model="sideMenu">
                    <label class="form-check-label" for="nf-product-sold">Menu zijkant</label>
                  </div>
                  <div class="form-text">Categorien als menu aan de zijkant</div>
                </div>
                <div class="border-bottom p-4">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" id="nf-product-sold" v-model="topMenu">
                    <label class="form-check-label" for="nf-product-sold">Menu bovenin</label>
                  </div>
                  <div class="form-text">Categorien als menu in hoofdment</div>
                </div>
                <div class="border-bottom p-4">
                  <label class="form-label" for="show-in-overview">Wat wordt er getoond in /verhuur (overview pagina)?</label>

                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="radio-products" value="products" v-model="showOnOverview" name="radio">
                    <label class="form-check-label" for="radio-products">Producten</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="radio-categories" value="categories" v-model="showOnOverview" name="radio">
                    <label class="form-check-label" for="radio-categories">Categorien</label>
                  </div>


                </div>

              </div>
              <div class="row gx-4 gy-3">

              </div>
            </div>
          </div>

          <div class="col-12">
            <hr class="mt-2 mb-4">
            <div class="d-sm-flex justify-content-between align-items-center">
              <button :class="{clicked: btnClicked}" class="btn btn-primary save-btn rounded font-sm hover-up" type="submit">
                <span class="save-product"><i class="check fs-sm me-1"></i>Opslaan</span>
                <span class="saved">Opgeslagen</span> <i class="ci-arrow-down-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </section>
    </form>
  </main>
</template>

<script>
export default {
  name: "AdminSettings",
  data() {
    return {
      btnClicked: false,
      enteredName: null,
      enteredFromEmail: null,
      enteredInstagram: null,
      enteredTwitter: null,
      enteredFacebook: null,
      enteredYoutube: null,
      enteredMaintenanceMode: null,
      enteredMaintenanceLine1: null,
      enteredMaintenanceLine2: null,
      enteredHomeHeadline1: null,
      enteredHomeHeadline2: null,
      enteredSubline: null,
      enteredcartQuote: null,
      enteredcartPayments: null,
      enteredorderEmail: null,
      enteredcartName: null,
      enterednoProductsInCart: null,
      enteredblogLink: null,
      enteredmetaDescription: null,
      enteredmetaKeywords: null,
      enteredcartUseShowdays: null,
      enteredPhone: null,
      enteredsideMenu: null,
      enteredtopMenu: null,
      enteredProductPageExtraText: null,
      enteredNotificationText: null,
      enteredOrderShipText: null,
      enteredOrderReturnText: null,
      enteredShowOnOverview: null,
    }
  },
  methods: {
    onSubmit() {
      console.log('submit');
      this.btnClicked = true;
      this.$store.dispatch('updateStore', {
          id: this.store.id,
          name: this.enteredName,
          phone: this.enteredPhone,
          fromEmail: this.fromEmail,
          instagram: this.enteredInstagram,
          twitter: this.enteredTwitter,
          facebook: this.enteredFacebook,
          youtube: this.enteredYoutube,
          maintenanceMode: this.enteredMaintenanceMode,
          maintenanceLine1: this.enteredMaintenanceLine1,
          maintenanceLine2: this.enteredMaintenanceLine2,
          homeHeadline1: this.enteredHomeHeadline1,
          homeHeadline2: this.enteredHomeHeadline2,
          homeSubline: this.enteredSubline,
          cartQuote: this.enteredcartQuote,
          cartPayments:  this.enteredcartPayments,
          orderEmail: this.enteredorderEmail,
          cartName: this.enteredcartName,
          noProductsInCart: this.enterednoProductsInCart,
          blogLink: this.enteredblogLink,
          metaDescription: this.enteredmetaDescription,
          metaKeywords: this.enteredmetaKeywords,
          cartUseShowdays: this.enteredcartUseShowdays,
          sideMenu: this.enteredsideMenu,
          topMenu: this.enteredtopMenu,
          productPageExtraText: this.enteredProductPageExtraText,
          notificationText: this.enteredNotificationText,
          orderShipText: this.enteredOrderShipText,
          orderReturnText: this.enteredOrderReturnText,
          showOnOverview: this.enteredShowOnOverview

        });

      setTimeout(() => {
        this.btnClicked = false;
      }, 2000);
    },
    addImage(e, field) {
      this.$store.dispatch('addMaintenanceImage', {storeId: this.store.id, filename: e.target.files[0], field: field});
    }
  },
  computed: {
    store() {
      return this.$store.getters.store;
    },
    name: {
      get() {
        return this.store.name;
      },
      set (value) {
        this.enteredName = value;
      }
    },
    phone: {
      get() {
        return this.store.phone;
      },
      set (value) {
        this.enteredPhone = value;
      }
    },
    emailaddress: {
      get() {
        return this.store.fromEmail;
      },
      set (value) {
        this.enteredFromEmail = value;
      }
    },
    instagram: {
      get() {
        return this.store.instagram;
      },
      set (value) {
        this.enteredInstagram = value;
      }
    },
    twitter: {
      get() {
        return this.store.twitter;
      },
      set (value) {
        this.enteredTwitter = value;
      }
    },
    facebook: {
      get() {
        return this.store.facebook;
      },
      set (value) {
        this.enteredFacebook = value;
      }
    },
    youtube: {
      get() {
        return this.store.youtube;
      },
      set (value) {
        this.enteredYoutube = value;
      }
    },
    maintenanceMode: {
      get() {
        return this.$store.getters.isMaintenanceMode
      },
      set (value) {
        this.enteredMaintenanceMode = value;
      }
    },
    maintenanceLine1: {
      get() { return this.store.maintenanceLine1;},
      set(value) { this.enteredMaintenanceLine1 = value;}
    },
    maintenanceLine2: {
      get() { return this.store.maintenanceLine2;},
      set(value) { this.enteredMaintenanceLine2 = value;}
    },
    homeHeadline1: {
      get() { return this.store.homeHeadline1;},
      set(value) { this.enteredHomeHeadline1 = value;}
    },
    homeHeadline2: {
      get() { return this.store.homeHeadline2;},
      set(value) { this.enteredHomeHeadline2 = value;}
    },
    homeSubline: {
      get() { return this.store.homeSubline;},
      set(value) { this.enteredHomeSubline = value;}
    },
    cartQuote: {
      get() {
        return this.store.cartQuote;
      },
      set(value) {
        this.enteredcartQuote = value;
      }
    },
    cartPayments: {
      get() {
        return this.store.cartPayments;
      },
      set(value) {
        this.enteredcartPayments = value;
      }
    },
    orderEmail: {
      get() {
        return this.store.orderEmail;
      },
      set(value) {
        this.enteredorderEmail = value;
      }
    },
    cartName: {
      get() {
        return this.store.cartName;
      },
      set(value) {
        this.enteredcartName = value;
      }
    },
    noProductsInCart: {
      get() {
        return this.store.noProductsInCart;
      },
      set(value) {
        this.enterednoProductsInCart = value;
      }
    },
    blogLink: {
      get() {
        return this.store.blogLink;
      },
      set(value) {
        this.enteredblogLink = value;
      }
    },
    metaDescription: {
      get() {
        return this.store.metaDescription;
      },
      set(value) {
        this.enteredmetaDescription = value;
      }
    },
    metaKeywords: {
      get() {
        return this.store.metaKeywords;
      },
      set(value) {
        this.enteredmetaKeywords = value;
      }
    },
    cartUseShowdays: {
      get() {
        return this.store.cartUseShowdays;
      },
      set(value) {
        this.enteredcartUseShowdays = value;
      }
    },
    sideMenu: {
      get() {
        return this.store.sideMenu;
      },
      set(value) {
        this.enteredsideMenu = value;
      }
    },
    topMenu: {
      get() {
        return this.store.topMenu;
      },
      set(value) {
        this.enteredtopMenu = value;
      }
    },
    productPageExtraText : {
      get() {
        return this.store.productPageExtraText;
      },
      set(value) {
        this.enteredProductPageExtraText = value
      }
    },
    notificationText : {
      get() {
        return this.store.notificationText;
      },
      set(value) {
        this.enteredNotificationText = value;
      }
    },
    orderShipText : {
      get() {
        return this.store.orderShipText;
      },
      set(value) {
        this.enteredOrderShipText = value;
      }
    },
    orderReturnText : {
      get() {
        return this.store.orderReturnText;
      },
      set(value) {
        this.enteredOrderReturnText = value;
      }
    },
    showOnOverview : {
      get() {
        return this.store.showOnOverview;
      },
      set(value) {
        this.enteredShowOnOverview = value;
      }
    }
  }
}
</script>

<style scoped>
.save-btn {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden
}

.save-btn:focus {
  outline: none !important
}

.save-btn .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.save-btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.save-btn span.saved {
  opacity: 0
}


.save-btn.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.save-btn.clicked {
  animation: color 1s ease-in forwards
}

.save-btn.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.save-btn.clicked span.save-product {
  animation: saveProduct 1s ease-in forwards
}

.save-btn.clicked span.saved {
  animation: saved 1s ease-in forwards
}


@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes saveProduct {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes saved {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}
</style>