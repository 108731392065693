<template>

  <page-title title="Account activeren"></page-title>
  <div class="container pb-5 mb-2 mb-md-4">
    <div class="row">
      <section class="col-lg-8">
        <div class="d-flex justify-content-between align-items-center pt-3 pb-4 pb-sm-5 mt-1">
          <h2 class="h6 text-light mb-0"></h2><router-link class="btn btn-outline-primary btn-sm ps-2" to="/verhuur"></router-link>
        </div>

        <div class="d-sm-flex justify-content-between align-items-center my-2 pb-3 border-bottom">
          <div class="d-block d-sm-flex align-items-center text-center text-sm-start">
            <div class="pt-2">
              <h3 v-if="activated" class="product-title fs-base mb-2">Het account is geactiveerd en u kunt nu inloggen</h3>
              <h3 v-if="error" class="product-title fs-base mb-2">{{error}}</h3>
            </div>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
export default {
  name: "ActivateAccount",
  data() {
    return {
      activated: false,
      error: null
    }
  },
  components: {
    PageTitle
  },
  mounted() {
    this.$store.dispatch("activate", this.$route.params.token)
      .then(() => {
        this.activated = true;
      })
      .catch(() => {
        this.error = 'Activate token is niet geldig'
      })

  }
}
</script>

<style scoped>

</style>