<template>
  <div class="border-top border-bottom my-lg-3 py-5">
    <div class="container pt-md-2" id="reviews">
      <div v-if="productRating" class="row pb-3">
        <div  class="col-lg-4 col-md-5">
          <h2 class="h3 mb-4">{{countReviews}} beoordelingen</h2>

          <div class="star-rating me-2">
            <i v-for="star in Math.floor(productRating)" :key="star" class="ci-star-filled fs-sm text-accent me-1"></i>
            <i v-for="star in Math.ceil(5 - productRating)" :key="star" class="ci-star fs-sm text-muted me-1"></i>
          </div>
          <span v-if="productRating" class="d-inline-block align-middle">{{productRating.toFixed(1)}} gemiddelde beoordeling</span>

        </div>
        <div class="col-lg-8 col-md-7">
          <div v-for="countRating,index in countRatings.slice().reverse()" :key="index" class="d-flex align-items-center mb-2">
            <div class="text-nowrap me-3"><span class="d-inline-block align-middle text-muted">{{5-index}}</span><i class="ci-star-filled fs-xs ms-1"></i></div>
            <div class="w-100">
              <div class="progress" style="height: 4px;">
                <div class="progress-bar bg-success" role="progressbar"  :style="{'width': countRating/countReviews*100 + '%'}" :aria-valuenow="countRating" aria-valuemin="0" :aria-valuemax="countReviews"></div>
              </div>
            </div><span class="text-muted ms-3">{{countRating}}</span>
          </div>

        </div>
      </div>
      <hr v-if="productRating" class="mt-4 mb-3">
      <div class="row pt-4">
        <!-- Reviews list-->
        <div v-if="productRating" class="col-md-7">

          <!-- Reviews-->
          <div v-for="review in productReviews" :key="review.id" class="product-review pb-4 mb-4 border-bottom">
            <div class="d-flex mb-3">
              <div class="d-flex align-items-center me-4 pe-2"><img class="rounded-circle" src="/img/user.png" width="50" :alt="review.name">
                <div class="ps-3">
                  <h6 class="fs-sm mb-0">{{ review.name }}</h6><span class="fs-ms text-muted">{{review.formattedDate}}</span>
                </div>
              </div>
              <div>
                <product-rating :rating="review.rating"></product-rating>
              </div>
            </div>
            <p class="fs-md mb-2">{{review.review}}</p>
            <ul class="list-unstyled fs-ms pt-1">
              <li class="mb-1"><span class="fw-medium">Pros:&nbsp;</span>{{review.pros}}</li>
              <li class="mb-1"><span class="fw-medium">Cons:&nbsp;</span>{{review.cons}}</li>
            </ul>

          </div>
        </div>
        <!-- Leave review form-->
        <div class="col-md-5 mt-2 pt-4 mt-md-0 pt-md-0">
          <div class="bg-secondary py-grid-gutter px-grid-gutter rounded-3">
            <h3 class="h4 pb-2">Schrijf een beoordeling</h3>
            <form class="needs-validation" method="post" id="form-post-review" novalidate @submit.prevent="submitForm">
              <div class="mb-3">
                <label class="form-label" for="review-name">Naam<span class="text-danger">*</span></label>
                <input class="form-control" type="text" required id="review-name" v-model="userName">
                <div class="invalid-feedback">Voer je naam in!</div><small class="form-text text-muted">Wordt getoond in de beoordeling.</small>
              </div>
              <div class="mb-3">
                <label class="form-label" for="review-email">Email adres<span class="text-danger">*</span></label>
                <input class="form-control" type="email" required id="review-email" v-model="emailAddress">
                <div class="invalid-feedback">Voer een geldig emailadres in!</div><small class="form-text text-muted">Wordt alleen gebruikt voor authenticatie.</small>
              </div>
              <div class="mb-3">
                <label class="form-label" for="review-rating">Aantal sterren<span class="text-danger">*</span></label>
                <select class="form-select" required id="review-rating" v-model="rating">
                  <option value="">Geef aantal sterren</option>
                  <option value="5">5 sterren</option>
                  <option value="4">4 sterren</option>
                  <option value="3">3 sterren</option>
                  <option value="2">2 sterren</option>
                  <option value="1">1 ster</option>
                </select>
                <div class="invalid-feedback">Wil je een aantal sterren kiezen?</div>
              </div>
              <div class="mb-3">
                <label class="form-label" for="review-text">Beoordeling<span class="text-danger">*</span></label>
                <textarea class="form-control" rows="6" required id="review-text" v-model="reviewText"></textarea>
                <div class="invalid-feedback">Vul een beoordeling in!</div><small class="form-text text-muted">Je boordeling moet minimaal 50 tekens hebben</small>
              </div>
              <div class="mb-3">
                <label class="form-label" for="review-pros">Voordelen</label>
                <textarea class="form-control" rows="2" placeholder="Gescheiden door een komma" id="review-pros" v-model="reviewPros"></textarea>
              </div>
              <div class="mb-3 mb-4">
                <label class="form-label" for="review-cons">Nadelen</label>
                <textarea class="form-control" rows="2" placeholder="Gescheiden door een komma" id="review-cons" v-model="reviewCons"></textarea>
              </div>
              <button class="btn btn-primary btn-shadow d-block w-100" type="submit">Versturen</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import ProductRating from "./ProductRating";

export default {
  name: "ProductReviews",
  props: ['product', 'productRating'],
  emits: ['reviewAdded'],
  components: {ProductRating},
  data() {
    return {
      reviews: [],
      userName: '',
      emailAddress: '',
      rating: '',
      reviewText: '',
      reviewPros: '',
      reviewCons: ''
    }
  },
  methods: {
    loadReviews() {
      fetch(process.env.VUE_APP_API_ENDPOINT + "/products/" + this.product.id + '/reviews')
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((data) => {
            this.reviews = data;
          })
    },
    submitForm() {
      if ((this.userName !== '') && (this.emailAddress != '') && (this.rating != '') && (this.reviewText != '')) {
        fetch(process.env.VUE_APP_API_ENDPOINT + "/products/" + this.product.id + '/reviews', {
          method: 'POST',
          headers: {
            'Content-Type' : 'application/json'
          },
          body: JSON.stringify({
            name: this.userName,
            emailAddress: this.emailAddress,
            review: this.reviewText,
            pros: this.pros,
            cons: this.cons,
            rating: this.rating
          })
        })
        .then(() => {
          this.$emit('reviewAdded');

          this.userName = '';
          this.emailAddress = '';
          this.reviewText = '';
          this.rating = '';
          this.reviewPros = '';
          this.reviewCons = '';
          let form = document.getElementById("form-post-review");
          form.classList.remove("was-validated");

        })

      }

    }
  },
  computed: {
    countReviews() {
      return this.productReviews!=undefined?this.productReviews.length:0
    },
    countRatings() {
      return [
        this.productReviews!=undefined?this.productReviews.filter(review => review.rating === 1).length:0,
        this.productReviews!=undefined?this.productReviews.filter(review => review.rating === 2).length:0,
        this.productReviews!=undefined?this.productReviews.filter(review => review.rating === 3).length:0,
        this.productReviews!=undefined?this.productReviews.filter(review => review.rating === 4).length:0,
        this.productReviews!=undefined?this.productReviews.filter(review => review.rating === 5).length:0
      ]
    },

    productReviews() {
      const reviews = this.product.reviews;
      return reviews!=undefined?reviews.slice().reverse():[];
    }
  },
  created() {
    this.loadReviews();
  },
}
</script>

<style scoped>

</style>