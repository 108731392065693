<template>
  <section class="col-lg-8">
    <!-- Toolbar-->

    <div class="d-flex justify-content-between align-items-center pt-lg-2 pb-4 pb-lg-5 mb-lg-3">
      <div class="d-flex align-items-center">

      </div><a @click="logout" class="btn btn-primary btn-sm"><i class="ci-sign-out me-2"></i>Uitloggen</a>
    </div>
    <!-- Profile form-->
    <div class="table-responsive fs-md mb-4">
      <table class="table table-hover mb-0">
        <thead>
        <tr>
          <th>Order #</th>
          <th>Datum</th>
          <th>Status</th>
          <th>Totaal</th>
        </tr>
        </thead>
        <tbody>


        </tbody>
      </table>
    </div>

  </section>
</template>

<script>
export default {
  name: "MyAccountOrders",
  computed: {
    user() {
      return this.$store.getters.user;
    }
  }
}
</script>

<style scoped>

</style>