<template>
  <main v-if="discount" class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <form method="post" id="form-post-product" @submit.prevent="onSubmit">

      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">{{discountId === null?'Nieuwe kortingscode':discount.code}}</h1>
        <div>

          <button :class="{clicked: btnClicked}" class="btn btn-primary save-btn rounded font-sm hover-up" type="submit">
            <span class="save-discount"><i class="check fs-sm me-1"></i>Opslaan</span>
            <span class="saved">Opgeslagen</span> <i class="ci-arrow-down-circle"></i>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="mb-3">
            <label class="form-label" for="discount-code">Code<span class="text-danger">*</span></label>
            <input class="form-control" type="text" required id="discount-code" v-model="discount.code">

          </div>
          <div class="mb-3">
            <label class="form-label" for="discount-description">Omschrijving<span class="text-danger">*</span></label>
            <input class="form-control" type="text" required id="discount-description" v-model="discount.description">
          </div>

          <div class="row">
            <div class="col-md-2 mb-2">
              <label class="form-label" for="discount-value">Waarde<span class="text-danger">*</span></label>
              <input class="form-control" type="text" required id="discount-value" v-model="discount.value">
            </div>
            <div class="col-md-2 mb-2">
              <label class="form-label" for="discount-method">Methode</label>
              <select class="form-select" id="discount-method" required v-model="discount.method">
                <option value="PERC">%</option>
                <option value="AMOUNT">EUR</option>
              </select>
            </div>
          </div>

          <div class="mb-3 ">
            <label class="form-label">Verhuur artikelen</label>
            <div class="form-check form-switch align-middle">
              <input type="checkbox" class="form-check-input" id="rentalSwitch" v-model="discount.rental">
              <label class="form-check-label" for="rentalSwitch">Korting is geldig op artikelen</label>
            </div>
          </div>

<!--          <div class="mb-3 ">-->
<!--            <label class="form-label">Verkoop artikelen</label>-->
<!--            <div class="form-check form-switch align-middle">-->
<!--              <input type="checkbox" class="form-check-input" id="saleSwitch" v-model="discount.saleItems">-->
<!--              <label class="form-check-label" for="saleSwitch">Korting is geldig op verkoop artikelen</label>-->
<!--            </div>-->
<!--          </div>-->
          <div class="mb-3 ">
            <label class="form-label">Verzendkosten</label>
            <div class="form-check form-switch align-middle">
              <input type="checkbox" class="form-check-input" id="shippingSwitch" v-model="discount.shippingCosts">
              <label class="form-check-label" for="shippingSwitch">Korting is geldig op verzendkosten</label>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 mb-3">
              <label class="form-label">Start datum</label>
              <div class="input-group">
                <flat-pickr class="form-control"  v-model="discount.startDate" :config="config"></flat-pickr>
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <label class="form-label">Eind datum</label>
              <flat-pickr class="form-control"  v-model="discount.endDate" :config="config"></flat-pickr>
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <label class="form-label" for="discount-minimum">Minimaal orderbedrag</label>
            <input class="form-control" type="text" required id="discount-minimum" v-model="discount.minimumOrderAmount">

          </div>


        </div>
      </div>

    </form>
  </main>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Dutch } from "flatpickr/dist/l10n/nl.js"
import dayjs from 'dayjs';

export default {

  name: "ProductForm",
  components: {
    flatPickr
  },
  props: ['discountId'],
  data() {
    return {
      btnClicked: false,
      config: {
        dateFormat: 'd-m-Y',
        locale: Dutch,
        //defaultDate: "today"
      }
    }
  },
  methods: {
    validateForm() {

      return true;
    },
    onSubmit() {
      if (this.validateForm()) {
        if (this.discountId) {
          console.log('update');
          console.log(this.discount)
          this.$store.dispatch('ords/updateDiscount', this.discount);
        } else {
          console.log('add');
          console.log(this.discount)
          this.$store.dispatch('ords/addDiscount', this.discount);
        }

        this.btnClicked = true;
        setTimeout(() => {
          this.btnClicked = false;
          this.$router.replace('/admin/discounts')
        }, 10);
      }
    },
  },
  computed: {
    discount() {
      if (this.discountId) {
        return this.$store.getters['ords/discount'];
      } else {
        return {
          code: '',
          description: '',
          rental: false,
          saleItems: false,
          shippingCosts: false,
          method: 'PERC',
          value: 0,
          startDate: dayjs().format('DD-MM-YYYY'),
          endDate: dayjs().format('DD-MM-YYYY'),
          minimumOrderAmount: 0
        }
      }
    }
  },
  created() {
    if (this.discountId) {
      this.$store.dispatch('ords/loadDiscount', this.discountId);
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 20px;
}

.save-btn {
  position: relative;
  outline: 0;
  color: #fff;
  border: none;
  height: 48px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  line-height: 0;
  overflow: hidden
}

.save-btn:focus {
  outline: none !important
}

.save-btn .ci-arrow-down-circle {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: -20%;
  font-size: 1.5em;
  transform: translate(-50%, -50%)
}

.save-btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  font-size: 16px
}

.save-btn span.saved {
  opacity: 0
}


.save-btn.clicked .fa-plus {
  animation: addicon 1s ease-in forwards
}

.save-btn.clicked {
  animation: color 1s ease-in forwards
}

.save-btn.clicked .ci-arrow-down-circle {
  animation: circle 0.5s ease-in forwards
}

.save-btn.clicked span.save-discount {
  animation: saveProduct 1s ease-in forwards
}

.save-btn.clicked span.saved {
  animation: saved 1s ease-in forwards
}


@keyframes addicon {
  0% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes circle {
  0% {
    left: -10%
  }

  100% {
    left: 20%
  }
}

@keyframes check {

  0%,
  40% {
    top: -20%
  }

  60% {
    top: 48%
  }

  100% {
    top: 48%
  }
}

@keyframes saveProduct {

  0%,
  30% {
    opacity: 1
  }

  30%,
  100% {
    opacity: 0
  }
}

@keyframes saved {

  0%,
  80% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes color {
  0% {
    background-color: #FE3638
  }

  80%,
  100% {
    background-color: green
  }
}

.image {
  position: relative;
  width: 100%;
  overflow: hidden
}

.image .overlay {
  position: absolute;
  bottom: 0;
  padding-left: 25px;
  padding-top: 15px;
  width: 100%;
  height: 70%;
  color: white;
  font-size: 20px;
  z-index: 5;
  opacity: 0;
}

.image .overlay::before {
  content: "";
  background: #ffff;
  height: 100%;
  width: 100%;
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: -150px;
  z-index: -2;
  opacity: 0.5;
  transition: all 0.3s ease-out
}

.image:hover .overlay {
  color: black;
  opacity: 10;
}

.container .image:hover .overlay .amount,
.container .image:hover .overlay .h4,
.container .image:hover .overlay .textmuted,
.container .image:hover .overlay .fa-star,
.container .image:hover .overlay .fa-star-half-alt,
.container .image:hover .overlay .review {
  color: black
}

.image:hover .overlay::before {
  bottom: 0px
}

</style>