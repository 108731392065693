<template>
  <div v-if="rating>1" class="star-rating">
    <i v-for="star in rating" :key="star" class="star-rating-icon ci-star-filled active"></i>
    <i v-for="star in 5 - rating" :key="star" class="star-rating-icon ci-star"></i>
  </div>
</template>

<script>
export default {
  name: "ProductRating",
  props: ['rating']
}
</script>

<style scoped>

</style>