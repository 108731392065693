<template>
  <discount-form></discount-form>

</template>

<script>
import DiscountForm from "@/components/admin/DiscountForm";

export default {
  name: "AdminNewProduct",
  components: {
    DiscountForm
  }
}
</script>

<style scoped>

</style>