<template>

    <NavigationAdmin></NavigationAdmin>
    <router-view></router-view>

</template>

<script>
import NavigationAdmin from "../../components/NavigationAdmin";

export default {
  name: "AdminDashboard",
  components: {
    NavigationAdmin,
  }
}
</script>

<style scoped>
body {
  font-size: .875rem;
}


.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

</style>