function addDays(dateStr, days) {
    let date = new Date(dateStr);
    date.setDate(date.getDate() + days);
    return date.toISOString().substr(0, 10);
}

export default {
    namespaced: true,
    state() {
        return {
            products: [],
            total_price: 0,
            qty: 0,
            rental: {
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                showDays: 1
            },
            customer: {
                companyName: '',
                firstName: '',
                lastName: '',
                emailAddress: '',
                phone: '',
                zipcode: '',
                houseNumber: '',
                street: '',
                city: "",

            },
            transport: {
                shippingMethod: "",
                returnMethod: "",
                shipDate: new Date().toISOString().substr(0, 10),
                returnDate: new Date().toISOString().substr(0, 10),
                deliveryAddressSame: true,
                deliveryZipcode: '',
                deliveryHouseNumber: '',
                deliveryStreet :'',
                deliveryCity: '',
                pickupAddressSame: true,
                pickupZipcode: '',
                pickupHouseNumber: '',
                pickupStreet :'',
                pickupCity: '',
                deliveryCosts: 0,
                pickupCosts: 0
            },
            payment: {
                bank: '',
                requestQuote: false,
                discount: {
                    code: '',
                    method: '',
                    value: '',
                    amount: 0
                }
            },
            checkTerms: false,
            discountErrorMessage: null
        };
    },
    getters: {
        checkTerms(state) {
            return state.checkTerms;
        },
        products(state) {
            return state.products;
        },
        productTotalPrice(state) {
            let total = 0;
            let products = state.products;
            products.forEach((prod) => {
                if (prod.saleItem) {
                    total += prod.quantity * prod.price;
                } else {
                    total += prod.quantity * prod.price * state.rental.showDays;
                }
            })
            return total;
        },
        totalPrice(state) {
            let total = 0;
            let products = state.products;
            if (products !== undefined) {
                products.forEach((prod) => {
                    if (prod.saleItem) {
                        total += prod.quantity * prod.price;
                    } else {
                        total += prod.quantity * prod.price * state.rental.showDays;
                    }
                })
                return total + state.transport.deliveryCosts + state.transport.pickupCosts;
            } else {
                return 0;
            }

        },
        vat(state) {
            let total = 0;
            let products = state.products;
            products.forEach((prod) => {
                if (prod.saleItem) {
                    total += prod.quantity * prod.price;
                } else {
                    total += prod.quantity * prod.price * state.rental.showDays;
                }
            })
            return (total + state.transport.deliveryCosts + state.transport.pickupCosts - state.payment.discount.amount) / 121 * 21 ;
        },
        discountAmount(state) {
            return state.payment.discount.amount;

        },
        discount(state) {
            return state.payment.discount;

        },
        quantity(state) {
            return state.qty;
        },
        showDays(state) {
            return state.showDays;
        },
        customer(state) {
            return state.customer;
        },
        transport(state) {
            return state.transport;
        },
        rental(state) {
            return state.rental;
        },
        payment(state) {
            return state.payment;
        },
        isDetailsValid(state) {
            return state.customer.firstName !== ''
                && state.customer.lastName !== ''
                && state.customer.emailAddress !== ''
                && state.customer.phone !== ''
                && state.customer.zipcode !== ''
                && state.customer.houseNumber !== ''
                && state.customer.street !== ''
                && state.customer.city !== ''

        },
        isTransportValid(state) {
            return state.transport.shippingMethod !== '' && state.transport.returnMethod !== ''
        },
        isPaymentValid(state) {
            return state.payment.bank !== '' || state.payment.requestQuote
        },
        isTermsValid(state) {
            return state.checkTerms;
        }
    },
    mutations: {
        setCart(state, payload) {
            state.products = payload.products;
            state.total_price = payload.total_price;
            state.qty = payload.qty;
        },
        removeProductFromCart(state, payload) {
            const prodId = payload.productId;
            const productInCartIndex = state.products.findIndex(
                (cartItem) => cartItem.id === prodId
            );
            const prodData = state.products[productInCartIndex];
            state.products.splice(productInCartIndex, 1);
            state.qty -= prodData.quantity;
            state.total_price -= prodData.price * prodData.quantity;

        },
        changeQuantity(state, payload) {
            const prodId = payload.productId;
            const productInCartIndex = state.products.findIndex(
                (cartItem) => cartItem.id === prodId
            );
            const prodData = state.products[productInCartIndex];
            state.qty -= prodData.quantity;
            state.total_price -= prodData.price * prodData.quantity;
            state.products[productInCartIndex].quantity = parseInt(payload.newQty);
            state.qty += parseInt(payload.newQty);
            state.total_price += prodData.price * payload.newQty;
        },
        changeShowDays(state, payload) {
            state.rental.showDays = payload;
        },
        addProductToCart(state, payload) {
            const productData = payload.product;
            console.log(productData);
            const qty = payload.qty;

            let productInCartIndex = -1;
            if (state.products) {
                productInCartIndex = state.products.findIndex(
                    (ci) => ci.id === productData.id
                );
            } else {
                state.products = [];
                state.total_price = 0;
                state.qty = 0;
            }

             if (productInCartIndex >= 0) {
                state.products[productInCartIndex].quantity += qty;
            } else {
                const newItem = {
                    id: productData.id,
                    title: productData.title,
                    overview_image: productData.image,
                    price: productData.price,
                    quantity: qty,
                    imagelink: process.env.VUE_APP_API_ENDPOINT + '/products/' + productData.id + '/images/' + productData.image + '?',
                    link: productData.link,
                    saleItem: productData.saleItem
                };
                console.log(newItem);
                state.products.push(newItem);
            }
            state.qty += qty;

            state.total_price += productData.price;

        },
        setCustomer(state, payload) {
            state.customer.firstName = payload.firstName;
        },
        changeStartDate(state, payload) {
            state.rental.startDate = payload;
            state.transport.shipDate = addDays(payload, -1);
            if (state.rental.endDate < state.rental.startDate) {
                state.rental.endDate = payload;
            }
        },
        changeEndDate(state, payload) {

            state.rental.endDate = payload;
            state.transport.returnDate = addDays(payload, 1);
        },
        setTransport(state, payload) {
            state.transport = payload;
        },
        setPayment(state, payload) {
            state.transport = payload;
        },
        setShippingCosts(state, payload) {
            state.transport.deliveryCosts = payload;
        },
        setPickupCosts(state, payload) {
            state.transport.pickupCosts = payload;
        },
        setDiscount(state, payload) {
            let discount = {
                method: payload.method,
                code: payload.code,
                value: payload.value
            }
            switch (payload.method) {
                case 'PERC':
                    discount.amount = +state.total_price * payload.value / 100;
                    break;
                case 'AMOUNT':
                    discount.amount = payload.value;
                    break;
            }
            state.payment.discount = discount;
        },
        setDiscountErrorMessage(state, payload) {
            state.discountErrorMessage = payload;
        },
        setCheckTerms(state, payload) {
            console.log('setCheckTerms');
            console.log(payload)
            state.checkTerms = payload;
        }

    },
    actions: {
        clickCheckTerms(context, payload) {
            console.log('clickCheckTerms');
            context.commit('setCheckTerms', payload);
        },
        loadCart(context) {
            const userId = context.getters.user;
            fetch(process.env.VUE_APP_API_ENDPOINT + "/cart?user=" + userId,
                {
                        headers: {
                            'store': process.env.VUE_APP_STORE
                        }
                    })
                .then((response) => {
                    if (response.ok) {
                        return response.json();
                    }
                })
                .then(cart => {
                    let quantity = 0;
                    if (cart.products) {
                        cart.products.map(product => {
                            console.log(product);
                            product.imagelink = process.env.VUE_APP_API_ENDPOINT + '/products/' + product.id + '/images/' + product.overview_image + '?';
                            product.link = '/verhuur/' + product.id;
                            quantity += product.quantity;
                        })
                    }
                    cart.qty = quantity;
                    context.commit('setCart', cart);
                })
                .catch(err => console.log(err))
        },
        processOrder(context) {
            console.log('saveCart');
            console.log(this.state.cart);
            fetch(process.env.VUE_APP_API_ENDPOINT + "/cart", {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json',
                    'store': process.env.VUE_APP_STORE
                },
                body: JSON.stringify(this.state.cart)
            })
            .then(result => {
                console.log(result)
                context.commit('setCart' , {product: [], total_price:0, qty:0})
            })
            .catch(err => console.log(err))
        },
        removeFromCart(context, payload) {
            context.commit('removeProductFromCart', payload);
        },
        changeProductQuantity(context, payload) {
            context.commit('changeQuantity', payload);
        },
        changeShowDays(context, payload) {
            console.log('Change ShowDays');
            console.log(payload);
            context.commit('changeShowDays', payload);
            //context.dispatch('calculateTotalPrice');

        },
        addToCart(context, payload) {
            const prodId = payload.id;
            const qty = payload.qty || 1;
            const products = context.rootGetters['prods/products'];
            let product = products.find(prod => prod.id === prodId);
            context.commit('addProductToCart', {product: product, qty: qty});
            if (payload.accessoires) {
                payload.accessoires.forEach(acc => {
                    product = products.find(prod => prod.id === acc);
                    context.commit('addProductToCart', {product: product, qty: 1});
                })
            }

        },
        setCustomerData(context, payload) {
            context.commit('setCustomer', payload);
        },
        calculateShippingCosts(context, payload) {
            let costs = 0;
            if (payload === 'us') {
                costs = 75;
            }
            context.commit('setShippingCosts', costs);
        },
        calculatePickupCosts(context, payload) {
            let costs = 0;
            if (payload === 'us') {
                costs = 75;
            }
            context.commit('setPickupCosts', costs);
        },

        applyDiscount(context, payload) {
            fetch(process.env.VUE_APP_API_ENDPOINT + "/discounts/" + payload + '?orderAmount=' + context.getters.productTotalPrice,
                {
                    method: 'GET',
                    headers: {
                        'store': process.env.VUE_APP_STORE
                    }
                },
            )
            .then(async response => {
                const data = await response.json();
                if (response.ok) {
                    context.commit('setDiscount', {code: data.code, method: data.method, value: data.value});
                } else {
                    const error = (data && data.message) || response.statusText;
                    return Promise.reject(error);
                }
            })
            .catch(err => {
                context.commit('setDiscountErrorMessage', err)
            })
        }

    }
};