<template>
  <faq-question-form></faq-question-form>

</template>

<script>
import FaqQuestionForm from "@/components/admin/FaqQuestionForm";

export default {
  name: "AdminNewFAQQuestion",
  components: {
    FaqQuestionForm
  }
}
</script>

<style scoped>

</style>