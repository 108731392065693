<template>

  <main class="page-wrapper">
    <notification-bar v-if="isApiOffline" text="<span class=&quot;fw-medium&quot;>Let op:</span> Vanwege technische problemen kunnen de gegevens niet worden opgehaald. Probeer het later nog eens!" type="alert-danger"></notification-bar>
    <div v-if="isMaintenanceMode">
      <Maintenance></Maintenance>
    </div>
    <div v-else>
      <notification-bar v-if="!isAdmin && store && store.notificationText !== null && store.notificationText !== ''" :text="store.notificationText" type="alert-warning"></notification-bar>
      <HeaderAdmin v-if="isAdmin"></HeaderAdmin>
      <Header v-else></Header>
      <router-view></router-view>
      <page-footer v-if="!isAdmin"></page-footer>
    </div>


  </main>

</template>

<script>
// import Navigation from './components/Navigation.vue'
import Header from './components/Header.vue'
import HeaderAdmin from "./components/HeaderAdmin";
import Maintenance from "./pages/Maintenance";
import PageFooter from './components/PageFooter'
import NotificationBar from './components/NotificationBar'


export default {
  name: "App",
  props: ['title'],
  components: {
    HeaderAdmin,
    Header,
    Maintenance,
    PageFooter,
    NotificationBar
  },
  created() {

    this.$store.dispatch('tryLogin');
    this.$store.dispatch('prods/loadCategories',{});
    this.$store.dispatch('prods/loadProducts',{});
    //this.$store.dispatch('cart/loadCart', );
    this.$store.dispatch('loadStore');
    // this.$store.dispatch('ords/loadOrders',{});
    this.$store.dispatch('prods/loadFeaturedProducts',{});
    this.$store.dispatch('prods/loadFeaturedCategories',{});


  },
  computed: {
    isAdmin() {
      return this.$route.path.substr(1,5) === 'admin';
    },
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
    isApiOffline() {
      return this.$store.getters.isApiOffline;
    },
    isMaintenanceMode() {
      if ((this.$route.path.substring(1,7) !== 'signin') && (this.$route.path.substring(1,6) !== 'admin')) {
        return this.$store.getters.isMaintenanceMode;
      } else {
        return false;
      }
    },
    store() {
      return this.$store.getters.store;
    }
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/verhuur');
      }
    }
  },
  mounted() {
    console.log('App mounted');
    let url = "https://livechat.messagebird.com/bootstrap.js?widgetId=8375d2af-2275-4604-8c28-af8c4ecd2297";
    let tag = document.createElement("script");
    tag.setAttribute("src", url);
    tag.setAttribute("id", "live-chat-widget-script");
    document.body.appendChild(tag);
  }

};
</script>

<style>

</style>
